<template>
    <div class="container-fluid">
        <div class="block-header">
            <bread-crumb></bread-crumb>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12 col-md-12">
                <div class="card">
                    <User :model="model" :gratitud="gratitud" :lenguages="lenguages" :habilities="habilities" :type="'view'"></User>
                </div>
            </div>
        </div>
        <div style="text-align:center">
            <button class="btn btn-danger m-b-15" @click="close">{{GetLang('Close')}}</button>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import User from '../../components/admin/user/UserComponent.vue'

export default {
    components:{User, BreadCrumb},
    name: 'UserProfileView',
    data:()=> ({
        id: '', 
        model:{
            name: '',
            app: '',
            apm: '',
            ine: '',
            rfc: '',
            email: '',
            birthday: '',
            street: '',
            streetNumber:'',
            interiorNumber: '',
            town: '',
            country: '',
            genderCatalogId: '',
            stateId: '',
            municipalityId: '',
            routeImage: '',
            roleId: '',
            gender:{
                nameEs: '',
                nameEn: ''
            }
        },
        gratitud: [],
        lenguages: [],
        habilities: [],
    }),
    methods:{
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        init(){
            this.id = ''
            const tok = this.$route.query.tok
            if(tok) {
                this.id = tok
				request.ADFetch({
					url: Urls.Api.Personal.Detail,
					data: { id: tok },
					recive: dat => {
						const person = dat[0]
                        const date = (person.birthday).split('T')[0]
						this.model = {
							name: person.name,
							app: person.lastname,
							apm: person.secondlastname,
							ine: person.ine,
							rfc: person.rfc,
							email: person.email,
                            birthday: date,
                            street: person.street,
                            streetNumber: person.streetNumber,
                            interiorNumber: person.interiorNumber,
                            town: person.town,
                            country: 'México',
                            genderCatalogId: person.genderCatalogId,
                            stateId: person.stateId,
                            municipalityId: person.municipalityId,
							routeImage: person.routeImage,
                            roleId: person.roleId,
                            gender:{
                                nameEs: person.gender.nameEs,
                                nameEn: person.gender.nameEn
                            }
						}
                        this.gratitud= person.award
                        this.lenguages= person.idioms
                        this.habilities= person.skills
                        
					}
				})
			}
        },
        close(){
            this.$router.push({path: '/user/list'})
            //this.$emit('Close')
        },
    },
    beforeMount(){
        toolsLang.initLanguage()
        this.init()    
    },
    beforeUpdate(){
        toolsLang.initLanguage()
    }
}
</script>