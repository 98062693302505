
import LoginComponent from '../../views/authentication/loginViewPage.vue'
import ForgotPasswordComponent from '../../views/authentication/forgotPasswordPage.vue'

const externalMenu = [
    {
        path: '/login',
        name: 'login',
        component: LoginComponent,
        meta: {
            breadCrumb: 'Dashboard',
            title: 'Login',
            external: true
        }
    },
    {
        path: '/forgotPassword',
        name: 'forgotPassword',
        component: ForgotPasswordComponent,
        meta: {
            breadCrumb: 'Dashboard',
            title: 'TTForgotPassword',
            external: true
        }
    }
]

export default externalMenu