<template>
    <div class="row clearfix">
        <div class="col-md-12">
            <div class="card">
                <div class="body">  
                    <div class="modal-header">
                        <h4 class="title">{{GetLang(title)}}</h4>
                    </div>
                    <div class="modal-body"> 
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6"> 
                                <h6>{{GetLang('NameEs')}}</h6>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="model.nameEs">
                                </div>  
                            </div>   
                            <div class="col-lg-6 col-md-6 col-sm-6" > 
                                <h6>{{GetLang('NameEn')}}</h6>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="model.nameEn">
                                </div>
                            </div>   
                        </div>
                        <h6>{{GetLang('Status')}}</h6>
                        <div class="input-group mb-3">
                            <label class="fancy-radio"><input name="status" :value="1" v-model="model.active" type="radio"><span><i></i>{{GetLang('Active')}}</span></label>
                            <label class="fancy-radio"><input name="status" :value="0" v-model="model.active" type="radio"><span><i></i>{{GetLang('Inactive')}}</span></label>
                        </div>
                        <table-component
                            :tableData="permission"
                            @Update="update">
                        </table-component>
                    </div>
                    <div class="modal-footer">
                        <button class="btn btn-primary" @click="save">{{type=='Add'? GetLang('Save'): GetLang('Update')}}</button>
                        <button class="btn btn-danger" @click="close">{{GetLang('Close')}}</button>
                    </div>
                </div>
            </div>
        </div>        
    </div>
</template>

<script>
import toolsLang from '../../../common/dictionary.js'
import tableComponent from '../table/TablePermissionComponent.vue'
import request from '../../../scripts/request'
import Urls from '../../../scripts/urls'

export default {
    name: 'RolePermissionComponent',
    components:{tableComponent},
    data: () => ({
        model: {
            nameEn: '',
            nameEs: '',
            active: 1,
            permission: []
        }, 
        permission: [],
        tableData: [],
        type: '',
    }),
    props:{
        title:{
            require: true,
            type: String,
            default: ''
        },
        roleId:{
            type: String,
            default: null
        },
    },
    methods: {
        GetLang: key => toolsLang.getKey(key),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        update(m){
            var arrTemp = this.permission
            arrTemp.forEach((parent, index) => {
                parent.options.forEach((item, ic) => {
                    var i = item
                    if(i.id == m.id){
                        i.isActive = !i.isActive
                    }
                    arrTemp[index][ic] = i
                })  
            })
            this.permission = arrTemp
        },
        save(){
            var arrPermission = []
            this.permission.forEach((father)=>{
                var isOk = false
                father.options.forEach((child)=>{
                    if (child.isActive){
                        arrPermission.push(child.id)
                        isOk = true
                    }
                    
                })
                if(isOk){
                    arrPermission.push(father.id)
                }
            })
            this.model.permission = arrPermission

            if(this.model.nameEs.trim() == "" || this.model.nameEn.trim() == "") {
                this.$toast.open({
                    message: this.GetLang("NameRequired"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false
            } 

            if(arrPermission.length == 0){
                this.$toast.open({
                    message: this.GetLang("PermissionRequired"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false
            }


            var url = Urls.Api.Config.AddRole
            var okMessage = "RoleAdded"
            var eMessage = "RoleAddError"

            if (this.roleId != null){
                url = Urls.Api.Config.EditRole
                okMessage = "RoleUpdated"
                eMessage = "RoleUpdateError"
            }

            request.ADFetch({
                url: url,
                data: {
                    roleId: this.roleId,
                    nameEs: this.model.nameEs,
                    nameEn: this.model.nameEn,
                    active: this.model.active,
                    permissions: arrPermission
                },
                success: () => {
                    this.$toast.open({
                        message: this.GetLang(okMessage),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })                      
                    this.$emit('Close')
                },
                error: () => {
                    this.$toast.open({
                        message: this.GetLang(eMessage),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    }) 
                }
            })
        },          
        listPermission(){
            request.ADFetch({
                url: Urls.Api.Config.ListPermission,
                
                before: () => {this.permission = []},
                recive: dat => {this.permission = dat
                    var newArr = []
                    this.permission.forEach((item) => {
                        var parent = item
                        var op = []
                        item.options.forEach((child) => {
                            var m = {
                                nameEn: child.nameEn,
                                nameEs: child.nameEs,
                                isActive: false,
                                status: child.status,
                                id: child.id
                            }
                            op.push(m)
                        })
                        parent.options = op
                        newArr.push(parent)
                    })

                    this.permission = newArr
                    if(this.roleId != null){
                        this.getDetails()
                    }
                }
            })
        },      
        getDetails(){
            request.ADFetch({
                url: Urls.Api.Config.DetailsRole,
                data: {
                    role: this.roleId
                },
                before: () => {},
                recive: dat => {
                    this.model = dat

                    var newArr = []
                    this.permission.forEach((item) => {
                        var parent = item
                        var op = []
                        item.options.forEach((child) => {
                            var m = {
                                nameEn: child.nameEn,
                                nameEs: child.nameEs,
                                isActive: false,
                                status: child.status,
                                id: child.id
                            }

                            this.model.permissions.forEach((per) => {
                                per.options.forEach((opPer) => {
                                    if (opPer.id == child.id){
                                        m.isActive = true
                                    }
                                })
                            })
                            op.push(m)
                        })
                        parent.options = op
                        newArr.push(parent)
                    })
                    this.permission = newArr
                }
            })
        },         
        close(){
            this.$router.back()
        },
        setupView(){
            this.type = this.roleId != null? 'Edit':'Add'
        }
    },
    beforeMount() { // Antes de que se monte
        //toolsLang.changeLanguage()    
        this.listPermission()    
        
    },
    beforeUpdate(){
        //toolsLang.initLanguage()
       
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        this.setupView()
    },
}
</script>

<style lang="scss" scoped>
    .select2-container {
        margin: 0;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .modal{
        background-color: rgba(50,50,50,0.4);
    }
</style>