<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <!-- <div class="col-sm-12" align="right">
                    <button type="button" class="btn btn-block btn-primary b-add" @click="clear();addVisible = true;" align="right" style="margin-bottom: 20px;">{{GetLang("AddGroup")}}</button>
                </div> -->
                <div class="card">
                    <div class="header">
                        <div class="comment-form">
                            <form class="row">
                                <div class="col-sm-12">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-default">{{GetLang("Filter")}}</span>
                                        </div>
                                        <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" :placeholder="GetLang('TypeToSearch')" v-model="search" />
                                        <button type="button" class="btn btn-primary b-add" @click="clear();addVisible = true;" style="margin-left: 15px">{{GetLang("AddSectionRoom")}}</button>
                                    </div>
                                </div>
                                     
                            </form>
                        </div>
                    </div>
                    <div class="body">
                        <div style="width: 100%"> 
                            <TableSectionComponent
                                :tableData="tableData" 
                                :search="search"
                                @editRow="edit"
                                @deleteRow="deleteRow"
                            ></TableSectionComponent>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <DialogSectionRoomComponent
             v-if="addVisible"
             :title="title"
             :data="model"         
             :arr-sections="tableData"     
             @Close="close"
             @Correct="save($event)"
             ></DialogSectionRoomComponent>         
        </div>
        <!-- Edit -->
        <div>
            <DialogSectionRoomComponent
             v-if="editVisible"
             :title="title" 
             :data="model"
             :arr-sections="tableData"
             @Close="close"
             @Correct="save($event, 'edit')"
             ></DialogSectionRoomComponent>         
        </div>                
    </div>
</template>

<script>

import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import TableSectionComponent from '../../components/admin/table/TableSectionComponent.vue'
import DialogSectionRoomComponent from '../../components/admin/dialog/DialogSectionRoomComponent.vue'
import {ListSectionRoomCatalog, AddSectionRoomCatalog, UpdateSectionRoomCatalog, RemoveSectionRoomCatalog} from '../../scripts/catalogs'

export default {
    name: 'CGroupsComponent',
    components: {
        BreadCrumb,
        TableSectionComponent,
        DialogSectionRoomComponent
    },
    data: () => ({
        tableData: [],
        addVisible: false,
        model: {
            routeImage: '',
            nameEs: '',
            nameEn: '',
            position: 0,
            active: 0,
            file:{
                extention: '',
                file: '',
                name: ''
            }
        },
        sending: false,
        editVisible: false,
        title: toolsLang.getKey('AddTypeRoom'),
        search: '',
        total: 0    
    }),
    methods: {
        GetLang: key => toolsLang.getKey(key),
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        edit(data){
            this.editVisible = true
            this.title = toolsLang.getKey('EditTypeRoom')
            this.model = data                        
        },
        close(){
            this.addVisible = false
            this.editVisible = false
        },
        deleteRow(data){
            RemoveSectionRoomCatalog(data.id).then(() => {
                this.list()
             })
             this.$toast.open({
                message: toolsLang.getKey("Deleted"),
                type: "success",
                duration: 3000,
                position: 'top'
            })             
        },        
        save(model, type = ""){ 
            let msg = toolsLang.getKey("Added")
            this.sending = true
            const finish = () => {
                this.$toast.open({
                        message: msg,
                        type: "success",
                        duration: 3000,
                        position: 'top'
                }) 
                this.addVisible = false
                this.editVisible = false
                this.clear()
            } 
            const complete = () =>{ this.sending=false; this.list() }
           
            if(type == "edit") {
                msg = toolsLang.getKey("Edited")
                UpdateSectionRoomCatalog(model, complete).then(finish)
            } else {
                AddSectionRoomCatalog(model, complete).then(finish)

            }
        },
        clear(){
            this.model = {
                id: '',
                routeImage: '',
                nameEs: '',
                nameEn: '',
                position: 0,
                active: 0,
                file:{
                    extention: '',
                    file: '',
                    name: ''
                }
            }
        },
        list(){
            this.tableData = []
            ListSectionRoomCatalog().then(dat => {this.tableData = dat})
        },
    },
    beforeMount() { // Antes de que se monte
        this.list()
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>

</style>