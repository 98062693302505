<template>
  <div id="main-content">
    <router-view />
  </div>
</template>

<script>
// Used to fill parent routes that need to have children mounted in them
export default {
  name: 'dummy-view'
}
</script>