<template>
    <div>
      <h2 class="title"> {{ GetLang("Schedule") }}</h2>
      <input type="checkbox" v-model="isOpen24Hours" @change="change24" />
      <label class="day-label" style="margin-left: 6px;">{{ GetLang('Available24') }}</label>
      <div v-if="!isOpen24Hours" style="margin-top: 12px;">

        <input type="checkbox" v-model="isReserveBlockTime" @change="changeReserveBlock"/>
        <label class="day-label" style="margin-left: 6px; margin-bottom: 20px; color: #197595;">{{ GetLang('ReserveBlockTime') }}</label>

        <div v-for="(day, index) in days" :key="index" class="day">
        <input type="checkbox" v-model="selectedDays[index]" @change="toggleTimeInputs(index)"/>
        <label class="day-label" style="margin-left: 6px;">{{ GetLang(day) }} </label>
        <div v-if="selectedDays[index]" class="time-slots">
            <div v-for="(time, timeIndex) in schedule[day.toLowerCase()]" :key="timeIndex" class="time-slot">
                <label style="margin-right: 6px;">{{ GetLang('From') }}</label>
                <!-- <input type="time" v-model="time.startTime" /> -->
                <el-time-select v-model="time.startTime" :change="isToday()"  :picker-options="{ start: '00:00',step: '00:30',end: '23:30'}"></el-time-select>
                <label style="margin-left: 6px; margin-right: 6px;">{{ GetLang('To') }}</label>
                <!-- <input type="time" v-model="time.endTime" /> -->
                <el-time-select  v-model="time.endTime" :change="isToday()"  :picker-options="{ start: '00:00',step: '00:30',end: '23:30'}"></el-time-select>
                <button type="button" v-if="timeIndex > 0" @click="removeTimeSlot(index, timeIndex)" class="remove-btn" >
                    <i class="icon-close"></i>
                </button>
            </div>
            
          <button type="button" @click="addTimeSlot(index)" class="btn btn-primary m-b-15 m-l-20" style="margin-top: 8px;">+ Agregar horario</button>
        </div>
      </div>
      </div>
      
    </div>
  </template>
  
  <script>
  import toolsLang from '../../../common/dictionary'

  export default {
    
    props:{
      schedule: {
        required: true,
        type: Object,
        default:() => ({
          monday: [], // Monday
          tuesday:[], // Tuesday
          wednesday:[], // Wednesday
          thursday:[], // Thursday
          friday:[], // Friday
          saturday:[], // Saturday
          sunday:[] // Sunday
        })
      }, 
      is24Hours: {
        required: true,
        type: Boolean,
        default: false
      },
      isReserveBlockTimeModel: {
        required: true,
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        isOpen24Hours: this.is24Hours,
        days: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
        selectedDaysData: {},
        isReserveBlockTime: false
      };
    },
    methods: {
        GetLang: k => toolsLang.getKey(k),
        toggleTimeInputs(index) {
          const day = this.days[index].toLowerCase()
          if (this.selectedDays[index]) {
            this.schedule[day].push({ startTime: '', endTime: '' });
          } else {
            this.schedule[day] = []
          }
            this.updateData()
        },
        addTimeSlot(index) {
            const day = this.days[index].toLowerCase()
            this.schedule[day].push({ startTime: '', endTime: '' });
            this.$forceUpdate()
            this.updateData()
        },
        removeTimeSlot(dayIndex, timeIndex) {
          const day = this.days[dayIndex].toLowerCase()
            this.schedule[day].splice(timeIndex, 1)
            this.updateData()
        },
        updateData(){
            this.$emit('updateSchedule', this.schedule, this.isOpen24Hours, this.isReserveBlockTime)
        },
        changeReserveBlock(){
          this.updateData()
        },
        change24(){
          this.selectedDaysData = [false, false, false, false, false, false, false]
          this.emptyTime()
          this.updateData()
        },
        emptyTime(){
          for (let i = 0; i < this.days.length; i++) {
            const day = this.days[i].toLowerCase()
            this.schedule[day] = []   
          }
          this.$forceUpdate()
        },
        isToday(){
          /* if(this.model.date != null){
              if (this.model.date != '' && this.model.date.length != 10){                        
                  let date = this.getDate(this.model.date)
                  let today = new Date()
                  let hour = today.getHours() + ':' + today.getMinutes() + ':00';
                  if(date == this.getDate(today)){
                      this.minTime = hour 
                  }else{this.minTime = ''}
              }
          } */
      },

    },
    computed: {
      selectedDays: {
        get() {
          return this.selectedDaysData;
        },
        set(value) {
          // Puedes agregar lógica personalizada aquí si lo necesitas.
          this.selectedDaysData = value;
        },
      },
    },
    watch: {
      is24Hours(newIs24HoursValue) {
        this.isOpen24Hours = newIs24HoursValue
      },
      isReserveBlockTimeModel(newValue) {
        this.isReserveBlockTime = newValue
      },
      schedule() {
        this.selectedDaysData = this.days.map(day => {
          const scheduleDay = this.schedule[day.toLowerCase()];
          this.updateData()
          return scheduleDay !== undefined && scheduleDay.length > 0
        })
      },
  },
  
  created() {
    this.selectedDaysData = this.days.map(day => {
            const scheduleDay = this.schedule[day.toLowerCase()];
            return scheduleDay !== undefined && scheduleDay.length > 0
          })
    
    // this.days.map(day => this.schedule[day.toLowerCase()] != undefined? this.schedule[day.toLowerCase()].length > 0 : false)
  },
   
  };
  </script>
  

  <style>
.title {
  font-size: 24px;
  margin-bottom: 10px;
}

.day {
  margin-bottom: 20px;
}

.day-label {
  font-weight: bold;
}

.time-slots {
  margin-top: 10px;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.time-slot {
  margin-top: 5px;
}

.add-button {
  background-color: #007bff;
  color: #fff;
  border: none;
  padding: 5px 10px;
  margin-top: 10px;
  cursor: pointer;
  border-radius: 5px;
}

.add-button:hover {
  background-color: #0056b3;
}

.remove-btn {
    background: transparent; 
    border: none; 
    color: #f58989;
    padding: 4px;
    border-radius: 5px;
    margin-left: 6px;

    &:hover {
        color: #f25959;
        background: #d0d0d052;    
        padding: 4px;
    border-radius: 5px;
    }
}
</style>
