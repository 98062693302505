<template>
    <div class="card reservation-card">
        <ValidationObserver v-slot="{passes}">
            <div class="card-header">
                <h4 v-if="type == 'add'" class="m-b-0">{{GetLang('AddReservation')}}</h4>
                <h3 v-if="type == 'show'" class="m-b-0">{{GetLang('ShowReservation')}}</h3>
            </div>
            <div class="body">
                <form id="form-add-reservation-calendar" @submit.prevent="passes(save)">
                    <ValidationProvider name="name" rules="required" v-slot="{ errors,classes }">
                        <div class="form-group">
                            <label>{{GetLang('NameMeeting')}}</label>
                            <input :disabled="isDisabled" v-model="model.name" type="text" class="form-control" :class="classes" :placeholder="GetLang('NameMeeting')">
                            <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('MeetingNameRequired')}}</span>
                        </div>
                    </ValidationProvider>
                    <ValidationProvider name="description" rules="required" v-slot="{ errors,classes }">
                        <div class="form-group">
                            <label>{{GetLang('Description')}}</label>
                            <input :disabled="isDisabled" v-model="model.description" type="text" class="form-control" :class="classes" :placeholder="GetLang('Description')">
                            <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('DescriptionRequired')}}</span>
                        </div>
                    </ValidationProvider>   

                    <!-- <div class="form-group" v-if="data.room.typeRoom.nameEn == 'Presidential'">
                        <label>{{GetLang('Justification')}}</label>
                        <input v-model="model.justify" type="text" class="form-control" :placeholder="GetLang('Justification')" :disabled="isDisabled">
                    </div> -->

                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <ValidationProvider name="date" rules="required" v-slot="{ errors }">
                                <div class="form-group">
                                    <label>{{GetLang('Date')}}</label>
                                    <div class="input-group ">
                                        <el-date-picker :value-format="'yyyy-MM-dd'" :disabled="isDisabled" v-model="model.date" :picker-options="options"></el-date-picker>
                                    </div>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('DateRequired')}}</span>                                            
                                </div>                                        
                            </ValidationProvider>
                        </div>
                        <div class="col-lg-3 col-sm-12">
                            <ValidationProvider name="start" rules="required" v-slot="{ errors }">
                                <div class="form-group">
                                    <label>{{GetLang('Start')}}</label>
                                    <div class="input-group ">
                                        <el-time-select :disabled="isDisabled" v-model="model.timeInit" :change="isToday()"  :picker-options="{ start: '05:00',step: '00:30',end: '21:30', minTime: minTime}"></el-time-select>
                                    </div>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('StartTimeRequired')}}</span>                                            
                                </div>                                        
                            </ValidationProvider>                                    
                        </div>
                        <div class="col-lg-3 col-sm-12">
                            <ValidationProvider name="end" rules="required" v-slot="{ errors }">
                                <div class="form-group">
                                    <label>{{GetLang('End')}}</label>
                                    <div class="input-group ">
                                        <el-time-select :disabled="isDisabled" v-model="model.timeEnd" :change="isToday()"  :picker-options="{ start: '05:30',step: '00:30',end: '22:00', minTime: model.timeInit}"></el-time-select>
                                    </div>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('EndTimeRequired')}}</span>                                            
                                </div>                                        
                            </ValidationProvider>  
                        </div>
                    </div>
                    <label v-if="(type=='show' && model.internalPersonal.length > 0) || type=='add' || type == 'edit'">{{GetLang('InternalPersonal')}}</label>    
                    <div class="input-group "> 
                            <input  v-if="type=='add' || type == 'edit'" type="email" class="form-control " multiple name="emails" id="emails" list="memberList" v-model="intMember" @change="addMember('int')"  autocomplete="off">
                            <datalist id="memberList" v-if="(type=='show' && rGroups.length > 0) || type=='add' || type == 'edit'">
                                <option :value="user.email" v-for="user in rUsers" :key="user.email">{{user.email}}</option>
                            </datalist>                                           
                        <div class="badge-container" v-if="model.internalPersonal.length != 0">
                            <span class="tag badge badge-user" v-for="member in model.internalPersonal" :key="member.guid" :value="member">{{member}}<i v-if="!isDisabled" class="icon-close m-l-5" style="cursor: pointer" @click="removeMember(member, 'int')"></i></span>
                        </div>
                    </div>
                    <label v-if="(type=='show' && model.externalPersonal.length > 0) || type=='add' || type == 'edit'">{{GetLang('ExternalPersonal')}}</label>    
                    <div class="input-group"> 
                            <input v-if="type=='add' || type == 'edit'" type="email" class="form-control " multiple name="emails" id="emails" v-model="extMember" sr-only  @keypress.enter="addMember('ext')" autocomplete="off">                                        
                        <div class="badge-container" v-if="model.externalPersonal.length != 0">
                            <span class="tag badge badge-user" v-for="member in model.externalPersonal" :key="member.guid" :value="member">{{member}}<i v-if="!isDisabled" class="icon-close m-l-5" style="cursor: pointer" @click="removeMember(member, 'ext')"></i></span>
                        </div>
                    </div>    
                    <label v-if="(type == 'show' && arrSGroups.length > 0) || type=='add' || type == 'edit'">{{GetLang('Groups')}}</label>    
                    <div class="input-group"> 
                            <input v-if="type=='add' || type == 'edit'" type="email" class="form-control" multiple name="emails" id="emails" list="groupList" v-model="group" @change="addMember('grp')"  autocomplete="off">
                            <datalist id="groupList" v-if="(type=='show' && rGroups.length > 0) || type=='add' || type == 'edit'">
                                <option :value="group.name" v-for="group in rGroups" :key="group.id">{{group.name}}</option>
                            </datalist>                                           
                        <div class="badge-container" v-if="arrSGroups.length != 0">
                            <span class="tag badge badge-user" v-for="group in arrSGroups" :key="group" :value="group">{{group}}<i v-if="!isDisabled" class="icon-close m-l-5" style="cursor: pointer" @click="removeMember(group, 'grp')"></i></span>
                        </div>
                    </div>   
                    <div class="row">   
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>{{GetLang('Room')}}</label>
                                <select class="form-control" v-model="model.roomId" disabled>
                                    <option v-for="room in rRooms" :key="room.id" :value="room.id" :label="TextLang(room.nameEn, room.nameEs)"></option>
                                </select>
                            </div>
                        </div>     
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>{{GetLang('Description')}}</label><br/>
                            <span>{{model.roomDescription}}</span><br/>
                            </div>
                        </div>
                    </div> 
                    <div class="row">
                        <div class="col-lg-12">
                            <label>{{GetLang('PersonalInChargeRoom')}}</label>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>{{GetLang('Name')}}</label>
                                <input type="text" class="form-control" v-model="model.personCharge.nameComplete" readonly>
                            </div>
                        </div> 
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>{{GetLang('Email')}}</label>
                                <input type="text" class="form-control" v-model="model.personCharge.email" readonly>
                            </div>
                        </div>   
                        <div class="col-lg-4 col-sm-12">
                            <div class="form-group">
                                <label>{{GetLang('Phone')}}</label>
                                <input type="text" class="form-control" v-model="model.personCharge.phone" readonly>
                            </div>
                        </div>                                                       
                    </div> 
                </form>                
            </div>      
            <div class="m-b-25 m-r-20" style="text-align:right">
                <button class="btn btn-default m-r-5" @click="$emit('Cancel')">{{type == 'add' ? GetLang('Cancel') : GetLang('Close')}}</button>
                <button v-if="(isREdit || data.userId == userId) && status != 'Finish' && status != 'Active' && type == 'show' && !edit && data.status != 'Cancelled'" class="btn btn-primary m-r-5" type="button" @click="reminderReservation(model.id)" :disabled="isSend">{{GetLang('SendReminder')}}</button>
                <button v-if="(isREdit || data.userId == userId) && status != 'Finish' && status != 'Active' && type == 'show' && type != 'add' && !edit && data.status != 'Cancelled'" class="btn btn-warning m-r-5" type="button" @click="cancelReservation(model.id)" >{{GetLang('CancelReservation')}}</button>  
                <button v-if="type == 'add' && !edit && data.status != 'Cancelled'" class="btn btn-primary m-r-5" @click="passes(save)" :disabled="isSending">{{!isSending ? GetLang('Save') : GetLang('Saving')}}</button>
            
                <button v-if="edit && data.status != 'Cancelled'" class="btn btn-primary m-r-5" @click="passes(save)" :disabled="isSending">{{!isSending ? GetLang('Edit') : GetLang('Editing')}}</button>    
                
            </div>                     
        </ValidationObserver>              

    </div>
</template>

<script>
    import { ValidationObserver, ValidationProvider } from 'vee-validate'
    import toolsLang from '../../../common/dictionary'
    import request from '../../../scripts/request'
    import Urls from '../../../scripts/urls'
    import Tools from '../../../scripts/tools'
    import dateUtil from 'element-ui/lib/utils/date'
    // import constants from '../../../scripts/constants'



    export default{
        name:"Reservation",
        components: { ValidationObserver, ValidationProvider},
        data:()=>({
            isSend: false,
            isAdd: false,
            isREdit: false,
            options: {
                disabledDate(date) {
                    return date < (Date.now() - 86400000);
                }, 
            },
            
            arrSGroups: [],
            rGroups: [],
            rUsers: [],
            rRooms: [],

            intMember: '',
            extMember: '',
            group: '',

            minTime:'',

            model:{
                id: '',
                name: '',
                description: '',
                justify: '',
                numberPeople:  0,
                date: '',
                timeInit: '',
                timeEnd: '',
                internalPersonal: [],
                externalPersonal: [],
                groups: [],
                roomId:'',
                roomDescription: '',
                eRoomCatalog: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                location: {
                    address: '',
                    building: '',
                    id: '',
                    municipality: {
                        id:'',
                        name: '',
                        municipalityId: 0
                    },
                    state: {
                        id: 0,
                        name: ''
                    }
                },
                personCharge: {
                    email: '',
                    nameComplete: '',
                    phone: '',
                    userId:''
                },
                flat: '',
                area: '',
                roomservices: [{
                    nameEn:'',
                    nameEs:'',
                    roomServiceId:'',
                    serviceCatalog:''
                }],
                routeImage: '',
                file: {
                    name: '',
                    file: '',
                    extention: ''
                },             
            }
        }),
        props: {
            data: {
                require: true,
                type: Object,
                default: null
            },
           type:{
               require: true,
               type: String,
               default: ''
           },
           edit: {
            require: false,
            type: Boolean,
            default: false
           },
           isSending: {
            require: true,
            type: Boolean,
            default: false
           }
           
       },
       methods:{
           GetLang: k => toolsLang.getKey(k),
           TextLang: (en,es) => toolsLang.getTranslate(en,es),
           save(){
                if(this.model.date != null){
                    if (this.model.date != '' && this.model.date.length != 10){
                        this.model.date = this.getDate(this.model.date)
                    }
                }
                
                this.model.groups = []
                this.arrSGroups.forEach((item) => {
                    this.rGroups.forEach((group) => {
                        if(item == group.name){
                            this.model.groups.push(group)
                        }
                    })
                })               
                
                this.$emit('Save', this.model)
           },
           close(){
               this.$emit('Close')
           },
           addMember(type){  
                switch(type){
                    case 'int':
                        if(this.intMember != ''){
                            let uniqueMembers = []
                            this.model.internalPersonal.push(this.intMember)
                            this.model.internalPersonal.forEach(item => {
                                if(!uniqueMembers.includes(item)){
                                    uniqueMembers.push(item)
                                }
                            });
                            this.model.internalPersonal = uniqueMembers
                        }
                        break;
                    case 'ext':
                        if(this.extMember != ''){
                            let uniqueMembers = []
                            this.model.externalPersonal.push(this.extMember)
                            this.model.externalPersonal.forEach(item => {
                                if(!uniqueMembers.includes(item)){
                                    uniqueMembers.push(item)
                                }
                            });
                            this.model.externalPersonal = uniqueMembers
                        } 
                        break;
                    case 'grp':
                        if(this.group != ''){
                            let uniqueGroups = []
                            this.arrSGroups.push(this.group)
                            this.arrSGroups.forEach(item => {
                                if(!uniqueGroups.includes(item)){
                                    uniqueGroups.push(item)
                                }
                            })
                            this.arrSGroups = uniqueGroups
                        }  
                        break;                
                }
               this.intMember = ''
               this.extMember = ''
               this.group = ''               
           },
           removeMember(mb, type){
                switch(type){
                    case 'int': {
                        const inter = this.model.internalPersonal.indexOf(mb)
                        if(inter != -1) {
                            this.model.internalPersonal.splice(inter, 1)
                        } 
                        break;
                    }
                    case 'ext': {
                        const exter = this.model.externalPersonal.indexOf(mb)
                        if(exter != -1) {
                            this.model.externalPersonal.splice(exter, 1)
                        } 
                        break;
                    }
                    case 'grp': {
                        const group = this.arrSGroups.indexOf(mb)
                        if(group != -1) {
                            this.arrSGroups.splice(group, 1)
                        }
                        break;   
                    }              
                }
           },
           list(){
               request.ADFetch({
                   url: Urls.Api.Personal.List,
                   before: () => {this.rUsers = []},
                   recive: dat => {this.rUsers = dat}
               })
               request.ADFetch({
                   url: Urls.Api.Catalog.Group.List,
                   before: () => {this.rGroups = []},
                   recive: dat => {this.rGroups = dat}
               })
               request.ADFetch({
                   url: Urls.Api.Room.List,
                   before: () => {this.rRooms = []},
                   recive: dat => {this.rRooms = dat}
               })
           },
           isToday(){
                if(this.model.date != null){
                    if (this.model.date != '' && this.model.date.length != 10){                        
                        let date = this.getDate(this.model.date)
                        let today = new Date()
                        let hour = today.getHours() + ':' + today.getMinutes() + ':00';
                        if(date == this.getDate(today)){
                            this.minTime = hour 
                        }else{this.minTime = ''}
                    }
                }
           },
           getSession(){
                const role = this.$store.getters.role
                if(role == 'Super'){
                    this.isREdit = true
                }else{this.isREdit = false}
                this.userId = this.$store.getters.userId
           },
           getDate(date = null, format  = 'yyyy-MM-dd'){
                date ??= new Date()
                return dateUtil.format(date, format)
            },
            isTimestamp(value) {
                if (typeof value !== 'number') {
                    return value;
                }
                const currenteDate = Date.now()
                const minDate = 0
                const maxDate = currenteDate + 100 * 365 * 24 * 60 * 60 * 1000

                 if(value >= minDate && value <= maxDate){
                    const date = new Date(value);

                    const year = date.getFullYear();
                    const month = String(date.getMonth() + 1).padStart(2, '0')
                    const day = String(date.getDate()).padStart(2, '0');

                    return `${year}-${month}-${day}`;
                 } else {
                    return value
                 }
            },
            initData(){
                const role = this.$store.getters.role
                if(role == 'Super'){
                    this.isREdit = true
                }else{this.isREdit = false}
                this.userId = this.$store.getters.userId
                this.model.id = this.data.id
                this.model.roomId = this.data.room.id
                this.model.roomDescription = this.data.room.description
                this.model.personCharge = this.data.room.manager

                this.model.date = this.isTimestamp(this.data.date)
                this.model.timeInit = this.data.dateTimeInit
                this.minTime = this.model.timeInit
                this.model.timeEnd = this.data.dateTimeEnd
                this.model.description = this.data.description
                this.model.name = this.data.name

                this.model.internalPersonal = []
                this.model.externalPersonal = []
                this.data.internalUsers?.forEach((item) => {
                    this.model.internalPersonal.push(item.email)
                })

                this.data.externalUsers?.forEach((item) => {
                    this.model.externalPersonal.push(item.email)
                })
                this.data.groups?.forEach((item) => {
                    this.arrSGroups.push(item.name)                                    
                })
            },
            reminderReservation(id){
                request.ADFetch({
                    url: Urls.Api.Reservation.Reminder,
                    data: {value: id},
                    before: () => {this.isSend=true},
                    success: () => {
                        this.$toast.open({
                            message: toolsLang.getKey("SentReminder"),
                            type: "success",
                            duration: 3000,
                            position: 'top'
                        }) 
                        this.$emit('Cancel')
                    },
                    complete: () => {this.isSend = false}
                })
            },
            cancelReservation(id){
                this.$confirm(toolsLang.getKey("AreYouSureToCancel"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
                .then(()=>{
                    this.editVisible = false
                    request.ADFetch({
                        url: Urls.Api.Reservation.Cancel,
                        data: {value: id},
                        success: () => {
                            this.$toast.open({
                                message: toolsLang.getKey("ReservationCancelled"),
                                type: "success",
                                duration: 3000,
                                position: 'top'
                            }) 
                            this.$emit('close')
                        }
                    })
                })
            }
       },
       watch:{
        data(){
            
            this.initData()
        }
       },
       beforeMount(){
            this.list()
            this.initData()
            this.getSession()
       },
       computed: {
            isDisabled(){ return this.type == 'show' },
            status(){
                
                if(this.model.timeEnd == "" && this.model.timeEnd == "") return "Active"

                const current = new Date()
                const dateInit = new Date(this.model.date)
                const dateEnd = Date.parse(Tools.getDate(dateInit) + 'T' + this.model.timeEnd + ':00')
                
                if(dateEnd < current){
                    return "Finish"
                } else if(dateInit > current){
                    return "Pendient"
                }else {
                    return "Active"
                }
            }
       }
   }
</script>


<style lang="scss">
    .reservation-card {

        .form-control:disabled, .form-control[readonly] {
            background-color: #fff;
            opacity: 1;
            cursor: not-allowed
        }

        .el-input.is-disabled .el-input__inner {
            background-color: #fff;
            border-color: #E4E7ED;
            color: #606367;
            cursor: not-allowed;
        }

        .input-group, .badge-container, .form-group {
            margin-bottom: 5px;
        }

    }
</style>
