<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>     
            <RoleComponent 
                :title="'AddRole'"
                :type="'Add'"
                @Close="close">
            </RoleComponent>   
        </div>           
    </div>
</template>


<script>
import toolsLang from '../../common/dictionary'
import BreadCrumb from '../../components/BreadCrumb.vue'
import RoleComponent from '../../components/admin/config/RolePermissionComponent.vue'

export default {
    name: 'ViewAddRole',
    components:{BreadCrumb, RoleComponent},
    methods: {
        GetLang: key => toolsLang.getKey(key),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
       
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },

        close(){
            this.$router.back()
        },
       
    },
    beforeMount() { // Antes de que se monte
        //toolsLang.changeLanguage()
    },
    beforeUpdate(){
        //toolsLang.initLanguage()
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>