<template>
    <div class="row clearfix">
        <div class="col-md-12">
            <div class="card">
                <ValidationObserver v-slot="{ passes }">
                    <div class="body">  
                            <div class="media">
                                <div class="media-left m-r-15">
                                    <viewer>
                                        <img  :src="image ? image : model.routeImage != '' ? route + model.routeImage : !image ? require('@/assets/fondo-sala.jpg') : image " class="user-photo media-object" width="150" height="150">
                                    </viewer>
                                </div>
                                <div class="media-body" v-if="!isView">
                                    <h6>{{GetLang('UploadRoomImage')}}</h6><br>
                                    <button  class="btn btn-upload-image" id="btn-upload-photo" @click="$refs.filePhoto.click()">{{GetLang('UploadImage')}}</button>
                                        <input type="file" ref="filePhoto" class="sr-only" @input="onSelectFile(); uploadImage();" accept="image/jpeg, image/png, image/jpg"><br>
                                </div>
                            </div>

                            
                        <form @submit.prevent="passes(saveRoom)" id="basic-form">   
                            
                            <div class="form-group">
                                    <label>{{GetLang('MainRoom')}}</label>
                                    <select  class="form-control" v-model="model.mainRoomId" :disabled="isView">
                                        <option disabled value="" >{{GetLang('SelectMainRoom')}}</option>
                                        <option v-for="room in mainRooms" :key="room.id" :value="room.id" :label="TextLang(room.nameEn, room.nameEs)"></option>                                
                                    </select>
                                </div>

                            <ValidationProvider name="nameEs" rules="required" v-slot="{ errors,classes }">
                                <div class="form-group">
                                    <label>{{GetLang('NameEs')}}</label>
                                    <input v-model="model.nameEs" type="text" class="form-control" :class="classes" :placeholder="GetLang('NameEs')" :readonly="isView">
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('NameRequired')}}</span>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="nameEn" rules="required" v-slot="{ errors,classes }">
                                <div class="form-group">
                                    <label>{{GetLang('NameEn')}}</label>
                                    <input v-model="model.nameEn" type="text" class="form-control" :class="classes" :placeholder="GetLang('NameEn')" :readonly="isView">
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('NameRequired')}}</span>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="description" rules="required" v-slot="{ errors,classes}">
                                <div class="form-group">
                                    <label>{{GetLang('Description')}}</label>
                                    <textarea class="form-control" v-model="model.description" :class="classes" :placeholder="GetLang('Description')" :readonly="isView"></textarea>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{ GetLang('DescriptionRequired') }}</span>
                                </div>
                            </ValidationProvider>                            
                            <div class="form-group">
                                <label>{{GetLang('PersonCharge')}}</label>
                                <div class="input-group "> 
                                        <input  v-if="type=='add' || type == 'edit'" type="text" class="form-control" id="personCharge" list="personInCharge" v-model="personInCharge" @change="addMember" autocomplete="off">
                                        <datalist id="personInCharge" v-if="(type=='show' && personInCharge == '') || type=='add' || type == 'edit'">
                                            <option :value="user.name" v-for="user in users" :key="user.name">{{user.name}}</option>
                                        </datalist>                                           
                                    <div class="badge-container" v-if="model.personCharge.userId != ''">
                                        <span class="tag badge badge-user" :value="model.personCharge.userId">{{model.personCharge.nameComplete}}<i v-if="!isView" class="icon-close m-l-5" style="cursor: pointer" @click="removeMember()"></i></span>
                                    </div>
                                </div>
                            </div>
                            <ValidationProvider name="numberPeople" rules="required|min_value:1" v-slot="{ errors,classes}">
                                <div class="form-group">
                                    <label>{{GetLang('CapacityPeople')}}</label>
                                    <input v-model="model.numberPeople" :placeholder="GetLang('CapacityPeople')" type="number" min="1" class="form-control" :class="classes" :readonly="isView" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('CapacityRequired') }}</span>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="sectionRoom" rules="required" v-slot="{ errors}">
                                <div class="form-group">
                                    <label>{{GetLang('SectionRoom')}}</label>
                                    <select  class="form-control" v-model="model.sectionRoomType.id" :disabled="isView">
                                        <option disabled value="" >{{GetLang('SelectSectionRoom')}}</option>
                                        <option v-for="section in sectionCatalog" :key="section.id" :value="section.id" :label="TextLang(section.nameEn, section.nameEs)"></option>                                
                                    </select>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{ GetLang('SectionRoomRequired') }}</span>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="typeRoom" rules="required" v-slot="{ errors}">
                                <div class="form-group">
                                    <label>{{GetLang('TypeRoom')}}</label>
                                    <select  class="form-control" v-model="model.typeRoomCatalog.id" :disabled="isView">
                                        <option disabled value="">{{GetLang('SelectTypeRoom')}}</option>
                                        <option v-for="room in rooms" :key="room.id" :value="room.id" :label="TextLang(room.nameEn, room.nameEs)"></option>                                
                                    </select>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{ GetLang('TypeRoomRequired') }}</span>
                                </div>
                            </ValidationProvider>  
                            <ValidationProvider name="location" rules="required" v-slot="{ errors}">
                                <div class="form-group">
                                    <label>{{GetLang('Location')}}</label>
                                    <select  class="form-control" v-model="model.location.id" :disabled="isView">
                                        <option disabled value="">{{GetLang('SelectLocation')}}</option>
                                        <option v-for="location in locations" :key="location.id" :value="location.id" :label="location.building + ' - ' + location.address"></option>
                                    </select>
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{ GetLang('LocationRequired') }}</span>
                                </div>
                            </ValidationProvider>  
                            <ValidationProvider name="flat" rules="required|numeric" v-slot="{ errors,classes }">
                                <div class="form-group">
                                    <label>{{GetLang('Flat')}}</label>
                                    <input v-model="model.flat" type="number" min="1" class="form-control" :class="classes" :placeholder="GetLang('Flat')" :readonly="isView" onkeypress="return (event.charCode !=8 && event.charCode ==0 || (event.charCode >= 48 && event.charCode <= 57))">
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('FlatRequired')}}</span>
                                </div>
                            </ValidationProvider>
                            <ValidationProvider name="area" rules="required" v-slot="{ errors,classes }">
                                <div class="form-group">
                                    <label>{{GetLang('Area')}}</label>
                                    <input v-model="model.area" type="text" class="form-control" :class="classes" :placeholder="GetLang('Area')" :readonly="isView">
                                    <span v-if="errors.length" :class="{'text-danger': errors.length}">{{GetLang('AreaRequired')}}</span>
                                </div>
                            </ValidationProvider>  
                            <label>{{GetLang('Services')}}</label>
                            <div class="form-group">
                                <label class="fancy-checkbox" v-for="service in services" :key="service.id" >
                                    <input type="checkbox"  v-if="service.active == 1" :value="service.id" v-model="service.isSelected" :disabled="isView">
                                    <span v-if="service.active == 1">{{TextLang(service.nameEn, service.nameEs)}}</span>
                                </label>
                            </div>
                            <ScheduleRoomComponent @updateSchedule="updateSchedule" :schedule="model.schedule" :is24Hours="model.isOpen24Hours" :isReserveBlockTimeModel="model.isReserveBlockTime"></ScheduleRoomComponent>
                        </form>                
                    </div>
                    <div style="text-align:left" v-if="!isView">
                        <button v-if="type == 'add'" class="btn btn-primary m-b-15 m-l-20" @click="passes(saveRoom)" :disabled="isSending">{{!isSending ? GetLang('Save') : GetLang('Saving')}}</button>
                        <button v-if="type == 'edit'" class="btn btn-primary m-b-15 m-l-20" @click="passes(saveRoom)" :disabled="isSending">{{!isSending ? GetLang('Edit') : GetLang('Editing')}}</button>
                        <button v-if="type != 'add'" class="btn btn-danger m-b-15 m-l-15" @click="close"> {{GetLang('Cancel')}}</button>
                    </div>         
                    <div style="text-align:left" v-if="isView">
                        <button class="btn btn-danger m-b-15 m-l-15" @click="close">{{GetLang('Close')}}</button>
                    </div>                       
                </ValidationObserver>
            </div>
        </div>        
    </div>         
</template>


<script>
import { ValidationObserver, ValidationProvider } from 'vee-validate'
import toolsLang from '../../../common/dictionary'
import request  from '../../../scripts/request'
import Urls from '../../../scripts/urls'
import {Services, TypesRoom} from '../../../scripts/catalogs'
import {ListSectionRoomCatalog} from '../../../scripts/catalogs'
import ScheduleRoomComponent from './ScheduleRoomComponent.vue'

export default {
    name: 'AddRoom',
    components:{ValidationObserver, ValidationProvider, ScheduleRoomComponent},
    data: () => ({
        mainRooms:[],
        services: [{
            id: '',
            active: 0,
            nameEn: '',
            nameEs: '',
            isSelected: false,
        }],
        personInCharge: '',
        selectServices: [],
        rooms: [],
        locations: [],
        users: [],
        filename: '',
        image: null, 
        isView: false ,
        route: '',
        sectionCatalog:[],
        schedule: {
            monday: [], // Monday
            tuesday:[], // Tuesday
            wednesday:[], // Wednesday
            thursday:[], // Thursday
            friday:[], // Friday
            saturday:[], // Saturday
            sunday:[], // Sunday
        },
    }),
    props:{
        isSending: {
            require: true,
            type: Boolean,
            default: false
        },
        model:{
            require: true,
            type: Object,
            default:() => ({
                id: '',
                nameEs: '',
                nameEn: '',
                description: '',
                personChargeId: '',
                numberPeople:  1,
                selectServices: [],
                sectionRoomType: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                typeRoomCatalog: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                location: {
                    address: '',
                    building: '',
                    id: '',
                    municipality: {
                        id:'',
                        name: '',
                        municipalityId: 0
                    },
                    state: {
                        id: 0,
                        name: ''
                    }
                },
                personCharge: {
                    email: '',
                    nameComplete: '',
                    phone: '',
                    userId:''
                },
                flat: '',
                area: '',
                roomservices: [{
                    nameEn:'',
                    nameEs:'',
                    roomServiceId:'',
                    serviceCatalog:''
                }],
                section: '',
                routeImage: '',
                file: {
                    name: '',
                    file: '',
                    extention: ''
                }, 
                isOpen24Hours: false,
                isReserveBlockTime: false,
                schedule: {
                    monday: [], // Monday
                    tuesday:[], // Tuesday
                    wednesday:[], // Wednesday
                    thursday:[], // Thursday
                    friday:[], // Friday
                    saturday:[], // Saturday
                    sunday:[], // Sunday
                },
                mainRoomId: ''
                
            })
        },
        type:{
            require: true,
            type: String,
            default: 'add'
        }
    },
    methods: {
        onSelectFile () {
            //constants.KeyCatalog
            const input = this.$refs.filePhoto
            const files = input.files
            if (files && files[0]) {
                const reader = new FileReader
                reader.onload = e => {
                    this.image = e.target.result
                    this.model.routeImage = e.target.result
                }
                reader.readAsDataURL(files[0])
                this.$emit('input', files[0])
            }
        }, 
        updateSchedule(data, is24, reserveBlock){
            this.schedule = data
            this.model.isOpen24Hours = is24
            this.model.isReserveBlockTime = reserveBlock
            console.log('isReserveBlockTime', this.model.isReserveBlockTime)
        },
        uploadImage() {
            if(this.model.file == null){
                this.model.file = {
                    name: '',
                    file: '',
                    extention: ''
                }
            }
			const file = document.querySelector('input[type=file]').files[0]
			const reader = new FileReader()
			reader.onload = () => {
				this.model.file.file = reader.result.split(',')[1]
			}
			reader.readAsDataURL(file)
			this.model.file.name = file.name.split('.')[0]
			this.model.file.extention = file.name.split('.').pop()
			this.filename = file.name
		},
        saveRoom(){
            if(this.model.numberPeople == 0 || this.model.numberPeople == null){
                return false
            }
            if(this.model.personCharge.userId == ''){
                this.$toast.open({
                        message: toolsLang.getKey("PersonalRequired"),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    }) 
                return false
            }
            this.selectServices = []
            this.services.forEach((item)=>{
                if(item.isSelected == true){
                    this.selectServices.push(item.id)
                }
            })
            this.model.selectServices = this.selectServices
            this.model.schedule = this.schedule
            
            let message = ''
            if (!this.model.isOpen24Hours) {
                message = this.validateSchedule(this.model.schedule)
            }
            
            if(message.trim().length === 0) {
                this.$emit('Save', this.model)
            } else{
                this.$toast.open({
                        message: toolsLang.getKey(message),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    }) 
            }
        
        },
        validateSchedule(schedule) {

            // Validar que haya al menos un horario disponible
            let message = ''
            let isOk = false;
            for (const day in schedule) {
                if (schedule[day].length != 0) {
                    isOk = true
                    break
                }
            }

            if (!isOk) {
                message = 'ErrorEmptySchedule'
                return message
            }

            // Validar que las horas de inicio sean menores que las horas finales
            for (const day in schedule) {
                for (const segment of schedule[day]) {
                    const startTime = segment.startTime;
                    const endTime = segment.endTime;

                    if (startTime === "" || endTime === "") {
                        message = 'ErrorEmptySchedule'
                        return message
                    }

                    const start = new Date(`1970-01-01T${startTime}`);
                    const end = new Date(`1970-01-01T${endTime}`);

                    if (start >= end) {
                        //return false; // La hora de inicio es mayor o igual que la hora final
                        message = 'ErrorHoursSchedule'
                        return message
                        
                    }
                }
            }

            // Validar que los segmentos de horario no estén superpuestos
            for (const day in schedule) {
                const segments = schedule[day];
                for (let i = 0; i < segments.length; i++) {
                const segment1 = segments[i];
                for (let j = i + 1; j < segments.length; j++) {
                    const segment2 = segments[j];
                    const startTime1 = new Date(`1970-01-01T${segment1.startTime}`);
                    const endTime1 = new Date(`1970-01-01T${segment1.endTime}`);
                    const startTime2 = new Date(`1970-01-01T${segment2.startTime}`);
                    const endTime2 = new Date(`1970-01-01T${segment2.endTime}`);

                    if (
                    (startTime1 <= startTime2 && endTime1 > startTime2) ||
                    (startTime2 <= startTime1 && endTime2 > startTime1)
                    ) {
                    //return false; // Los segmentos de horario están superpuestos
                        message = 'ErrorHoursSegmentsSchedule'
                        return message
                    }
                }
                }
            }
            return message
        },
        close(){
            this.$router.back();
        },
        addMember(){  
            if(this.personInCharge != ''){
                this.users.forEach((item) => {
                    if(item.name == this.personInCharge){
                        this.model.personCharge = {
                            email: item.email,
                            nameComplete: item.name,
                            phone: item.phone,
                            userId: item.userId
                        }
                    }
                })
            }
            this.personInCharge = ''
        },
        removeMember(){
            this.model.personCharge = {
                email: '',
                nameComplete: '',
                phone: '',
                userId:''
            }
        },
        clear(){
            this.model={
                id: '',
                nameEs: '',
                nameEn: '',
                description: '',
                personChargeId: '',
                numberPeople:  0,
                sectionRoomType: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                typeRoomCatalog: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                location: {
                    address: '',
                    building: '',
                    id: '',
                    municipality: {
                        id:'',
                        name: '',
                        municipalityId: 0
                    },
                    state: {
                        id: 0,
                        name: ''
                    }
                },
                personCharge: {
                    email: '',
                    nameComplete: '',
                    phone: '',
                    userId:''
                },
                flat: '',
                area: '',
                roomservices: [{
                    nameEn:'',
                    nameEs:'',
                    roomServiceId:'',
                    serviceCatalog:''
                }],
                section: '',
                routeImage: '',
                file: {
                    name: '',
                    file: '',
                    extention: ''
                },    
                isOpen24Hours: false,
                isReserveBlockTime: false,
                schedule: {
                    monday: [], // Monday
                    tuesday:[], // Tuesday
                    wednesday:[], // Wednesday
                    thursday:[], // Thursday
                    friday:[], // Friday
                    saturday:[], // Saturday
                    sunday:[], // Sunday
                },    
                mainRoomId: ''     
            }
        },  
        list(){
            ListSectionRoomCatalog().then(dat => {
                this.sectionCatalog = []
                for (let i = 0; i < dat.length; i++) {
                    if(dat[i].active == 1) {
                        this.sectionCatalog.push(dat[i])
                    }
                }
            })
            this.route = Urls.Sites.UrlAPI
            this.services = []
            Services().then(data => {
                this.services = data
                var arrServices = []
                data.forEach((item) => {
                    var isOk = false
                    this.model.roomservices.forEach((itemR) => {
                        if(item.id == itemR.serviceCatalog) {
                            isOk = true
                        }
                    })
                    var newSer = {
                        id: item.id,
                        active: item.active,
                        nameEn: item.nameEn,
                        nameEs: item.nameEs,
                        isSelected: isOk,
                    }
                    arrServices.push(newSer)
                })

                this.services = arrServices
            })
            this.rooms = []
            TypesRoom().then(dat => {this.rooms = dat})
            request.ADFetch({
                url: Urls.Api.Catalog.Location.List,
                before: () => {this.locations = []},
                recive: dat => {this.locations = dat}
            })    
            request.ADFetch({
                url: Urls.Api.Personal.List,
                before: () => {this.users = []},
                recive: dat => {this.users = dat}
            })                  
            if(this.type == 'view'){
                this.isView = true
            }                           
        },       
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
    }, 
    beforeMount() { // Antes de que se monte
        toolsLang.initLanguage()    
        this.list()

        request.ADFetch({
            url: Urls.Api.Room.ListMain,
            before: () => {this.mainRooms = []},
            recive: dat => {this.mainRooms = dat}
        })
    },
    beforeUpdate(){

    },
    computed: { // actualiza cuando cambia algo en el dom
        
    },
    created() { // cuando se crea
        
    }

}
</script>

<style lang="scss" scoped>
    .fancy-checkbox {    
        input[type="checkbox"] {

            &:checked + span:before {
                background: #003b5b;
                border-color: #003b5b;
            }
        }
    }
    .form-control:disabled, .form-control[readonly] {
        background-color: #fff;
            border-color: #E4E7ED;
            color: #606367;
            cursor: not-allowed;
}
</style>
