<template>
    <div class="row">
        <div class="col-lg-12">
            <div v-if="filter.length == 0" style="text-align: center">
                <h4>Sin Datos</h4>
            </div>
        </div>
        <div class="col-lg-4 col-md-6 col-sm-12" v-for="reservation in filter" :key="reservation.id">
            <div class="card">
                <div class="body" style="padding: 20px">
                    <span v-if="reservation.proggress > 100" style="float: right; cursor: pointer;" @click="cancel(reservation.id)"><i class="fa fa-close"></i></span>
                    <h6>{{reservation.name}}</h6>
                    <p>{{reservation.description}}</p>
                    <ul class="list-unstyled team-info m-t-20">
                        <span>{{GetLang('Team')}} </span>
                        <li v-for="user in reservation.users" :key="user.id"><img :src="require('@/assets/users/img_user_other.png')" :title="user.name" :alt="user.email"></li>
                    </ul>
                    <span style="float: right">{{reservation.dateTimeInit.split('T')[0]}}</span> 
                    <h6>{{GetLang('From')}}  {{reservation.dateTimeInit.split('T')[1]}} hrs {{GetLang('To')}}  {{reservation.dateTimeEnd.split('T')[1]}} hrs</h6>
                    <div class="progress progress-xs progress-transparent m-b-10 m-t-25" :class="'custom-color-'+ reservation.color">
                        <div class="progress-bar " role="progressbar" :style="{width: reservation.proggress + '%'}"></div>        
                    </div>
                    <p v-if="reservation.color != 'green'" :style="{color: reservation.bColor, margin: '0px'}">{{reservation.statusName}}</p>                        
                    <div  style="text-align: right">
                        <span v-if="reservation.color == 'green'" class="badge badge-success" style="cursor: pointer" @click="edit(reservation.id)">{{GetLang('Edit')}}</span>
                        <span v-if="reservation.color == 'green'" class="badge badge-danger" style="cursor: pointer" @click="cancel(reservation.id)">{{GetLang('Cancel')}}</span>
                    </div>
                </div>
            </div>
        </div>
    </div>  
</template>
<script>
import toolsLang from '../../../common/dictionary'
import Urls from '../../../scripts/urls';

export default {
    name: 'MyReservation',
    components: {},
    data: () => ({   
        counter: 0, 
        interval: '', 
        route: '',
        filter: [],
        searchDate: ''                  
    }),
    props:{
        reservations:{
            require: true,
            type: [],
            default(){return []}                
        },            
        search: {
            required: true,
            type: String,
            default: ''
        },
        date:{
            require: true,
            type: Date,
            default: null
        },
        checked:{
            require: true,
            type: Boolean,
            default: false,
        }
    },
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        imgError(){
            var other = require('@/assets/users/img_user_other.png')
            this.model.routeImage = other
        },
        getProggress(){
            var arrReservation = []
           this.reservations.forEach((item) => {
                var sttDate = item.dateTimeInit.replace('T',' ')
                var endDate = item.dateTimeEnd.replace('T',' ')
                var currentDate = new Date()
                sttDate = new Date(sttDate)
                endDate = new Date(endDate)
                var durationMin = (( endDate - sttDate)/1000)/60
                var timeLeft = ((endDate - currentDate)/1000)/60
                var proggress = ((durationMin - timeLeft)*100)/durationMin
                if(proggress < 0){item.color = 'green', item.statusName = toolsLang.getKey('Pendient')}
                if(proggress > 0 && proggress < 100){item.color = 'yellow',item.bColor = "#F3BB23", item.statusName = toolsLang.getKey('InProcess')}
                if(proggress >= 100){item.color = 'red',item.bColor = "#de4848",  item.statusName = toolsLang.getKey('Finished')}
                item.proggress = proggress
                if(item.status == "Cancelled"){item.color = 'red',item.bColor = "#de4848",  item.statusName = toolsLang.getKey('Cancelled')}
                arrReservation.push(item)
            })
            this.reservations = arrReservation;
        },
        getRoute(){
            this.route = Urls.Sites.UrlAPI
        },
        formatDate(){
            if(this.date != null){ 
                var newDate = this.date.toJSON().slice(0,10)
                this.searchDate = newDate.toString()
            }
        },
        updateClock(){
            setInterval(() =>{
                this.getProggress()
            }, 10000)
        },   
        filtering(){
            if(this.search == null) {
                this.filter = this.reservations
            }
            else{this.filter = this.reservations.filter((r) => (r.name).toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))}
        },   
        filterByDate(){
            if(this.searchDate == '') {this.filter = this.reservations}
            else{this.filter = this.reservations.filter((r) => r.dateTimeInit.split('T')[0] == this.searchDate)}
        },
        cancel(id){
            this.$emit('Cancel', id)
        },
        edit(){
            this.$emit('Edit')
        }
    },
    watch:{
        search(){
            this.filtering()
        },
        date(){
            this.formatDate()
            this.filterByDate()
        },
        checked(){
            this.showAll()
        },  
    },
    beforeMount() { // Antes de que se monte
        this.filtering()
        this.updateClock()
        this.getRoute()        
    },
    beforeUpdate(){

    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>
    .vdp-datepicker input {
        border: none;
        background: transparent;
        background-color: transparent !important;
        height: 1% !important;
    }
    .vdp-datepicker__calendar {
        top: 41px;
        left: 0px !important;
    }
</style>