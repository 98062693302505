<template>
    <div class="body">
        <div class="row clearfix">
            <div class="col-lg-12">
                <div class="profile-header" style="text-align:left">
                    <div class="profile-image">
                        <img :src="model.routeImage != '' ? model.routeImage : route" :onerror="imgError()" class="rounded-circle m-b-10" width="180" height="180">
                    </div>
                    <div class="m-b-20">
                        <h4 class="m-b-0">{{model.name+ ' ' + model.app + ' ' + model.apm}}</h4>
                        <span>{{model.email != '' ? model.email : 'Sin dato'}}</span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('DateBirth')}}</h6>
                <div class="input-group mb-3">
                    <input type="text" class="form-control"  readonly v-model="model.birthday">
                </div>                                    
            </div>
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('Gender')}}</h6>
                <div class="input-group mb-3">
                    <input type="text" class="form-control"  readonly :placeholder="TextLang(model.gender.nameEn, model.gender.nameEs)">
                </div>
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-6 col-md-12">
                <h6>RFC</h6>
                <div class="input-group mb-3">
                    <input type="text"  class="form-control" readonly v-model="model.rfc">
                </div>                                    
            </div>
        </div>
        <div style="margin: 20px 0px">
            <span>{{GetLang('Address')}}</span>                                
        </div>
            <div class="row clearfix">                                
            <div class="col-lg-12">
                <h6>{{GetLang('Street')}}</h6>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" readonly v-model="model.street">
                </div>                                    
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('OutNumber')}}</h6>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" readonly v-model="model.streetNumber">
                </div>                                    
            </div>
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('InNumber')}}</h6>
                <div class="input-group mb-3">
                    <input type="text"  class="form-control" readonly v-model="model.interiorNumber">
                </div>                                    
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('Suburb')}}</h6>
                <div class="input-group mb-3">
                    <input type="text" class="form-control" readonly v-model="model.town">
                </div>                                    
            </div>
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('Municipality')}}</h6>
                <div class="input-group mb-3">
                    <select  class="form-control" v-model="model.municipalityId" disabled>
                        <option v-for="municipio in municipalities" :key="municipio.id" :value="municipio.id" :label="municipio.name"></option>
                    </select>
                </div>                                    
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('State')}}</h6>
                <div class="input-group mb-3">
                    <select  class="form-control" v-model="model.stateId" disabled>
                        <option v-for="state in states" :key="state.id" :value="state.id" :label="state.name"></option>
                    </select>                                         
                </div>                                    
            </div>
            <div class="col-lg-6 col-md-12">
                <h6>{{GetLang('Country')}}</h6>
                <div class="input-group mb-3">
                    <input type="text"  class="form-control" readonly v-model="model.country">
                </div>                                    
            </div>
        </div>
        <div style="margin: 20px 0px">
            <span>{{GetLang('Skills')}}</span>                                
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <h6>{{GetLang('Skills')}}</h6>
                <div class="input-group">
                    <div class="badge-container">
                        <span class="tag badge badge-user" v-for="skill in habilities" :key="skill.id" :value="skill.id">
                            {{TextLang(skill.nameEn, skill.nameEs)}}
                        </span>
                    </div>
                </div>                                
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <h6>{{GetLang('Acknowledgments')}}</h6>
                <div class="input-group">
                    <div class="badge-container">
                        <span class="tag badge badge-user" v-for="award in gratitud" :key="award.id" :value="award.id">
                            {{TextLang(award.nameEn, award.nameEs)}}
                        </span>
                    </div>
                </div>                                 
            </div>
        </div>
        <div class="row clearfix">
            <div class="col-lg-12">
                <h6>{{GetLang('Lenguages')}}</h6>
                <div class="input-s">
                    <div class="badge-container">
                        <span class="tag badge badge-user" v-for="idiom in lenguages" :key="idiom.id" :value="idiom.id">
                            {{TextLang(idiom.nameEn, idiom.nameEs)}}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="row clearfix" >
            <div class="col-lg-12">
                <h6>{{GetLang('MSRole')}}</h6>
                <div class="input-group mb-3">
                    <select  class="form-control" v-model="model.roleId" :disabled="type == 'view' ? true : false">
                        <option v-for="role in roles" :key="role.id" :value="role.id" :label="TextLang(role.nameEn, role.nameEs)"></option>
                    </select>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toolsLang from '../../../common/dictionary'
import request from '../../../scripts/request'
import Urls from '../../../scripts/urls'
import {Genders, Skills, Awards, Idioms} from '../../../scripts/catalogs'

export default {
    name:'ViewUser',
    data: () => ({
        route:"https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png",
        Skills: [],
        Awards: [],
        Idioms: [],
        genders: [],
        states: [],
        roles: [],
        municipalities: [],
        language: '',
    }),
    props:{
        model:{
            name: '',
            app: '',
            apm: '',
            ine: '',
            rfc: '',
            email: '',
            birthday: '',
            street: '',
            streetNumber:'',
            interiorNumber: '',
            town: '',
            country: '',
            genderCatalogId: '',
            stateId: '',
            municipalityId: '',
            routeImage: '',
            roleId: '',
            gender:{
                nameEs: '',
                nameEn: ''
            }              
        },
        habilities:{
            required: true,
            type: [],
            default: []
        },
        lenguages:{
            required: true,
            type: [],
            default: []
        },
        gratitud:{
            required: true,
            type: [],
            default: []
        },
        type: {
            require: true,
            type: String,
            default: 'view'
        }
    },
    methods:{
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            this.language = toolsLang.changeLanguage(lang)
        },
        imgError(){
            this.model.routeImage = this.route
        },
        catalogs(){
            Skills().then(dat => {this.Skills = dat
                if(this.habilities.length > 0 && this.habilities[0] == undefined) {
                    const hab = []
                    this.habilities.map(item => {
                        const ha = this.Skills.find(r => r.id == item)
                        hab.push(ha)
                    })
                    this.habilities = hab;
                }
            })
            Idioms().then(dat => { this.Idioms = dat
                if(this.lenguages.length > 0 && this.lenguages[0] == undefined) {
                    const hab = []
                    this.lenguages.map(item => {
                        const ha = this.Idioms.find(r => r.id == item)
                        hab.push(ha)
                    })
                    this.lenguages = hab;
                }
            })
            Awards().then(dat =>  { this.Awards = dat
                if(this.gratitud.length > 0 && this.gratitud[0] == undefined) {  
                    const hab = []
                    this.gratitud.map(item => {
                        const ha = this.Awards.find(r => r.id == item)
                        hab.push(ha)
                    })
                    this.gratitud = hab;
                }
            })

        },
        getMunicipalities(){
			this.municipalities = []
			request.Catalog(Urls.Api.Catalog.Municipality, { id: this.model.stateId }).then(dat => {this.municipalities = dat})
        },
        getRoles(){
            request.ADFetch({
                url: Urls.Api.Catalog.Role.List,
                before: () => {this.roles = []},
                recive: dat => {this. roles = dat}
            })
        }
    },
    beforeMount(){
        this.language = toolsLang.initLanguage()
        Genders().then(dat => { this.genders = dat})
        request.Catalog(Urls.Api.Catalog.State).then(dat => {
            this.states = dat
            if(this.model.stateId == '') {
                this.model.stateId = dat[0].id
            }
            this.getMunicipalities()
        }) 
        this.catalogs()
        this.getRoles()
    },
    beforeUpdate(){
        this.language = toolsLang.initLanguage()
    }    
}
</script>