<template>
   <div class="modal fade show" style="padding-right: 15px; display: block;">
        <div class="modal-dialog" >
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="title">{{GetLang('Details')}}</h4>
                    <div style="float: right" @click="close">
                        <i class="fa fa-times" style="font-size: larger; cursor: pointer" ></i>
                    </div>                    
                </div>
                    <div class="modal-body"> 
                        <div style="width:auto">
                            <img :src=" model.routeImage == '' ? placeholder : route + model.routeImage" width="100%" height="200" style="border-radius: 15px">
                        </div>
                        <p></p>
                        <h5>{{model.name}}</h5>
                        <p>{{model.description}}</p>
                        <h6>{{'Capacidad de '+ model.numberPeople + ' personas'}}</h6>        
                        <span class="tag badge badge-user p-2 m-b-10" style="font-size:small" v-for="service in model.roomservices" :key="service.serviceCatalog" :value="service.serviceCatalog">
                            {{ TextLang(service.nameEn, service.nameEs)}}
                        </span>                                 
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="viewDetail(model.id)">{{GetLang('ViewDetails')}}</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import toolsLang from '../../../common/dictionary'
    import Urls from '../../../scripts/urls';
    export default {
        name: 'DialogRoomDetailComponent',
        data: () => ({
            language: '',
            route: '',
            placeholder: require('@/assets/fondo-sala.png')
        }),
        props:{
            model:{
                require: true,
                type: Object,
                default: ()=>({
                        id: '',
                        name: '',
                        description: '',
                        personChargeId: '',
                        numberPeople:  0,
                        typeRoomCatalog: {
                            id: '',
                            nameEn:'',
                            nameEs:''
                        },
                        location: {
                            address: '',
                            building: '',
                            id: '',
                            municipality: {
                                id:'',
                                name: '',
                                municipalityId: 0
                            },
                            state: {
                                id: 0,
                                name: ''
                            }
                        },
                        personCharge: {
                            email: '',
                            nameComplete: '',
                            phone: '',
                            userId:''
                        },
                        flat: '',
                        area: '',
                        roomservices: [{
                            nameEn:'',
                            nameEs:'',
                            roomServiceId:'',
                            serviceCatalog:''
                        }],
                        routeImage: '',
                        file: {
                            name: '',
                            file: '',
                            extention: ''
                        },             
                })   
            }
        },
        methods:{
            GetLang: key => toolsLang.getKey(key),
            TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },          
            close(){
                this.$emit('Close')
            },
            viewDetail(id){
                this.$router.push({path: '/admin/viewroom', query:{tok: id}})
            }
        },
        watch:{
    
        },
        beforeMount(){
            toolsLang.initLanguage()
            this.route = Urls.Sites.UrlAPI            
        },
        beforeUpdate(){
        }
    }
</script>

<style lang="scss" scoped>
    .modal{
        background-color: rgba(50,50,50,0.4);
        .modal-footer {
            display: inline;
            text-align: center;
        }     
        .modal-dialog{
            width: 400px;
        }   
    }
</style>