<template>
    <div class="modal fade show" style="padding-right: 15px; display: block; ">
        <div class="modal-dialog modal-size" style="max-width: 80%; margin: 10%;" >
            <div class="modal-content" >
                <div class="modal-header">
                    <h4 class="title">{{title}}</h4>
                </div>
                    <div class="modal-body"> 
                        <div class="row">
                            <div class="col-lg-6 col-md-6 col-sm-6"> 
                                <h6>{{GetLang('NameEs')}}</h6>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="data.nameEs">
                                </div> 
                            </div>   
                            <div class="col-lg-6 col-md-6 col-sm-6" > 
                                <h6>{{GetLang('NameEn')}}</h6>
                                <div class="input-group mb-3">
                                    <input type="text" class="form-control" v-model="data.nameEn">
                                </div>
                            </div>   
                        </div>

                        <h6>{{GetLang('Status')}}</h6>
                        <div class="input-group mb-3">
                            <label class="fancy-radio"><input name="status" :value="1" v-model="data.active" type="radio"><span><i></i>{{GetLang('Active')}}</span></label>
                            <label class="fancy-radio"><input name="status" :value="0" v-model="data.active" type="radio"><span><i></i>{{GetLang('Inactive')}}</span></label>
                        </div> 

                        <table-component :tableData="tableData" style="height: 30% ;"></table-component>
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" v-if="!isView" @click="save">{{GetLang('Save')}}</button>
                    <button class="btn btn-danger" @click="close">{{GetLang('Close')}}</button>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
import toolsLang from '../../../common/dictionary'
import request from '../../../scripts/request'
import Urls from '../../../scripts/urls'
import tableComponent from '../table/TablePermissionComponent.vue'

    export default {
        name: 'DialogComponent',
        data: () => ({    
            isView: false,   
            language: '',
            newMember: '',
            members: [],
            permission: [],
            tableData: [],
        }),
        components: {
            tableComponent,
        },
        props:{
            data:{
                require: true,
                type: Object,
                default: () => ({
                    nameEs: '',
                    nameEn: '',
                    active: 0,
                    keyName: ''
                })
            },
            title:{
                require: true,
                type: String,
                default: 'Tittle'
            },
            type: {
                require: true,
                type: String,
                default: ''
            }        
        },
        methods:{
            GetLang: key => toolsLang.getKey(key),
            TextLang: (en,es) => toolsLang.getTranslate(en,es),
            tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
            close(){
                this.$emit('Close')
            },
            save(){
                this.data.members = this.members
                if(this.data.name.trim() == "") {
                    this.$toast.open({
                        message: toolsLang.getKey("NameRequired"),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                    return false
                }
                this.$emit("Correct", this.data)
            },
            addMember(){    
                this.members.push(this.newMember)
                this.newMember = ''
            },
            removeMember(mb){
                if(!this.isView){
                    var index = this.members.indexOf(mb)
                    if(index != -1) this.members.splice(index, 1)                    
                }

            },
            constructor(){
                if(this.type == 'add'){
                    this.members = []         
                }
                else{
                    this.members = this.data.emails
                }
                if(this.type == 'view')this.isView = true;
            },
            listPermission(){
                request.ADFetch({
                    url: Urls.Api.Config.ListPermission,
                    before: () => {this.permission = []},
                    recive: dat => {this.permission = dat
                        this.tableData = dat
                    }
                })
            },
        },

        beforeMount(){
            this.listPermission()
            this.constructor()
        },
        beforeUpdate(){

        }
    }
    </script>

<style lang="scss">
    .select2-container {
        margin: 0;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .modal{
        background-color: rgba(50,50,50,0.4);
    }

</style>
