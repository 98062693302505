<template>
     <div class="row">
        <div class="col">                        
            <h2>
                {{GetLang(meta)}}
                <el-tooltip v-if="crumbs[0].text!='dashboard'" class="item" effect="dark" :content="GetLang('Back')" placement="left-start">
                    <el-button @click="$router.back()" icon="el-icon-back" style="float:right" plain type="info" circle></el-button>
                </el-tooltip>
            </h2>
            <ul class="breadcrumb">
                <li class="breadcrumb-item"><router-link to="/dashboard/index"><i class="icon-home"></i></router-link></li>                            
                <template v-for="(item,i) in crumbs">
                    <li class="breadcrumb-item" v-if="item.text!='dashboard'" :key="i">
                        <router-link :to="item.to">
                            {{ GetLang(item.text) }}
                        </router-link>
                    </li>
                </template>
            </ul>
        </div>   
        <!---        
        <div class="col-lg-7 col-md-4 col-sm-12 text-right">git 
                <div class="inlineblock text-center m-r-15 m-l-15 hidden-sm pt-2" >
                <chart :options="visitors" class="echart-line-chart"></chart>
                <span>Visitors</span>
            </div>
            <div class="inlineblock text-center m-r-15 m-l-15 hidden-sm pt-2">
                <chart :options="visit" class="echart-line-chart"></chart>
                <span>visits</span>
            </div>
        </div>
        -->
    </div>
</template>
<script>

import toolsLang from '../common/dictionary'

export default {
  props: {
    back: {
        require: false,
        default: false
    }
  },
  computed: {
    crumbs: function() {
      let pathArray = this.$route.path.split("/")
      
      pathArray.shift()
      let breadcrumbs = pathArray.reduce((breadcrumbArray, path, idx) => {
        breadcrumbArray.push({
            path: path,
            to: breadcrumbArray[idx - 1]
                ? "/" + breadcrumbArray[idx - 1].path + "/" + path
                : "/" + path,
            text: this.$route.matched[idx].meta.breadCrumb || path,

        });
        return breadcrumbArray;
      }, [])
      return breadcrumbs;
    }
  },methods:{
        GetLang: k => toolsLang.getKey(k),
        layoutFullwidth(){
            this.show = !this.show;
            let sitebody = document.body;
            this.show ? sitebody.classList.add("layout-fullwidth") : sitebody.classList.remove("layout-fullwidth");
        },
        visitorsOptionsSeries() {
            return this.data = [3,5,1,6,5,4,8,3]
        },
         visitOptionsSeries() {
            return this.data = [4,6,3,2,5]
        },
    }, data (){
        return {
            show:false,
            meta:'',
            visitors: {
                grid: {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0
                },
                tooltip : {
                    trigger: 'item',
                    formatter:function(params){
                        var param = params;
                        return param.marker+" "+param.value;
                    }
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    boundaryGap: false,
                },
                yAxis : [
                    {
                        type : 'value',
                        show:false,
                    }
                ],
                series: [
                    {
                        type: 'line',
                        symbolSize: 1,
                        showSymbol: true,
                        data: this.visitorsOptionsSeries(),
                        lineStyle: {
                            width: 1,
                        },
                    }
                ],
                color: ["#00c5dc"]
            },
            visit: {
                grid: {
                    left: 0,
                    top: 0,
                    right: 0,
                    bottom: 0
                },
                tooltip : {
                    trigger: 'item',
                    formatter:function(params){
                        var param = params;
                        return param.marker+" "+param.value;
                    }
                },
                xAxis: {
                    type: 'category',
                    show: false,
                    boundaryGap: false,
                },
                yAxis : [
                    {
                        type : 'value',
                        show:false,
                    }
                ],
                series: [
                    {
                        type: 'line',
                        symbolSize: 1,
                        showSymbol: true,
                        data: this.visitOptionsSeries(),
                        lineStyle: {
                            width: 1,
                        },
                    }
                ],
                color: ["#f4516c"]
            },
        }
    },created() {
        this.meta = this.$route.meta.title;
    }
};
</script>
<style scoped>
.echart-line-chart{
    height: 20px;
    width: 112px;
}
</style>