/*
    TT = Top Title
    T = Title
    D = Description
    B = Button
*/
const dictionary = {
    "Accept":"Ok",
    "AccessDenied":"Access denied",
    "Accions":"Actions",
    "Acknowledgments":"Acknowledgments",
    "Action":"Action",
    "ActionRequired":"Action required",
    "Active":"Active",
    "actived":"Actived",
    "ActiveUser":"Active user",
    "add":"add",
    "AddAction":"Add action",
    "AddCatalog":"Add catalog",
    "Added":"Added",
    "AddExternalPersonal":"Add external staff email",
    "AddFile":"Add file",
    "AddGroup":"Add group",
    "AddingGroup":"Group added",
    "AddingLocation":"Location added",
    "AddingRole":"Role added",
    "AddingService":"Service added",
    "AddingTypeRoom":"Room type added",
    "AddInternalPersonal":"Add posco staff email",
    "AddLocation":"Add location",
    "AddMembers":"Add members to the group",
    "AddNew":"Add",
    "AddNewUser":"Add new user",
    "AddReservation":"Add reservation",
    "Address":"Address",
    "AddRole":"Add role",
    "AddRoom":"Add room",
    "AddService":"Add service",
    "AddTypeRoom":"Add room type",
    "admin":"admin",
    "AdminCatalog":"Catalog management",
    "AdminRolesPermssion":"Roles and permissions management",
    "AdressRequired":"Address is required",
    "Alert":"Alert",
    "All":"All",
    "AllReservations":"All reservations",
    "Area":"Area",
    "AreaRequired":"Area is required",
    "AreYouSureToCancel":"Do you agree to cancel the reservation?",
    "AreYouSureToRemoveIt":"Do you agree to remove it?",
    "AreYouSureToRemoveRoom":"Do you agree to delete the room?",
    "Atention":"Attention",
    "Back":"Back",
    "BAddPermission":"Add a permission",
    "Branch":"Branch office",
    "BRestorePassword":"Restore password",
    "Building":"Building",
    "BuildingRequired":"Building is required",
    "calendar":"calendar",
    "Cancel":"Cancel",
    "Cancelled":"Cancelled",
    "CancelReservation":"Cancel reservation and meeting",
    "CapacityPeople":"People capacity",
    "CapacityRequired":"People capacity is required",
    "catalog":"catalog",
    "Catalogs":"Catalogs",
    "charts":"Charts",
    "Close":"Close",
    "Code":"Code",
    "CodeRequired":"Code required",
    "Company":"Company",
    "Country":"Country",
    "dashboard":"dashboard",
    "DataCatalogNotExist":"Catalog does not exist",
    "DataCatalogRegistered":"The data for the catalog is already registered",
    "DataLocationNotExist":"Location does not exist",
    "DataLocationRegistered":"The data for the location is already registered",
    "DataRoomNotDelete":"The room cannot be deleted because already has reservations",
    "DataRoomNotExist":"The room does not exist",
    "DataRoomRegistered":"The data for the room is already registered",
    "DataRoomRequired":"Room required",
    "Date":"Date",
    "DateBirth":"Date of birth",
    "DateRequired":"Date is required",
    "Delete":"Delete",
    "Deleted":"Deleted",
    "Description":"Description",
    "DescriptionRequired":"Description required",
    "Details":"Details",
    "DForgotPasswordPassword":"Please enter your email, we will send you instructions to reset your password",
    "Edit":"Edit",
    "Edited":"Edited",
    "EditedGroup":"Group edited",
    "EditedLocation":"Location edited",
    "EditedRole":"Role edited",
    "EditedService":"Service edited",
    "EditedTypeRoom":"Room type edited",
    "EditGroup":"Edit group",
    "EditLocation":"Edit location",
    "EditReservation":"Edit reservation",
    "EditRole":"Edit role",
    "EditRoom":"Edit room",
    "EditService":"Edit service",
    "EditTypeRoom":"Edit room type",
    "Email":"Email",
    "End":"End",
    "EndTimeMessage":"The end time cannot be earlier than the start time",
    "EndTimeRequired":"End time is required",
    "ErrorDateFormat":"Wrong date format",
    "ErrorDateInitEndMeeting":"Please verify that the date, start time and end time are correct",
    "ErrorDeleteLocationUsed":"The location cannot be deleted because it is being used by a room",
    "ExistingRoleNameEN":"English name already exists for another role",
    "ExistingRoleNameES":"Spanish name already exists for another role",
    "ExpiredToken":"Token has expired or you are not authorized",
    "ExtensionAllowed":"It is only possible to upload jpg or png",
    "ExtensionPDFAllowed":"It is only possible to upload pdf",
    "ExtensionRequired":"Extension is required",
    "ExternalPersonal":"External staff email",
    "FailSave":"Saving error",
    "FieldRequired":"Complete the required fields",
    "File":"File",
    "FileNotSave":"File not save",
    "Filter":"Filter",
    "FilterByName":"Filter by name",
    "FilterHere":"Filter here",
    "FilterStaff":"Type here to filter staff",
    "Finished":"Finished",
    "finished":"Finished",
    "Flat":"Floor",
    "FlatRequired":"Floor is required",
    "ForgotMyPassword":"I forgot my password",
    "ForgotPassword":"You have requested to reset your password, click the button below to continue with the process.",
    "ForgotPwd":"Forgot password?",
    "FormatEncryptionIncorrect":"The password does not have the correct encryption format.",
    "From":"From",
    "Gender":"Gender",
    "Group":"Group",
    "Groups":"Groups",
    "Hour":"Hour",
    "ImageNotSaved":"Image could not be saved",
    "ImageRequired":"Image is required",
    "Inactive":"Inactive",
    "InactiveUser":"Inactive user",
    "IncorrectCredentials":"Credentials are incorrect",
    "index":"home",
    "IneRequired":"Identification required",
    "Information":"Information",
    "InformationCorrect":"The information is correct?",
    "InNumber":"Indoor number",
    "InProcess":"In process",
    "InstructionResetPassword":"We have sent you an e-mail with instructions on how to reset your password at",
    "InternalPersonal":"Posco staff email",
    "InvalidEmail":"Data entered is not a valid email",
    "IsRequired":"Required",
    "Justification":"Justification",
    "KnowYourPwd":"Do you know your password?",
    "LangEnglish":"English",
    "LangSpanish":"Spanish",
    "LanguageRequire":"At least one language must be captured",
    "Languages":"Languages",
    "list":"list",
    "ListByCard":"List by room",
    "listroom":"Room list",
    "listrooms":"Rooms list",
    "Loading":"Loading...",
    "Location":"Location",
    "LocationRequired":"Location is required",
    "locations":"locations",
    "LoggingIn":"Logging in ...",
    "LogginOn":"Loggin on",
    "Login":"Login",
    "LoginCredentials":"Enter your login credentials",
    "LogOn":"Log on",
    "Logout":"Logout",
    "MAdminRooms":"Administrator rooms",
    "MailSent":"Mail sent successfully ",
    "MCatalogs":"Catalogs",
    "MeetingNameRequired":"Meeting name is required",
    "MeetingUpdated":"Meeting updated",
    "Menu":"Menu",
    "MHome":"Home",
    "MReports":"Reports",
    "MReservations":"Reservations",
    "MSAddRoom":"Add room",
    "MSGroups":"Groups",
    "MSLocations":"Locations",
    "MSMyReservations":"My reservations",
    "MSRole":"Roles and permissions",
    "MSRoomList":"Room list",
    "MSRooms":"Rooms",
    "Municipality":"Municipality",
    "MUsers":"Employees",
    "MyProfile":"My profile",
    "myreservations":"my reservations",
    "Name":"Name",
    "NameEn":"Name(EN)",
    "NameEnglish":"Name in english",
    "NameEs":"Name(ES)",
    "NameMeeting":"Meeting name",
    "NameRequired":"Name is required",
    "NameSpanish":"Name in spanish",
    "NewLocation":"New location",
    "NewMeeting":"New meeting",
    "NewPassword":"New password",
    "Next":"Next",
    "NoConnection":"You are not connected",
    "NoData":"No data",
    "NoErrors":"There are no errors to show",
    "NotRemovedRoom":"This room cannot be deleted, it has reservations",
    "NumberPeopleRequired":"The number of persons must be greater than 0",
    "Office":"Office",
    "OutNumber":"Outdoor number",
    "Participants":"Participants",
    "Password":"Password",
    "PasswordStrong":"Enter your new password and to reinforce the security of it do not forget to put letters and numbers.",
    "pendient":"Pending",
    "Pendients":"Pending",
    "PermissionDenied":"Permission denied",
    "PermissionRequired":"You need to add at least one permission",
    "Permissions":"Permissions",
    "Personal":"Employees",
    "PersonalIformation":"Personal information",
    "PersonalInChargeRoom":"Staff in charge of the room",
    "PersonalList":"List of employees",
    "PersonalRequired":"Staff in charge of the room is required",
    "PersonCharge":"Staff in charge",
    "Phone":"Phone",
    "Previous":"Previous",
    "process":"In process",
    "Profile":"Profile",
    "ProfileImageRequired":"User profile picture is required",
    "Protected":"Protected",
    "Records":"Files",
    "Recovering":"Recovering",
    "Recovery":"Recovery",
    "RecoveryPassword":"Password recovery",
    "RegisteredEmail":"Email already exists for another user",
    "RegisteredRole":"The role is already registered",
    "RegisterUser":"Register user",
    "ReminderMeeting":"Reminder",
    "reports":"Reports",
    "RequiredEmail":"Email is required",
    "RequiredPassword":"Password is required",
    "RequiredPermissions":"Permissions are required",
    "RequiredProfilePic":"User profile picture is required",
    "RequiredPwd":"Password is required",
    "RequiredRole":"Role is required",
    "RequiredUser":"User is required",
    "RequiredUsername":"Username is required",
    "reservation":"reservation",
    "ReservationAdded":"Reservation added",
    "ReservationCanceled":"Reservation canceled",
    "ReservationCancelled":"Reservation cancelled",
    "ReservationConfirmed":"Reservation confirmed",
    "ReservationExist":"Same reservation already exists",
    "ReservationExistDate":"There is already another reservation with that date and time",
    "ReservationNotExist":"The reservation does not exist",
    "reservations":"reservations",
    "Reserve":"Reserve",
    "ReserveRoom":"Reserve a room",
    "ResetPwd":"Reset password",
    "RestorePassword":"Do you know your password?",
    "Results":"Results",
    "Role":"Role",
    "RoleAdded":"Role added successfully",
    "RoleAddError":"Could not add new role",
    "RoleNotFound":"Role was not found",
    "roles":"roles",
    "RolesPermissions":"Roles and permissions",
    "RoleUpdated":"The role was successfully updated",
    "RoleUpdateError":"Error updating role",
    "Room":"Room",
    "ShowReservation":"Show reservation",
    "To":"to",
    "Update":"Update",
    "UpdatePassword":"Update password",
    "Seats":"Seats",
    "Welcome":"Welcome",
    "Search":"Search",
    "Street":"Street",
    "TextButtonRecoveryEmail":"Change password",
    "Suburb":"Suburb",
    "UpdatedPwd":"Updated password",
    "UsersRequired":"There must be at least one participant in the meeting",
    "ScheduleRequired":"El horario es obligatorio",
    "TypeRoomRequired":"Type of room is required",
    "SectionRoomRequired":"The room section is required",
    "TokenExpired":"Token expired",
    "SendingEmail":"Sending email",
    "SendReminder":"Send reminder",
    "Team":"Team",
    "TypeToSearch":"Type to search",
    "State":"State",
    "Status":"Status",
    "Saving":"Saving",
    "Save":"Save",
    "Skills":"Skills",
    "TextReset":"Enter your new password and to reinforce the security of it do not forget to put letters and numbers.",
    "Start":"Start",
    "StartTimeRequired":"Start time is required",
    "UserList":"Employees list",
    "RoomName":"Room name",
    "UserName":"Username",
    "TTForgotPassword":"Forgot my password",
    "TextRecovery":"Please enter your email address, we will send you instructions to reset your password.",
    "TextRecoveryEmail":"We received a request to reset your password. If you did not make this request, please ignore this email.",
    "SentReminder":"Sent reminder",
    "TRecoverPassword":"Recover password",
    "TitleRecoveryEmail":"Reset your password",
    "UpdateMeeting":"Updated meeting",
    "RoomAdded":"Room added",
    "VideoconferenceRoom":"Videoconference room",
    "RoomEdited":"Room edited",
    "RoomRemoved":"Room removed",
    "RoomSortOk":"Room sorted",
    "rooms":"rooms",
    "SectionRoom":"Section room",
    "SelectCharge":"Select the staff in charge",
    "SelectTypeRoom":"Room type is required",
    "SelectDate":"Select date",
    "SelectRoom":"Select a room",
    "SelectSectionRoom":"Select section room",
    "SelectLocation":"Select a location",
    "Services":"Services",
    "SubtitleRecoveryEmail":"If you want to reset your password, click on the following link",
    "UploadRoomImage":"Upload an image of the room",
    "UploadImage":"Upload image",
    "TextMailSent":"We have sent you an email with instructions on how to reset your password at ",
    "Type":"Type",
    "TypeRoom":"Room type",
    "types":"types",
    "TypesRoomSection":"Section types",
    "TypesRoom":"Room types",
    "user":"user",
    "show":"show",
    "view":"view",
    "ViewCalendar":"View calendar",
    "ViewDetails":"View details",
    "ViewList":"View list",
    "ViewRoom":"view room",
    "ShowAllReservations":"Show all reservations",
    "UserView":" Employee view",
    "Editing":"Editing",
    "InitDate":"Start date",
    "EndDate":"End date",

    "hostUser":"Host user",
    "InitDateMeeting":"Initial meeting date",
    "EndDateMeeting":"End meeting date",
    "GuestInternal":"Internal guest",
    "GuestExternal":"External guest",
    "CarriedOut":"Carried out",
    "InProgress":"In progress",
    "Scheduled":"Scheduled",

    "MoreFilters":" More filters",
    "LessFilters":" Less filters",
    "GeneralFilter":"Filters general",
    "RoomFilter":"Filters by room",
    "StatusFilter":"Filters by status",
    "HostFilter":"Filters by host",
    "PersonalChargeFilter":"Filters by personal in charge",
    "CleanFilters":"Clean filters",

    "AddSectionRoom": "Add section",
    "SectionEnd": "In the end",
    "Position": "Position",
    "UploadSectionRoomImage": "Upload a section image",
    "before": "Before ",
    "RequiredImage": "Required image",
    "FilterRoomTypeSection":"Section type",

    "Schedule": "Schedule",
    "Monday": "Monday",
    "Tuesday": "Tuesday",
    "Wednesday": "Wednesday",
    "Thursday": "Thursday",
    "Friday": "Friday",
    "Saturday": "Saturday",
    "Sunday": "Sunday",
    "Available24": "Available 24 hours",
    "MainRoom": "Main room",
    "SelectMainRoom": "Select main room",
    "ErrorEmptySchedule": "Please select an available time or set to 24-hour mode",
    "ErrorHoursSchedule": "Please verify that the start time is before the end time",
    "ErrorHoursSegmentsSchedule": "Please verify that the schedules do not overlap",
    "ReserveBlockTime": "Reserve and block time segments",
    
 }
export default dictionary