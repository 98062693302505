<template>
    <div class="main-content">
        <div class="container-fluid" v-if="visible">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div style="margin: 15px; float: right; " >
                <button class="btn btn-search"  style="margin: auto; margin-right: 0px;" type="button" @click=showCalendar>{{GetLang('ViewCalendar')}}</button>
            </div>
            <div class="card">
                <div class="body">
                    <h4>{{GetLang('MSRooms')}}</h4>
                    <div class="input-group m-b-10">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{GetLang('Filter')}}</span>
                        </div>
                        <input type="text" class="form-control" :placeholder="GetLang('TypeToSearch')" v-model="search">
                    </div>
                </div>
            </div>
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12 p-4" v-for="room in filtering" :key="room.id">
                    <div class="card" >
                        <div class="room-img" :style="room.routeImage == '' ? 'background-image: url(' + require('@/assets/fondo-sala.png') +')' : 'background-image: url(' + (route + room.routeImage) + ')'" @click="details(room.id)"></div>
                        <div class="body-card">
                            <div class="row">
                                <div class="col-10">
                                    <h5>{{TextLang(room.nameEn, room.nameEs)}}</h5>
                                </div>
                                <div class="col-2">
                                    <i :class="'status-room-' + room.availability" class="fa fa-circle" style="font-size: 30px"></i>
                                    <!-- <i v-if="room.status == 'Inactive'" class="fa fa-circle text-danger" style="font-size: 30px"></i>
                                    <i v-if="room.status == 'Occupied'" class="fa fa-circle text-brown" style="font-size: 30px"></i> -->
                                </div>
                            </div>
                            <span>{{TextLang(room.typeRoom.nameEn, room.typeRoom.nameEs)}}</span><br>
                            <button class="btn btn-primary m-t-10" @click="addReservation(room.id)">{{GetLang('Reserve')}}</button>
                            <div style="float: right">
                                <button type="button" style="padding: 5px 10px" class="btn btn-outline-secondary m-t-10 m-r-5" @click="showList(room.id)"><i class="fa fa-calendar"></i></button>
                                <button v-if="isARDelete" type="button" style="padding: 5px 10px" class="btn btn-danger m-t-10"  @click="deleteRoom(room.id)"><i class="fa fa-trash"></i></button>
                                <button v-if="isAREdit" type="button" style="padding: 5px 10px" class="btn btn-outline-secondary m-l-5 m-t-10" @click="edit(room.id)"><i class="fa fa-pencil"></i></button>                                                                    
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <dialog-room-detail v-if="viewVisible" @Close="close" @View="details(model, 'view')" @Detail="details($event, 'detail')" :model="model"></dialog-room-detail>                 
        </div>
        <add-reservation v-if="reservationVisible && data!=null" :data="data" @Cancel="close" @Save="Save" type="add" :isSending="isSending"></add-reservation>
        <list-reservation v-if="listVisible" :reservations="reservations" :search="search"></list-reservation>
    </div>
</template>


<script>

import BreadCrumb from '../../components/BreadCrumb.vue'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import DialogRoomDetail from '../../components/admin/dialog/DialogRoomDetailComponent.vue'
import AddReservation from '../../components/admin/reservation/reservationComponent.vue'
import ListReservation from '../../components/admin/reservation/reservationListComponent.vue'
import constants from '../../scripts/constants'

export default {
    name: 'AddRoom',
    components:{BreadCrumb, DialogRoomDetail, AddReservation, ListReservation},    
    data: () => ({
        search: '',
        filter: [],
        rooms:[],
        reservations: [],
        userRole: '',
        route: '',
        visible: true,
        viewVisible: false,
        editVisible: false,
        reservationVisible: false,
        listVisible: false,
        isAREdit: false,
        isARDelete: false,
        person:{
            email: '',
            nameComplete: '',
            phone: '',
            userId:''
        },
        data: {
            id: '',
            name: '',
            description: '',
            justify: '',
            status: '',
            date:'',
            dateTimeInit: '',
            dateTimeEnd: '',
            room: {
                name: '',
                description: '',
                numberPeople: 0,
                flat: '',
                area: '',
                status: '',
                typeRoom:{
                    id: '',
                    nameEn: '',
                    nameEs: ''
                },
                manager: {
                    nameComplete: '',
                    email: '',
                    phone: '',
                },                    
                location: {
                    address: '',
                    name: '',
                    stateId: 0,
                    municipalityId: 0,
                    municipality: ''
                },
            },
            users: {
                id: '',
                email: '',
                personal: {
                id: '',
                name: '',
                routeImage: ''
                }
            },
            internalUsers: [],
            externalUsers: [],
            groups:[]
        },
        model:{
            last: false,
            id: '',
            name: '',
            description: '',
            personChargeId: '',
            numberPeople:  0,
            typeRoomCatalog: {
                id: '',
                nameEn:'',
                nameEs:''
            },
            location: {
                address: '',
                building: '',
                id: '',
                municipality: {
                    id:'',
                    name: '',
                    municipalityId: 0
                },
                state: {
                    id: 0,
                    name: ''
                }
            },
            personCharge: {
                email: '',
                nameComplete: '',
                phone: '',
                userId:''
            },
            flat: '',
            area: '',
            roomservices: [{
                nameEn:'',
                nameEs:'',
                roomServiceId:'',
                serviceCatalog:''
            }],
            routeImage: '',
            file: {
                name: '',
                file: '',
                extention: ''
            },             
        },
        isSending: false,        
    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        imgError(){

        },
        list(){
            const filter = this.$route.query.section            
            request.ADFetch({
                url: Urls.Api.Room.List,
                data: {filterStatus: 0, status: filter},
                before:()=>{this.rooms = []},
                recive: dat => {
                    dat.forEach(item => {
                        item.routeImage = item.routeImage.split('\\').join('/')
                    })
                    this.rooms = dat
                }
            })  
            this.route = Urls.Sites.UrlAPI
        },
        getSession(){
            this.userRole = this.$store.getters.role
            let arrPermission = this.$store.getters.permissions
            arrPermission.forEach(item =>{
                item.childs.forEach(c =>{
                    if(c.keyName == constants.Permissions.AR_EDIT){
                        this.isAREdit = true
                    }
                    if(c.keyName == constants.Permissions.AR_DELETE){
                        this.isARDelete = true
                    }
                })
                
            })
        },
        showCalendar(){
            const filter = this.$route.query.section
            if(filter != null){
                this.$router.push('/reservation/listrooms?section=' + filter)
            }
            else{
                this.$router.push('/reservation/listrooms')
            }
        },
        details(id){
            request.ADFetch({
                url: Urls.Api.Room.Detail,
                data: {
                    roomId: id
                },
                recive: data => {
                    this.model = data
                },
            })
            this.viewVisible = true
        },
        showList(id){
            this.$router.push({path: '/reservation/reservations', query: {tok: id}})
        },
        edit(id){
            this.$router.push({path: '/admin/editroom', query: {tok: id} });
        },
        addReservation(id){
            request.ADFetch({
                url: Urls.Api.Room.Detail,
                data: {
                    roomId: id
                },
                recive: data => {
                    this.data = {
                        id: '',
                        name: '',
                        description: '',
                        status: '',
                        date: '',
                        dateTimeInit: '',
                        dateTimeEnd: '',                        
                        room: {
                            id: data.id,
                            name: data.name,
                            description: data.description,
                            numberPeople: data.numberPeople,
                            flat: data.flat,
                            area: data.area,
                            status: data.status,
                            typeRoom:{
                                id: data.typeRoomCatalog.id,
                                nameEn:  data.typeRoomCatalog.nameEn,
                                nameEs:  data.typeRoomCatalog.nameEs
                            },
                            manager: {
                                nameComplete: data.personCharge.nameComplete,
                                email: data.personCharge.email,
                                phone: data.personCharge.phone,
                            },
                            location: {
                                address: '',
                                name: '',
                                stateId: 0,
                                municipalityId: 0,
                                municipality: ''
                            },
                        },    
                        users: {
                            id: '',
                            email: '',
                            personal: {
                            id: '',
                            name: '',
                            routeImage: ''
                            }
                        },
                        internalUsers: [],
                        externalUsers: [],
                    }
                }                
            })
            
            this.visible = false
            this.reservationVisible = true
        },
        Save(model){
            var groupsId = []
            model.groups.forEach((item) => {
                groupsId.push(item.id)
            })
            if(model.date == null || model.timeInit == '' || model.timeEnd == ''){
                this.$toast.open({
                    message: toolsLang.getKey('ScheduleRequired'),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false;
            }
            if(model.internalPersonal.length == 0 && model.externalPersonal.length == 0 && model.groups.length == 0){
                this.$toast.open({
                    message: toolsLang.getKey('UsersRequired'),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false;
            }
            request.ADFetch({
                url: Urls.Api.Reservation.Add,
                data: {
                    roomId: model.roomId,
                    name: model.name,
                    description: model.description,
                    // justify: model.justify,
                    date: model.date,
                    startTime: model.timeInit,
                    endTime: model.timeEnd,
                    internalPersonal: model.internalPersonal,
                    externalPersonal: model.externalPersonal,
                    groups: groupsId
                },
                before: () => {this.isSending = true},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("ReservationAdded"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                    this.close()
                },
                complete: () => {this.isSending = false},
                failed: (dat) => {
                    this.$toast.open({
                        message: dat,
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                }
            })
        },
        deleteRoom(id){
            this.$confirm(toolsLang.getKey("AreYouSureToRemoveRoom"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
                .then(()=>{
                    request.ADFetch({
                        url: Urls.Api.Room.Delete,
                        data:{roomId: id},
                        success:() => {
                            this.$toast.open({
                                message: toolsLang.getKey("RoomRemoved"),
                                type: "success",
                                duration: 3000,
                                position: 'top'
                            }) 
                        },
                        failed: () => {
                            this.$toast.open({
                                message: toolsLang.getKey("NotRemovedRoom"),
                                type: "error",
                                duration: 3000,
                                position: 'top'
                            }) 
                        },
                        complete: () => {this.list()}
                    })
                }).catch(()=>{})
        },
        close(){
            this.viewVisible = this.editVisible = this.reservationVisible = false
            this.visible = true
        }     
    },
    watch:{
        search(){
            this.filtering
        }
    },    
    beforeMount() { // Antes de que se monte
        toolsLang.initLanguage()
        this.list()
        this.getSession()
    },
    beforeUpdate(){
        toolsLang.initLanguage()
        this.getSession()
    },
    computed: { // actualiza cuando cambia algo en el dom
        filtering() {
            const section = this.$route.query.section
            if (this.search == '' && section == null) return this.rooms;
            let filtered = this.rooms.filter((r) =>
                (r.name + ' ' + r.typeRoom.nameEs + ' ' + r.typeRoom.nameEn).toLowerCase().includes(this.search.toLowerCase()));
            if(section != null){
                //filtered = this.rooms.filter((r) => (r.section).includes(section));
                filtered = this.rooms.filter((r) => (r.sectionRoomType.id ).includes(section));
            }
            return filtered
        }        
    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>
    img{
        border-top-left-radius: 0.5rem;
        border-top-right-radius: 0.5rem;
        height: 250px;
        width: 100%;
    }
    .btn-search{
        background: #00436a;
        color: white;
        padding: 5px 35px;
        display: block;
    }
    .btn{
        padding-left: 25px;
        padding-right: 25px;
    }
    
</style>