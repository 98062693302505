<template>
   <div class="vertical-align-wrap">
        <div class="vertical-align-middle auth-main">
            <div class="auth-box">
                <div class="top">
                    <img src="" alt="posco-salas">
                </div>
                <div class="card">
                    <div class="header">
                        <p class="lead">{{GetLang('TRecoverPassword')}}</p>
                        <el-dropdown class="el-static-lang" trigger="click" @command="onCommandLang">
                            <span class="el-dropdown-link">
                                <i class="flag-icon" :class="{ ['flag-icon-' + language]: true }"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown">
                                <el-dropdown-item command="us">
                                    <i class="flag-icon flag-icon-us mr-15"></i> {{GetLang('LangEnglish')}}
                                </el-dropdown-item>
                                <el-dropdown-item command="mx">
                                    <i class="flag-icon flag-icon-mx mr-15"></i> {{GetLang('LangSpanish')}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </div>
                    <div class="body">
                        <p>{{GetLang('DForgotPasswordPassword')}}</p>
                        <form class="form-auth-small" v-on:submit.prevent="submit">
                            <div class="form-group">                                    
                                <input type="text" class="form-control" id="signup-password" :placeholder="GetLang('Email')" >
                            </div>
                            <button type="submit" class="btn btn-primary btn-lg btn-block">{{GetLang('BRestorePassword')}}</button>
                            <div class="bottom">
                                <span class="helper-text">{{GetLang("RestorePassword")}} <router-link to="/authentication/page-login">{{GetLang("BLogin")}}</router-link></span>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toolsLang from '../../common/dictionary'

export default {
    name:'ForgotPasswordComponent',
    methods: {
        submit(e){
           this.$router.push({ path: '/authentication/page-login' })
             e.preventDefault()
            //...
        },
        GetLang: k => toolsLang.getKey(k),
        onCommandLang(lang) {
            this.language = toolsLang.changeLanguage(lang)
        },
    },
     beforeMount(){
        this.language = toolsLang.initLanguage()
    },
}
</script>
<style scoped>

</style>