<template>
    <div class="main-content"   >
        <div class="container-fluid" >
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card">
                        <div class="body">
                            <div class="row">
                                <div class="col-lg-3">
                                    <b>{{GetLang('Company')}}</b>
                                    <div class="input-group mb-3">
                                        <el-input v-model="company"></el-input>
                                    </div>
                                </div>
                                <div class="col-lg-3">
                                    <b >{{GetLang('Date')}}</b>
                                    <div class="input-group mb-3">
                                        <el-date-picker 
                                            @change="listRooms" 
                                            v-model="date" 
                                            :placeholder="GetLang('SelectDate')">
                                        </el-date-picker>
                                    </div>
                                </div>
                                <div class="col-lg-6" style="margin: auto;" >
                                    <div>
                                        <button class="btn btn-search"  style="margin: auto; margin-right: 0px;" type="button" @click="showCards">{{GetLang('ViewList')}}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-md-12">
                                    <div class="table-responsive table-scroll" oncontextmenu="return false;">
                                        <table class="table table-striped" id="calendar" ref="calendar">
                                            <thead class="thead-ligth calendar-container">
                                                <tr >
                                                    <th style="border-right:solid 0px #a9c2e2">{{GetLang('Hour')}}</th>
                                                    <th style="border-left:solid 0px #a9c2e2"></th>
                                                    <th style="vertical-align:middle;" colspan="2" rowspan="2" v-for="hora in header" :key="hora.hour">{{hora.hour}}</th>                                                    
                                                </tr>
                                                <tr>
                                                    <th ref="rooms-column" style="width: 230px !important;">{{GetLang('MSRooms')}}</th>
                                                    <th ref="seats-column">{{GetLang('Seats')}}</th>                                                    
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="room in ListCalendar" :key="room.id">
                                                    <td style="cursor:pointer; width: 230px !important; min-width: 230px;" @click="details(room.id)">{{TextLang(room.nameEn, room.nameEs)}}</td>
                                                    <td align="center">{{room.numberPeople}}</td>
                                                    <td v-for="(hora, h) in halfHour" :key="hora.hour" 
                                                        :id="room.id + ',' + dateId + 'T' + hora.id" 
                                                        :data-init="hora.id"
                                                        :data-end="halfHour[h+1]?.id"
                                                        :data-col="'room_'+room.id"
                                                        data-blocked="no"
                                                        class="columnRoom"
                                                        style="cursor:pointer;"
                                                        @touchstart="touchStart(room.id + ',' + dateId + 'T' + hora.id, room)"
                                                        @touchend="touchEnd"
                                                        @mouseup="selectMouseUp"
                                                        @mousemove="selectMouseMove(room.id, $event )"  
                                                        @mouseenter="selectHour(room.id + ',' + dateId + 'T' + hora.id, room)"
                                                        @click="showReservationHour(room, room.id + ',' + dateId + 'T' + hora.id)"
                                                        >
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <dialog-room-detail v-if="viewVisible" @Close="viewVisible = false" @View="details(model)" @Detail="details($event)" :model="model"></dialog-room-detail>
        </div>
        <!-- <div style="background: blue; height: 50px; cursor: pointer;" @mousedown="test"></div> -->
        <div class="modal fade show" v-if="addVisible" style="padding-right: 15px; display: block;">
            <div class="modal-dialog modal-lg" style="height: 100%;">
                <div class="modal-content"  style="height: auto; background: transparent !important; border: none">
                    <div class="main-content">
                        <div class="modal-body">
                            <add-reservation @close="close" :type="typeReservation" :data="data" :isSending="isSending" @Cancel="close(false)" @Save="save"></add-reservation>              
                        </div>
                    </div>
                </div>
            </div>
        </div>                
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import Tools from '../../scripts/tools'

import DialogRoomDetail from '../../components/admin/dialog/DialogRoomDetailComponent.vue'
import AddReservation from '../../components/admin/reservation/reservationComponent.vue'

export default{
    name: 'ReservationByCalendar',
    components:{BreadCrumb, DialogRoomDetail, AddReservation},
    data(){

        const hal = []
        const hed = []
        for(let i = 0, h = 5; h < 22; i+= 30){
            let d = i < 10 ? '0' + i : i
            let j = h < 10 ? '0' + h : h
            if(i == 0){
                hal.push({ id: j + ":" + d + ':00', hour: j + ":" + d })
                hed.push({ id: j + ":" + d, hour: j })
            } else if(i == 30){
                hal.push({ id: j + ":" + d + ':00', hour: j + ":" + d })
            } else if(i == 60){
                h+=1
                i = -30
            }
        }
        //hal.push({ id:  '23:00:00', hour: '23:00' })
          //      hed.push({ id: '23:00', hour: '23' })
        return {
            selectedId: undefined,
            selectedRoom: {},
            company: 'POSCO - MÉXICO',
            date: new Date(),
            dateId: Tools.getDate(),
            viewVisible: false,
            addVisible: false,
            celda: {},
            selected: [],
            ListCalendar: [],
            model: {},
            data: this.getDataItem(),
            halfHour: hal,
            header: hed,
            typeReservation: 'add',
            timer: 0,
            touchDuration: 500,
            cell: {},
            posX: [],
            posY: [],
            rigth: false,
            left: false,
            isTouch: false,
            isSending: false
        }
    },
    methods: {
        test(event){
            console.log(event.type)
            // if(event.name)
            //     console.log('yes')

        },
        touchStart(id) {
            this.timer = setTimeout(this.onlongtouch, this.touchDuration)
            this.cell = document.getElementById(id)        
            this.isTouch = true
        },        
        touchEnd() {
            if (this.timer)
                clearTimeout(this.timer); // clearTimeout, not cleartimeout..
        },
        onlongtouch(){
            if(this.cell.dataset.blocked != 'occuped' && this.cell.classList[1] != 'blocked'){
                this.selected.push(this.cell.id)
                this.cell.style.background = "#538dc6"
                if(this.selected.length > 1){
                    const inicio = new Date(this.selected[0].split(',')[1])
                    const fin = new Date(this.selected[1].split(',')[1])
                    const dataFormated = new Intl.DateTimeFormat('es-MX', { dateStyle: 'medium', timeStyle: 'medium' })
                    for (let i = inicio; i <= fin; i = new Date(i.getTime() + 60000 * 30)) {
                        const date = (this.selected[0].split(',')[1]).split('T')[0]
                        const hour = dataFormated.format(i).split(' ')[3]
                        this.cell = document.getElementById(this.selected[0].split(',')[0] + ',' + date + 'T'  + hour)
                        if(this.cell != null){
                            this.cell.style.background = "#538dc6"
                            this.cell.dataset.reservationId = i
                            const period = (this.selected[0].split(',')[1]).split('T')[0].substring(0,5) + ' - '+(this.selected[1].split(',')[1]).split('T')[0].substring(0,5)
                            this.cell.title = i.name + " (" + period + ")"
                        }
                    }   
                    setTimeout(() => this.getTimes(), 500)
                }
                this.isTouch = false
            }   
        },
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        getDataItem(room = null, timeInit = '', timeEnd = ''){
            room ??= {
                name: '',
                description: '',
                numberPeople: 0,
                flat: '',
                area: '',
                status: '',
                typeRoom:{
                    id: '',
                    nameEn: '',
                    nameEs: ''
                },
                manager: {
                    nameComplete: '',
                    email: '',
                    phone: '',
                },                    
                location: {
                    address: '',
                    name: '',
                    stateId: 0,
                    municipalityId: 0,
                    municipality: ''
                },
            }
            return {
                id: '',
                name: '',
                description: '',
                status: '',
                date: new Date(this.date),//Tools.getDate(this.date),
                dateTimeInit: timeInit,
                dateTimeEnd: timeEnd,
                room: room,
                users: {
                    id: '',
                    email: '',
                    personal: {
                    id: '',
                    name: '',
                    routeImage: ''
                    }
                },
                internalUsers: [],
                externalUsers: [],
                groups:[]
            }
        },
        convertHoursToMinuts(time) {
            const [hours, minutes] = time.split(":");
            return parseInt(hours) * 60 + parseInt(minutes);
        },
        showReservationHour(room, elementId){
            console.log(room)
            const cell = document.getElementById(elementId)
            if(cell.style.background == "" || cell.dataset.blocked == 'yes' || this.isTouch == false){
                if(this.validateHour(cell.dataset.init.substring(0, 5))){
                    this.typeReservation = 'add'

                    console.log(cell.dataset.init.substring(0, 5))
                    console.log( cell.dataset.end?.substring(0, 5))

                    let timeStart = cell.dataset.init.substring(0, 5)
                    let timeEnd = cell.dataset.end?.substring(0, 5)?? '22:00'
                    if(room.isReserveBlockTime) {
                        const date = new Date(Date.parse(this.dateId+"T00:00:00"))
                        const options = { weekday: 'long' }
                        const dayOfWeek = new Intl.DateTimeFormat('en-US', options).format(date).toLowerCase()
                        console.log(room.schedule[dayOfWeek])
                        let isOkTime = false
                        if(room.schedule[dayOfWeek].length != 0) {
                            room.schedule[dayOfWeek].forEach((day) => {
                                if(!isOkTime){
                                    let startTime = this.roundTimeToNearest30Minutes(day.startTime, false)
                                    let endTime = this.roundTimeToNearest30Minutes(day.endTime, false)

                                    let currentTime = this.convertHoursToMinuts(timeStart);
                                    let startTimeMinuts = this.convertHoursToMinuts(startTime);
                                    let EndTimeMinuts = this.convertHoursToMinuts(endTime);

                                    if (currentTime >= startTimeMinuts && currentTime <= EndTimeMinuts) {
                                        isOkTime = true
                                        timeStart = startTime
                                        timeEnd = endTime
                                        console.log("La hora actual está dentro del rango.");
                                    } else {
                                        console.log("La hora actual NO está dentro del rango.");
                                    }
                                }
                                
                            })
                        }
                        

                    }

                    this.data = this.getDataItem(room, timeStart, timeEnd)
                    this.addVisible=true
                } else {
                    this.selected = []
                }
            }
            else {
                this.typeReservation = this.selected.length > 1 ? 'add' : 'show'
                if(cell.dataset.reservationId != undefined){
                    request.ADFetch({
                        url: Urls.Api.Reservation.Detail,
                        data: { value: cell.dataset.reservationId },
                        recive: dat => {
                            const date = dat.dateTimeInit
                            dat.dateTimeInit = dat.dateTimeInit.split('T')[1].substring(0,5)
                            dat.dateTimeEnd = dat.dateTimeEnd.split('T')[1].substring(0,5)
                            this.data = { 
                                ...dat, 
                                date: Date.parse(date)
                            }
                            // console.log(this.data)

                            this.addVisible = true
                        }
                    })
                } else this.addVisible = true
            }
        },
        details(id){
            request.ADFetch({
                url: Urls.Api.Room.Detail,
                data: {
                    roomId: id
                },
                recive: data => { this.model = data},
                complete: () => { this.viewVisible = true }
            })
        },
        validateHour(timeInit, msg = true){
            if(!timeInit) return false
            if(this.date > new Date()) return true
            if(Tools.getDate(this.date) != Tools.getDate()) return false
            
            const timeNow = Tools.getDate(new Date(), 'HH:mm')
            const hourNow = parseInt(timeNow.split(':')[0])
            const minuteNow = parseInt(timeNow.split(':')[1])
            const hourInit = parseInt(timeInit?.split(':')[0])
            const minuteInit = parseInt(timeInit?.split(':')[1])
            
            if(hourInit >= hourNow){
                const minutesInit = (hourInit * 60) + minuteInit
                const minutesNow = (hourNow * 60) + minuteNow
                
                if(minutesInit < minutesNow && !msg){ // class blocked
                    return false
                }
                if(minutesInit < minutesNow && msg){
                    //if(msg)
                        //this.$message.error("La hora ya pasó")
                    return false
                }
            } else {
                //if(msg)
                    //this.$message.error("La hora ya pasó")
                return false
            }
            return true
        },
        listRooms(){
            const filter = this.$route.query.section
            console.log(filter)
            const date = Tools.getDate(this.date)
            this.ListCalendar = []
            request.ADFetch({
                url: Urls.Api.Room.ListCalendar,
                data: {filterStatus: 0,  date: date},
                recive: dat => {
                    console.log(dat)
                    if (filter != null) {
                        this.ListCalendar = dat.filter(r => r.section == filter || r.sectionRoomType.id == filter)
                    } else {
                        this.ListCalendar = dat
                    }
                    this.$nextTick(this.print)
                }
            })
        },
        roundTimeToNearest30Minutes(time, isSecond = true) {
            const parts = time.split(":");
            const currentHour = parseInt(parts[0]);
            const currentMinutes = parseInt(parts[1]);

            // Round the minutes to the nearest 30-minute interval (0 or 30)
            const roundedMinutes = Math.round(currentMinutes / 30) * 30;

            let second = ''

            if(isSecond){
                second = ':00'
            }

            if (roundedMinutes === 60) {
                const nextHour = currentHour + 1;
                return `${nextHour < 10 ? "0" : ""}${nextHour}:00${second}`;
            } else {
                return `${currentHour < 10 ? "0" : ""}${currentHour}:${roundedMinutes < 10 ? "0" : ""}${roundedMinutes}${second}`;
            }
        },
        generateHalfHourIntervals(startTime, endTime) {
            const intervals = [];
            const current = new Date(`2000-01-01T${startTime}`);

            while (current <= new Date(`2000-01-01T${endTime}`)) {
                const hours = current.getHours();
                const minutes = current.getMinutes();
                const formattedTime = `${hours.toString().padStart(2, '0')}:${minutes.toString().padStart(2, '0')}:00`;
                intervals.push(formattedTime);

                // Incrementar 30 minutos
                current.setMinutes(current.getMinutes() + 30);
            }

            return intervals
        },
        print(){
            this.isTouch = true
            window.getSelection().empty()
            this.clearTable()
            const dataFormated = new Intl.DateTimeFormat('es-MX', {dateStyle: 'medium', timeStyle: 'medium'})
            this.ListCalendar.forEach((item) => {
                item.reservations.forEach((i) => {
                    
                    for (let j = new Date(i.dateTimeInit); j < new Date(i.dateTimeEnd); j = new Date(j.getTime() + 60000 * 30)){
                        const date = i.dateTimeInit.split('T')[0]
                        const hour = dataFormated.format(j).split(' ')[3]
                        this.celda = document.getElementById(item.id + ',' + date + 'T'  + hour)
                        if(this.celda != null){
                            this.celda.dataset.blocked = 'occuped'
                            this.celda.style.background = "#538dc6"
                            
                            this.celda.dataset.reservationId = i.id
                            const period = i.dateTimeInit.split('T')[1].substring(0,5) + ' - '+i.dateTimeEnd.split('T')[1].substring(0,5)
                            console.log(period,'****')
                            this.celda.title = i.name + " (" + period + ")"

                            //if(i.isReserveBlockTime) {
                                this.celda.style.borderLeft = "1px solid #538dc6"
                                this.celda.style.borderRight = "1px solid #538dc6"
                           // }

                            if(i.isRepeat) {
                                this.celda.classList.add('blocked')
                                this.celda.dataset.blocked = 'yes'
                                this.celda.style.background = "#689C9C"
                                this.celda.style.borderLeft = "1px solid #689C9C"
                                this.celda.style.borderRight = "1px solid #689C9C"
                            }

                            
                        }
                    } 
                })
                const cells = document.querySelectorAll('.columnRoom[data-col="room_'+item.id+'"]')
                cells.forEach(cell => {
                    if(cell.style.background == ''){
                        if(!this.validateHour(cell.dataset.init?.substring(0,5), false)){
                            cell.classList.add('blocked')
                            cell.dataset.blocked = 'yes'
                        }
                    }
                })

                if(!item.isOpen24Hours){
                    const date = new Date(Date.parse(this.dateId+"T00:00:00"))
                    const options = { weekday: 'long' }
                    const dayOfWeek = new Intl.DateTimeFormat('en-US', options).format(date).toLowerCase()
                    let cells = document.querySelectorAll('.columnRoom[data-col="room_'+item.id+'"]')
                   
                    if(item.schedule[dayOfWeek].length !=0){
                        item.schedule[dayOfWeek].forEach((day) => {
                            let startTime = this.roundTimeToNearest30Minutes(day.startTime)
                            let endTime = this.roundTimeToNearest30Minutes(day.endTime)
                            
                            let arrPeriod = this.generateHalfHourIntervals(startTime, endTime)
                            arrPeriod = arrPeriod.slice(0, -1)
                            const cellPeriod = Array.from(cells).filter(cell => arrPeriod.includes(cell.dataset.init))

                            if(item.isReserveBlockTime) {
                                for (let i = 0; i < cellPeriod.length ; i++) {
                                    let cell = cellPeriod[i]

                                    if( i != 0 && cellPeriod.length>0){
                                        cell.style.borderLeft = "1px solid transparent"
                                    }

                                    if( i != cellPeriod.length -1){
                                        cell.style.borderRight = "1px solid transparent"
                                    }
                                }
                            }
                        


                            cells = Array.from(cells).filter(cell => !arrPeriod.includes(cell.dataset.init))
                            
                        })
                    }

                    cells.forEach(cell => {
                        if(cell.style.background == ''){
                            cell.classList.add('blocked')
                            cell.dataset.blocked = 'yes'
                        }

                    })
                }
                
            })
        },
        clearTable(){
            this.ListCalendar.forEach((item) => {
                this.halfHour.forEach((i) => {
                    this.celda = document.getElementById(item.id + ',' + this.dateId + 'T' + i.id)
                    if (this.celda != null){
                        if(this.celda.dataset.blocked != 'occuped')
                        {
                            this.celda.dataset.blocked = 'no'
                            this.celda.style.background = ''
                            this.celda.classList.remove("blocked")
                        }
                    }
                })
            })
        },
        save(model){
            const groupsId = model.groups.map(item => item.id)
            if(model.date == null || model.timeInit == '' || model.timeEnd == ''){
                this.$toast.open({
                    message: toolsLang('ScheduleRequired'),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false
            }
            
            if(model.internalPersonal.length == 0 && model.externalPersonal.length == 0 && model.groups.length == 0){
                this.$toast.open({
                    message: toolsLang.getKey('UsersRequired'),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false;
            }
            request.ADFetch({
                url: Urls.Api.Reservation.Add,
                data: {
                    roomId: model.roomId,
                    name: model.name,
                    description: model.description,
                    // justify: model.justify,
                    date: model.date,
                    startTime: model.timeInit,
                    endTime: model.timeEnd,
                    internalPersonal: model.internalPersonal,
                    externalPersonal: model.externalPersonal,
                    groups: groupsId
                },
                before: () => {this.isSending = true},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("ReservationAdded"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                    this.close()
                },
                complete: () => {this.isSending = false},
                failed: msg => {
                    this.$toast.open({
                        message: msg,
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                }
            })
        },
        close(list = true){
            this.selected = []
            this.addVisible =  false
            this.print() 
            if(list){
                this.listRooms()
            }
        },
        showCards(){
            const filter = this.$route.query.section
            if(filter != null){
                this.$router.push('/reservation/list?section=' + filter)
            }
            else{
                this.$router.push('/reservation/list')
            }
        },
        selectMouseMove(id, event){          
            if(this.date != ''){
                this.posX.push(event.clientX)
                if(this.posX[this.posX.length-1] > this.posX[this.posX.length-2]){
                    this.rigth = true
                    this.left = false
                }
                else{
                    this.left = true
                    this.rigth = false
                }
                if(this.selected.length > 0){
                    if(this.selected[0].split(',')[0] != id){
                        this.selected = []
                        this.print()
                    }
                }
                if(event.buttons == 1 && this.rigth && this.celda.dataset.blocked != 'occuped'){
                    this.typeReservation = "add"
                    this.celda.style.background = "#538dc6"
                    let unique = []
                    this.selected.push(this.selectedId)
                    this.selected.forEach((item) => {
                        if(!unique.includes(item)){
                            unique.push(item)
                        }
                    })
                    this.selected = unique
                }
                else if(event.buttons == 1 && this.left){
                    if(this.celda.dataset.blocked != 'occuped')
                    {
                        this.selected = this.selected.filter((item) => item !== this.selectedId)
                        this.celda.style.background = ''
                    }
                } 
            }
        },
        selectMouseUp(event){
            if(this.selected.length == 0) {
                this.selected = []
                this.print()
                return
            }
            if(this.selected.length == 1){
                this.selected.forEach(item => {
                    const cell = document.getElementById(item)
                    if(cell.dataset.blocked != 'occuped')
                    {
                        cell.style.background = ''
                    }
                })
                this.selected = []
                return
            }
            if(event.buttons == 0 && this.celda.style.background !=  '' && this.celda.dataset.blocked != 'occuped'){                            
                // console.log(this.selected)
                setTimeout(() => this.getTimes(), 100)
            }
            else{
                this.print()
            }
        },
        getTimes(){
            let timeInit = this.selected[0].split('T')[1].substring(0,5)
            let dateTimeEnd = this.selected[this.selected.length-1].split(',')[1]
            dateTimeEnd = new Date(dateTimeEnd)
            const dataFormated = new Intl.DateTimeFormat('es-MX', {dateStyle: 'medium', timeStyle: 'medium'})
            let timeEnd = new Date(dateTimeEnd.getTime() + (60000 * 30))
            timeEnd = dataFormated.format(timeEnd).split(' ')[3].substring(0, 5)

            const hourValValidateClick = (parseInt(timeInit.split(':')[0]) * 60) + parseInt(timeInit.split(':')[1])
            const hourNowValidateClick = (parseInt(timeEnd.split(':')[0]) * 60) + parseInt(timeEnd.split(':')[1])

            if(hourValValidateClick > hourNowValidateClick){
                this.$message.error(this.GetLang('EndTimeMessage'))
                this.close(false)
                return
            }

            if(this.validateHour(timeInit)){
                this.data= this.getDataItem(this.selectedRoom, timeInit, timeEnd)
                // console.log(this.data)

                this.addVisible=true
            } else {
                this.selected.forEach(item => {
                const cell = document.getElementById(item)
                if(cell.dataset.blocked != 'occuped')
                {
                    cell.style.background = ''
                }
                })
                this.selected = []
            }
        },
        selectHour(id, room, ){
            this.selectedId = id
            this.selectedRoom = room        
            // console.log(room)
            this.celda = document.getElementById(this.selectedId)
        },
        
        
    },
    watch:{
        date() { 
            this.dateId = Tools.getDate(this.date)
        }
    },
    beforeMount() { 
        toolsLang.initLanguage()
        this.selected = []
        this.listRooms()
        
        // console.log(this.model)
        
    },
   
}
</script>

<style lan="scss" scoped>
    .card .body {
        border: solid 1.5px #74b1f1;
        border-radius: 10px;
    }
    .btn-search{
        background: #00436a;
        color: white;
        padding: 5px 35px;
        border-radius: 10px;   
    }
    .btn{
        margin: 10px;
        display: block;
    }
    .input-group-text{
        color: #00436a;
    }
    table th,td{
        padding: 5px 12px;
    }
    thead{
        color: #00436a;
        background: #bbbbbb;
        background-color: #bbbbbb;
        text-align: center;
    }
   
    .table thead th, td{
        border: solid 1px #bbbbbb;
    }
    td{
        border: solid 1px #a9c2e2;
    }
    .table-striped tbody tr:nth-of-type(even) {
        background: #ffffff;
    }
    .table-striped tbody tr:nth-of-type(odd) {
        background: #ffffff;
    }
    table {
        border-collapse: separate; /* Don't collapse */
        border-spacing: 0px;
    }

    ::-webkit-scrollbar {
        width: 6px;
        height: 6px;
    }
    /* Track */
    ::-webkit-scrollbar-track {
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
        -webkit-border-radius: 6px;
        border-radius: 6px;
    }
    /* Handle */
    ::-webkit-scrollbar-thumb {
        -webkit-border-radius: 6px;
        border-radius: 6px;
        /*background: rgba(255,0,0,0.8); */
        -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
    }
    ::-webkit-scrollbar-thumb:window-inactive {
        /*background: rgba(255,0,0,0.4); */
    }
    .blocked{
        background:#f4f4f4;
        cursor: not-allowed !important
    }
    /* .table-scroll {
        position: relative;
        width:100%;
        z-index: 1;
        margin: auto;
        overflow: auto;
        height: 350px;
    }
    .table-scroll table {
        width: 100%;
        margin: auto;
    }
    .table-scroll th,
    .table-scroll td {
        background: #fff;
        vertical-align: top;
    }
    */
    .table-scroll thead th {
        color: #00436a;
        background: #d6e1ef;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
    }
    /*
    .table-scroll tfoot,
    .table-scroll tfoot th,
    .table-scroll tfoot td {
        position: -webkit-sticky;
        position: sticky;
        bottom: 0;
        background: #666;
        color: #fff; 
        z-index:4;   
    }*/
    th:first-child {
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 2;
        background: #d6e1ef;
        border: solid 1px #bbbbbb;
    }
    td:first-child{
        position: -webkit-sticky;
        position: sticky;
        left: 0;
        z-index: 2;
        background: #ffffff;
        border: solid 1px #a9c2e2;
    }
    th:nth-child(2) {
        position: -webkit-sticky;
        position: sticky;
        left: 230px;
        z-index: 2;
        background: #d6e1ef;
        border: solid 1px #bbbbbb;
    }
    td:nth-child(2){
        position: -webkit-sticky;
        position: sticky;
        left: 230px;
        z-index: 2;
        background: #ffffff;
        border: solid 1px #a9c2e2;
    }
</style>