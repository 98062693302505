<template>
    <div class="main-content">
        <div class="container-fluid" v-if="!visible">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>

            
            <div class="card">
                    <div class="header">
                        <div class="comment-form">
                            <form class="row">
                                <div class="col-sm-12">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-default">{{GetLang("Filter")}}</span>
                                        </div>
                                        <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" :placeholder="GetLang('TypeToSearch')" v-model="search" />
                                        <button v-if="isAREdit" type="button" class="btn btn-primary b-add" @click="add" style="margin-left: 15px">{{GetLang("AddRoom")}}</button>
                                    </div>
                                </div>                                        
                            </form>
                            <el-form  v-if="isARSort">
                                <el-row>
                                    <el-col :xs="24" :sm="8" :md="8">
                                        <el-form-item :label="GetLang('FilterRoomTypeSection')">
                                            <el-select @change="changeType" v-model="selectedType" style="width:100%">
                                                <el-option value="" :label="GetLang('All')"></el-option>
                                                <el-option v-for="item in arrSections" :key="item.id" :value="item.id" :label="TextLang(item.nameEn, item.nameEs)"></el-option>
                                            </el-select>
                                        </el-form-item>
                                    </el-col>
                                </el-row>
                            </el-form>
                        </div>
                    </div>                
                <div class="body">
                    <div>
                        <el-table v-if="!isARSort" :row-class-name="tableRowClassName" :header-cell-style="{ background: '#003B5C', color: '#fff'}" 
                                    :data="filtering" :empty-text="GetLang('NoData')">
                            <el-table-column
                                prop="name"
                                sortable
                                :label="GetLang('Name')"
                               
                                >
                                <template slot-scope="scope">
                                    {{TextLang(scope.row.nameEn, scope.row.nameEs)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="description"
                                :label="GetLang('Description')" width="300">
                                <template slot-scope="scope">
                                {{scope.row.description}}
                                </template>
                            </el-table-column>                            
                            <el-table-column
                                prop="numberPeople"
                                sortable
                                :label="GetLang('CapacityPeople')"
                                align="center"
                                >
                                <template slot-scope="scope">
                                {{scope.row.numberPeople}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="type"
                                :label="GetLang('Type')"
                                width="200"
                                align="center">
                                <template slot-scope="scope">
                                {{TextLang(scope.row.typeRoom.nameEn, scope.row.typeRoom.nameEs)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                width="80">
                                <template slot-scope="scope">
                                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" @click="showList(scope.row.id)" data-toggle="tooltip" data-original-title="Edit">
                                        <i class="fa fa-calendar"></i>
                                    </button>                                         
                                </template>                               
                            </el-table-column>
                            <el-table-column
                                align="center"
                                width="80"
                                v-if="isARDelete">
                                <template slot-scope="scope">
                                    <button class="btn btn-sm btn-pure btn-default on-default m-r-5" @click="deleteRoom(scope.row.id)" data-toggle="tooltip" data-original-title="Edit">
                                        <i class="fa fa-trash"></i>
                                    </button>                                         
                                </template>                               
                            </el-table-column>                                                        
                            <el-table-column
                                align="center"
                                width="80"
                                v-if="isAREdit">
                                <template slot-scope="scope">
                                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" @click="show(scope.row.id)" data-toggle="tooltip" data-original-title="Edit">
                                        <i class="icon-pencil"></i>
                                    </button>                                         
                                </template>                               
                            </el-table-column>
                        </el-table>

                        <div v-else class="draggable-container">
                            <draggable :list="draggableData" :element="'tbody'"  @end="onDragEnd">
                            <tr class="header-row">
                                <th class="data-cell" style="width: 80px !important;"></th>
                                <th class="data-cell" style="width: 250px !important;">{{ GetLang('Name')}}</th>
                                <th class="data-cell" style="width: 350px !important;">{{ GetLang('Description') }}</th>
                                <th class="data-cell">{{ GetLang('CapacityPeople') }}</th>
                                <th class="data-cell">{{ GetLang('Type') }}</th>
                                <th class="data-cell" style="width: 80px !important;"></th>
                                <th class="data-cell" style="width: 80px !important;" v-if="isARDelete"></th>
                                <th class="data-cell" style="width: 80px !important;" v-if="isAREdit"></th>
                            </tr>
                            <tr v-for="(item, index) in filteringByTypeRoom" :key="index" :class="{
                                'el-table__row': true, // Clase de fila de element-ui
                                'is-selected': index === selectedRowIndex, // Clase de fila seleccionada de element-ui
                                'white-row': index % 2 === 0, // Fila blanca para índices pares
                                'blue-row': index % 2 === 1, // Fila azul para índices impares
                                }">
        
                                <td class="drag-handle data-cell">
                                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" data-toggle="tooltip" >
                                        <i class="fa fa-bars handle-icon"></i>
                                    </button>
                                    
                                </td>
                                <td style="width: 250px !important;" class="data-cell"><div style="width: 250px; white-space: pre-wrap;">{{TextLang(item.nameEn, item.nameEs)}}</div></td>
                                <td style="width: 350px !important;" class="data-cell"> <div style="width: 350px; white-space: pre-wrap;">{{ item.description }}</div></td>
                                <td class="data-cell">{{ item.numberPeople }}</td>
                                <td class="data-cell">{{TextLang(item.typeRoom.nameEn, item.typeRoom.nameEs)}}</td>
                                <td style="width: 80px !important;">
                                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" @click="showList(item.id)" data-toggle="tooltip" data-original-title="Edit">
                                        <i class="fa fa-calendar"></i>
                                    </button>
                                </td>
                                <td style="width: 80px !important;" v-if="isARDelete">
                                    <button class="btn btn-sm btn-pure btn-default on-default m-r-5" @click="deleteRoom(item.id)" data-toggle="tooltip" data-original-title="Edit">
                                        <i class="fa fa-trash"></i>
                                    </button>
                                </td>
                                <td style="width: 80px !important;" v-if="isAREdit">
                                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" @click="show(item.id)" data-toggle="tooltip" data-original-title="Edit">
                                        <i class="icon-pencil"></i>
                                    </button> 
                                </td>
                            </tr>
                        </draggable>
                        </div>
                        
                        <el-divider></el-divider>
                        <div v-if="!isARSort" style="text-align: center">
                            <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="pag = $event"
                                :page-size="pageSize"
                                :total="total">
                            </el-pagination>
                        </div>
                    </div>                
                </div>                                   
            </div>
        </div>    
        <div class="card" v-if="visible">
            <EditRoom @Close="close" :model="data"></EditRoom>
        </div>
    </div>
</template>


<script>
import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import EditRoom from '../../views/adminRoom/editRoomPage.vue'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import constants from '../../scripts/constants'
import draggable from 'vuedraggable'
import 'element-ui/lib/theme-chalk/index.css'
import {ListSectionRoomCatalog} from '../../scripts/catalogs'

export default {
    name: 'AddRoom',
    components:{BreadCrumb, EditRoom, draggable},
    data: () => ({
        selectedRowIndex: null,
        visible: false,
        data: {
            id: '',
            name: '',
            description: '',
            personCharge: {},
            numberPeople: 0,
            typeId: '',
            location: {},
            flat: '',
            area: '',
            roomservices: [],
            routeImage: ''
        },       
        services: [],      
        search: '',
        selectedType: '',
        total: 0,  
        pag: 1,
        pageSize: 7,
        language: '',     
        tableData:[],
        draggableData:[],
        arrSections:[],
        isAREdit: false,
        isARDelete: false,
        isARSort: false,
    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        changeType(){
            console.log(this.selectedType)
        },
        add(){
            this.$router.push({path: '/admin/addroom'})
        },
        show(id){
            this.$router.push({ path: '/admin/editroom', query:{tok: id}})
        },
        showList(id){
            this.$router.push({path: '/reservation/reservations', query: {tok: id}})
        },
        calendar(){
            
        },
        close(){
            this.visible = false
            this.pag= 1
            this.list()
        }, 
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        totalPags(){
            const filtered = this.tableData.filter((r) =>
            (r.name + ' ' + r.type +' ' + r.numberPeople+ ' ').toLowerCase().includes(this.search.toLowerCase()));
            this.total = filtered.length
        },
        list(){
            request.ADFetch({
                url: Urls.Api.Room.List,
                data: {filterStatus: 0, status: ''},
                before:()=>{this.tableData = []; this.draggableData = []},
                recive: dat => {this.tableData = dat; this.draggableData =  dat}
            })
        },
        listSection(){
            ListSectionRoomCatalog().then(dat => {
                this.arrSections = []
                for (let i = 0; i < dat.length; i++) {
                    if(dat[i].active == 1) {
                        this.arrSections.push(dat[i])
                    }
                }
            })
        },
        getPermissions(){
            let arrPermission = this.$store.getters.permissions
            
            arrPermission.forEach(item =>{
                item.childs.forEach(c =>{
                    if(c.keyName == constants.Permissions.AR_EDIT){
                        this.isAREdit = true
                    }
                    if(c.keyName == constants.Permissions.AR_DELETE){
                        this.isARDelete = true
                    }

                    if(c.keyName == constants.Permissions.AR_SORT){
                        this.isARSort = true
                    }
                })
                
            })
        },
        deleteRoom(id){
            this.$confirm(toolsLang.getKey("AreYouSureToRemoveRoom"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
                .then(()=>{
                    request.ADFetch({
                        url: Urls.Api.Room.Delete,
                        data:{roomId: id},
                        success:() => {
                            this.$toast.open({
                                message: toolsLang.getKey("RoomRemoved"),
                                type: "success",
                                duration: 3000,
                                position: 'top'
                            }) 
                        },
                        failed: () => {
                            this.$toast.open({
                                message: toolsLang.getKey("NotRemovedRoom"),
                                type: "error",
                                duration: 3000,
                                position: 'top'
                            }) 
                        },
                        complete: () => {this.list()}
                    })
                }).catch(()=>{})
        },      
        onDragEnd(event) {
            const oldIndex = event.oldIndex -1;
            const newIndex = event.newIndex -1;
            const movedElement = this.filteringByTypeRoom.splice(oldIndex, 1)[0];
            const movedNewElement = this.filteringByTypeRoom.splice(newIndex, 1)[0];
            
            if(movedNewElement != null && movedElement != null){
                this.tableData.splice(movedNewElement.row, 0, movedElement);
                let temp = [...this.tableData]
                this.tableData = []
                this.tableData = temp
                let roomId = movedElement.id

                request.ADFetch({
                url: Urls.Api.Room.UpdatePosition,
                data: {roomId: roomId, row: movedNewElement.row},
                before:()=>{},
                recive: dat => {console.log(dat)},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("RoomSortOk"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                    this.close()
                },
                failed: (dat) => {
                    this.$toast.open({
                        message: dat,
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                }
            })
            }
            /* this.tableData.splice(newIndex, 0, movedElement);
            let temp = [...this.tableData]
            this.tableData = []
            this.tableData = temp
            let roomId = this.tableData[newIndex].id

            request.ADFetch({
                url: Urls.Api.Room.UpdatePosition,
                data: {roomId: roomId, row: newIndex},
                before:()=>{},
                recive: dat => {console.log(dat)},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("RoomSortOk"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                    this.close()
                },
                failed: (dat) => {
                    this.$toast.open({
                        message: dat,
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                }
            }) */
        },  
    },
    beforeMount() { // Antes de que se monte
        this.getPermissions()
        toolsLang.initLanguage()
        this.list()
        this.totalPags()
        this.listSection()
    },
    beforeUpdate(){
        this.totalPags()
    },
    computed: { // actualiza cuando cambia algo en el dom
        filtering() {
            if (this.search === null) return this.tableData;
            const filtered = this.tableData.filter((r) =>
                (r.name + ' ' + r.type +' ' + r.numberPeople).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.slice(this.pageSize * this.pag - this.pageSize,this.pageSize * this.pag)
        },
        filteringByTypeRoom() {
            
            if (this.selectedType === null || this.selectedType == '') return this.tableData;
            const filtered = this.tableData.filter((r) =>
                (r.sectionRoomType.id).toLowerCase().includes(this.selectedType.toLowerCase()));
            return filtered
        },
        dragOptions() {
            return {
                animation: 200,
            };
        },
    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>
.handle-icon {
  cursor: grab;
}

.header-row {
    background: rgb(0, 59, 92);
    color: rgb(255, 255, 255);
    min-height: 100px;
    height: auto;
}

.data-cell{
    text-align: left; /* Centra el texto en las celdas de datos */
  padding: 5px;
  min-width: 50px;
}
.white-row {
  background-color: white; /* Fila blanca */
}

.blue-row {
  background-color: #f2f2f2; /* Fila azul */
}
.draggable-container {
    width: 100%; /* Ancho del contenedor del 100% del ancho de la pantalla */
  overflow-x: auto; /* Habilita el scroll horizontal si la tabla es más ancha que el contenedor */
  white-space: nowrap; 
}

</style>