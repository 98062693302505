import toolsLang from '../common/dictionary'
import store from '../store'
import Vue from 'vue'


export default {
    GetLang: k => toolsLang.getKey(k),
    extend(a, b) {
        for (var key in b)
            if (Object.prototype.hasOwnProperty.call(b, key))
                a[key] = b[key]
        return a
    },
    ADFetch(opts) {
        const defs = {
            url: "",
            method: "POST",
            data: {},
            formData: false,
            isPlain: false,
            success: () => { },
            fields: fil => {
                let err = fil[0]
                Vue.$toast.open({
                    message: err.message,
                    type: "error",
                    duration: 3000,
                    position: 'top',
                    dismissible: true
                })
            },
            error: () => { },
            complete: () => { },
            recive: () => { },
            before: () => { },
            plain: () => { },
            failed: (msg) => { if(msg && msg?.trim() != '') {
                Vue.$toast.open({
                    message: msg,
                    type: "error",
                    duration: 3000,
                    position: 'top',
                    dismissible: true
                })
            } },
            headers: { "Content-type": "application/json; charset=UTF-8" },
            token: ''
        }
        const conf = this.extend(defs, opts)
        //conf.url = Tools.API + conf.url
        const headConfig = {
            'Authorization': '',
            'LANG': store.state.lang,
            ...(conf.headers)
        };

        if (store.getters.isLogged) {
            headConfig.Authorization = 'Bearer ' + store.getters.token
        }

        if (conf.token != '') {
            headConfig.Authorization = 'Bearer ' + conf.token
        }

        let body;
        if (!conf.formData) {
            body = JSON.stringify(conf.data)
        } else {
            body = conf.data
        }

        if (conf.url == '') {
            return false
        }

        let options = {
            method: conf.method,
            body: body,
            headers: headConfig,
            credentials: 'include'
        }

        if (conf.method == "GET") {
            delete options['body'];
        }

        conf.before();

        fetch(conf.url, options)
            .then(async response => {
                conf.complete()
                if (conf.isPlain) {
                    response.text().then(res => { conf.plain(res) })
                }
                else {
                    if (response.status == 401) {
                        const bod = await response.json()
                        bod.aux = 401
                        return bod;
                    }
                    else if (response.status == 404 || response.status == 204) {
                        return { isOk: false, message: "No existe la ruta" }
                    }
                    else {
                        try {
                            return await response.json()
                        } catch {
                            return { isOk: false, message: "" }
                        }
                    }
                }
            })  // Convert to json
            .then(json => {
                if (!conf.isPlain) {
                    if (json.aux == 401) {
                        Vue.$toast.open({
                            message: json.message,
                            type: "error",
                            duration: 3000,
                            position: 'top',
                            dismissible: true
                        })
                        if (!window.location.href.includes('login')) {
                            window.location.href = '/login'
                        }
                    } else {
                        if (!json?.isOk) {
                            if (json.message == 'Validation Failed') {
                                conf.fields(json.data)
                            }
                            else {
                                conf.failed(json?.message)
                            }
                        } else {
                            conf.success(json)
                            if (json?.data != null) {
                                conf.recive(json?.data)
                            }
                        }
                    }
                }
            })   // Print data to console
            .catch(err => {
                conf.complete()
                conf.error(err)
                // console.log("error")
                Vue.$toast.open({
                    message: err.message == 'Failed to fetch' ? toolsLang.getKey('NoConnection') : err.message,
                    type: "error",
                    duration: 3000,
                    position: 'top',
                    dismissible: true
                })
            })      // Catch errors
    },
    Catalog(url, data = null){
        const self = this
        return new Promise((success, error) => {
            self.ADFetch({
                url: url,
                data: data,
                recive: success,
                error: error
            })
        })
    }
}