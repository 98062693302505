<template>
    <div>
        <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#003B5C', color: '#fff'}" :data="filtering" style="width: 100%" :empty-text="GetLang('NoData')">
            <el-table-column
                prop="id"
                sortable
                label="Id">
                <template slot-scope="scope">
                    {{scope.row.id}}
                </template>
            </el-table-column>
            <el-table-column
                prop="name"
                sortable
                :label="GetLang('Name')">
                <template slot-scope="scope">
                {{scope.row.name}}
                </template>
            </el-table-column>
            <el-table-column
                v-if="false"
                prop="emails">
                <template slot-scope="scope">
                {{scope.row.emails}}
                </template>
            </el-table-column>            
            <el-table-column
                align="center"
                width="100">
                <template slot-scope="scope">
                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" @click="show(scope.row)" data-toggle="tooltip" data-original-title="View">
                        <i class="icon-eye"></i>
                    </button>                                         
                </template>                               
            </el-table-column>            
            <el-table-column
                prop="tag"
                label=""
                align="center"
                width="150">
                <template slot-scope="scope">
                    <el-dropdown v-if="scope.row.nameEs != 'Personal' && scope.row.nameEs != 'Super'" @command="actions($event, JSON.stringify(scope.row))">
                        <el-button plain type="primary" size="mini">
                            {{GetLang("Actions")}}<i class="el-icon-arrow-down el-icon--right"></i>
                        </el-button>
                        <el-dropdown-menu slot="dropdown">
                            <el-dropdown-item command="Edit">{{GetLang("Edit")}}</el-dropdown-item>
                             <el-dropdown-item command="Delet">{{GetLang("Delete")}}</el-dropdown-item>
                        </el-dropdown-menu>
                    </el-dropdown>
                    <label v-else>{{GetLang("Protected")}}</label>
                </template>
            </el-table-column>
        </el-table>
        <el-divider></el-divider>

        <div style="text-align: center">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="pag = $event"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div>
    </div>
</template>

<script>
import toolsLang from '../../../common/dictionary'
export default {
    name: 'TableCatalog',
    props: {
        tableData: {
            require: true,
            type: Array,
            default(){return []}
        },
        search: {
            require: true,
            type: String,
            default: ''
        },
    },
    watch:{
        totalPags(){
            this.total = this.totalPags
        }
    },
    data: ()=>({
        total: 0,
        pag: 1,
        pageSize: 7
    }),
    methods: {
        tableRowClassName({rowIndex}){
            if ((rowIndex%2)!= 0) {
                return 'color-row';
            }
            return '';
        },
        GetLang: key => toolsLang.getKey(key),
        actions(type, sData){
            const data = JSON.parse(sData)
            switch(type){
                case "Edit": this.$emit("editRow", data); break
                case "Delet":
                    this.$confirm(toolsLang.getKey("AreYouSureToRemoveIt"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
                    .then(()=>{
                        this.$emit("deleteRow", data)
                    }).catch(()=>{})    
                break
            }
        },
        show(data){
            this.$emit("view", data)
        }
    },
    beforeUpdate(){
        
    },  
    computed:{
        filtering(){
            if(this.search == null) return this.tableData;
            const filtered = this.tableData.filter(r => (r.name).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.slice(this.pageSize * this.pag - this.pageSize, this.pageSize * this.pag);
        },
        totalPags(){
            const filtered = this.tableData.filter(r => (r.name).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.length;            
        }
    },
}
</script>

<style lang="scss">
    .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #0e1d37;
    }
    .el-table .descending .sort-caret.descending{
        border-top-color: #0e1d37;
    }
    .el-table .sort-caret.ascending {
        border-bottom-color: #7e8daa;
    }
    .el-table .sort-caret.descending {
        border-top-color: #7e8daa;
    }
	.el-tag{	
        border: solid 1.8px !important;
	}    
</style>