import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
import Tools from '../scripts/tools'

Vue.use(Vuex)

export const str_TOKEN = "31E8765C877B4D16B46A2503CFE7A869"
export const str_ROLE = "A4F792E4855347C8B60AF21F45C244FB"
export const str_NAME = "1B3C92555A25467D992E4A26B819C4EA"
export const str_IMAGE = "0F80BDB650FC49C78A32A82BC32AF666"
export const str_MENU = "C9901C3F58014DC59F8964F594AFBC76"
export const str_PERMISSION = "C9901C3F58014DC74LUHLJKNRKLH76Q7"

export default new Vuex.Store({
	state: {
		logged: localStorage.getItem(str_TOKEN) != null,
		role: Tools.DecryptPass(localStorage.getItem(str_ROLE)),
		token: Tools.DecryptPass(localStorage.getItem(str_TOKEN)),
		name: Tools.DecryptPass(localStorage.getItem(str_NAME)),
		lang: Tools.getCookie("LANG"),
		avatar: Tools.DecryptPass(localStorage.getItem(str_IMAGE)),
		permissions: Tools.DecryptPass(localStorage.getItem(str_PERMISSION))
	},
	mutations: {
		setLogout(state) {
			state.layout.navPos = null
			state.layout.toolbar = null
			state.permissions = ''
			localStorage.clear()
			state.role = ''
		},
		setLogin(state){
			state.role = localStorage.getItem(str_ROLE)
			state.token = localStorage.getItem(str_TOKEN)
		},
		setLang(state, lang) {
			state.lang = lang
			Tools.setCookie("LANG", lang, 30)
		},
		setPermission(state) {
			state.permissions = Tools.DecryptPass(localStorage.getItem(str_PERMISSION))
		}
	},
	actions: {
		setSession(state, dat){
			localStorage.setItem(str_TOKEN, Tools.EncryptPass(dat.token,1))
			localStorage.setItem(str_ROLE, Tools.EncryptPass(dat.typeIdentity,2))
			localStorage.setItem(str_NAME, Tools.EncryptPass(dat.name,3))
			localStorage.setItem(str_MENU, Tools.EncryptPass(JSON.stringify(dat.menus),1))
			localStorage.setItem(str_IMAGE, Tools.EncryptPass(dat.image,2))
		},
		setPermissions(state, dat){
			var newdat =  JSON.stringify(dat)
			localStorage.setItem(str_PERMISSION, Tools.EncryptPass(newdat,1))
		}
	},
	getters: {
		isLogged(state) {
			return state.logged
		},
		token(state) {
			return state.token
		},
		name(state){
			return state.name
		},
		lang(state) {
			return state.lang
		},
		avatar(state){
			return state.avatar
		},
		role(state){
			return state.role
		},
		userId(state){
			try	{
				const dat = state.token.split('.')[1]
				const obj = JSON.parse(atob(dat))
				return obj.unique_name
			} catch {
                return ''
            }
		},
		permissions(state){
			try	{
				const dat = state.permissions
				const obj = JSON.parse(dat)
				return obj
			} catch {
                return ''
            }
		},
	},
	plugins: [createPersistedState({paths: ['layout']})]
})