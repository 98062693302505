export default {
    KeyCatalog: {
        Gender: "Gender",
        Skill: "Skill",
        Award: "Award",
        Idiom: "Idiom",
        Role: "Role",
        Location: "Location",
        Group: "Group",
        Service: "Service",
        TypesRoom: "TypesRoom",

        //role
        RPersonal: "Personal"
    },
    Permissions: {
        GENERIC: "GENERIC",
        REPORTS: "REPORTS",
        //Remotes
        ADMIN_ROOM: "ADMIN_ROOM",
        AR_SHOW: "AR_SHOW",
        AR_EDIT: "AR_EDIT",
        AR_DELETE: "AR_DELETE",
        AR_SORT: "AR_SORT",

        ADMIN_CATALOGS: "ADMIN_CATALOGS",
        C_SECTION_ROOM_TYPES: "C_SECTION_ROOM_TYPES",
        C_ROOM_TYPES: "C_ROOM_TYPES",
        C_SERVICES: "C_SERVICES",
        C_GROUPS: "C_GROUPS",
        C_LOCATIONS: "C_LOCATIONS",
        C_ROLES_PERMISSION: "C_ROLES_PERMISSION",
        
        ADMIN_EMPLOYEES: "ADMIN_EMPLOYEES",
        E_SHOW: "E_SHOW",
        E_PERMISSIONS: "E_PERMISSIONS",

        ADMIN_RESERVATIONS: "ADMIN_RESERVATIONS",
        R_SHOW_PERSONAL: "R_SHOW_PERSONAL",
        R_SHOW_ALL: "R_SHOW_ALL",
        ADMIN_REPORTS: "ADMIN_REPORTS",
        AR_FREQUENCY_REPORT: "AR_FREQUENCY_REPORT",
        //Local
        R_LIST_ROOM: "R_LIST_ROOM",
        R_ADD: "R_ADD",
        

    },
    SectionCatalog: [
        {
            "name": "ConferenceRoom",
            "code": "Conference",
        },
        {
            "name": "VideoconferenceRoom",
            "code": "Videoconference",
        }
    ]
}