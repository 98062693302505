<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <!-- <div class="col-sm-12" align="right">
                    <button type="button" class="btn btn-block btn-primary b-add" @click="clear();addVisible = true;" align="right" style="margin-bottom: 20px;">{{GetLang("AddLocation")}}</button>
                </div> -->
                <div class="card">
                    <div class="header">
                        <div class="comment-form">
                            <form class="row">
                                <div class="col-sm-12">
                                    <div class="input-group mb-3" >
                                        <div class="input-group-prepend" >
                                            <span class="input-group-text" id="inputGroup-sizing-default">{{GetLang("Filter")}}</span>
                                        </div>
                                        <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" :placeholder="GetLang('TypeToSearch')" v-model="search" />
                                        <button type="button" class="btn btn-primary b-add" @click="clear();addVisible = true;" style="margin-left: 15px">{{GetLang("AddLocation")}}</button>
                                    </div>
                                </div>
                                     
                            </form>
                        </div>
                    </div>
                    <div class="body">
                        <div style="width: 100%"> 
                            <table-catalog 
                                :tableData="tableData"
                                :states="states"
                                :search="search"
                                @editRow="edit"
                                @deleteRow="deleteRow"
                            ></table-catalog>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <dialog-component
             v-if="addVisible"
             :title="title"
             :data="model"              
             @Close="close"
             @Correct="save($event)"
             ></dialog-component>         
        </div>
        <!-- Edit -->
        <div>
            <dialog-component
             v-if="editVisible"
             :title="title" 
             :data="model"
             type='edit'
             @Close="close"
             @Correct="save($event, 'edit')"
             ></dialog-component>         
        </div>                
    </div>
</template>

<script>

import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import tableCatalog from '../../components/admin/table/TableLocationComponent.vue'
import dialogComponent from '../../components/admin/dialog/DialogLocationComponent.vue'
import Urls from '../../scripts/urls'
import request from '@/scripts/request'

export default {
    name: 'CLocationsComponent',
    components: {
        BreadCrumb,
        tableCatalog,
        dialogComponent
    },
    data: () => ({
        tableData: [],
        states:[],
        municipalities: [],
        addVisible: false,
        model: {
            id: '',
            building: '',
            address: '',
            stateId: 1,
            municipalityId: 1
        },
        sending: false,
        editVisible: false,
        title: toolsLang.getKey('NewLocation'),
        search: '',
        total: 0    
    }),
    methods: {
        GetLang: key => toolsLang.getKey(key),
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        edit(data){
            this.editVisible = true
            this.title = toolsLang.getKey('EditLocation')
            this.model = data
        },
        close(){
            this.addVisible = false
            this.editVisible = false
        },
        deleteRow(data){
            request.ADFetch({
                url: Urls.Api.Catalog.Location.Delete,
                data: {value: data.id},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey('Deleted'),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                },
                complete: () => {this.list()}
            })        
        },
        save(data, type= ''){
            var msg = toolsLang.getKey("AddingLocation")
            var auxUrl = Urls.Api.Catalog.Location.Add
            var id = ''
            if(type == 'edit' ){
                id = data.id
                msg = toolsLang.getKey("EditedLocation")
                auxUrl = Urls.Api.Catalog.Location.Update
            }
            request.ADFetch({
                url: auxUrl,
                data:{
                    id: id,
                    address: data.address,
                    building: data.building,
                    municipalityId: data.municipalityId,
                    stateId: data.stateId
                },
                success: () => {
                    this.$toast.open({
                        message: msg,
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    }),
                    this.addVisible = this.editVisible = false                
                },
                complete: () => {this.list()}
            })
        },
        clear(){
            this.model = {
                id: '',
                building: '',
                address: '',
                stateId: 0,
                municipalityId: 0
            }
        }, 
        list(){
            this.tableData = []
            request.ADFetch({
                url: Urls.Api.Catalog.Location.List,
                before: () => {this.tableData = []},
                recive: dat => {this.tableData = dat}
            })
        },       
    },
    beforeMount() { // Antes de que se monte
        this.list()
        request.Catalog(Urls.Api.Catalog.State).then(dat => {
            this.states = dat
            if(this.tableData.stateId == '') {
                this.tableData.stateId = dat[0].id
            }
        })
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    }
}
</script>

<style lang="scss" scoped>

</style>