import store from '../store/index'

import es from '../assets/language/ES'
import en from '../assets/language/EN'
import tools from '../scripts/tools'


export default {
    getTranslate(en, es){
        switch (store.state.lang) {
            case "ES": return es;
            case "EN": return en;
        }
        return ""
    },
    getKey(key) {
        switch (store.state.lang) {
            case "ES": return es[key];
            case "EN": return en[key];
        }
        return key
    },
    initLanguage(){
        if(tools.getCookie("LANG") == "") {
            const lang = (navigator.language || navigator.userLanguage).toUpperCase().split("-")[0]
            tools.setCookie("LANG", lang, 30)
            store.commit("setLang", lang)
        }

        var language = "mx"
        if(tools.getCookie("LANG") == "EN") {
            language = "us"
        }
        return language
    },
    changeLanguage(lang){
        const lg = lang == "us" ? "EN" : "ES";
        store.commit("setLang", lg)
        return lang
    }
}