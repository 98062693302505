<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="card">
                <div class="card-header">
                    {{GetLang('Filter')}}
                    <label class="fancy-checkbox" style="float:right">
                    </label>
                    <span style="float: right; cursor: pointer;" @click="download"><i class="el-icon-download" style="font-size: x-large;"></i></span>
                </div>
                <div class="body">
                    <el-form>
                        <el-row :gutter="15">
                            <el-col :xs="24" :md="16">
                                <el-form-item>
                                    <label for="">{{GetLang('GeneralFilter')}}</label>
                                    <el-input 
                                        type="text" :placeholder="GetLang('TypeToSearch')" v-model="search" @change="filtered">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :md="4">
                                <el-form-item>
                                    <label for="">{{GetLang('InitDate')}}</label>
                                    <el-date-picker @change="init" :placeholder="GetLang('InitDate')" v-model="intDate" style="width:100%"></el-date-picker>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :md="4">
                                <el-form-item>
                                    <label for="">{{GetLang('EndDate')}}</label>
                                    <el-date-picker @change="init" :placeholder="GetLang('EndDate')" v-model="endDate" style="width:100%"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                        <el-row :gutter="15" v-if="filterVisible">
                            <el-col :xs="24" :md="6">
                                <el-form-item>
                                    <label for="">{{GetLang('RoomFilter')}}</label>
                                    <el-input             
                                        type="text" :placeholder="GetLang('TypeToSearch')" v-model="searchRoom" @change="filtered">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :md="6">
                                <el-form-item>
                                    <label for="">{{GetLang('StatusFilter')}}</label>
                                    <el-input 
                                        type="text" :placeholder="GetLang('TypeToSearch')" v-model="searchStatus" @change="filtered">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :md="6">
                                <el-form-item>
                                    <label for="">{{GetLang('HostFilter')}}</label>
                                    <el-input 
                                        type="text" :placeholder="GetLang('TypeToSearch')" v-model="searchHost" @change="filtered">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :md="6">
                                <el-form-item>
                                    <label for="">{{GetLang('PersonalChargeFilter')}}</label>
                                    <el-input 
                                        type="text" :placeholder="GetLang('TypeToSearch')" v-model="searchPersonal" @change="filtered">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form> 
                    <span style="cursor: pointer" @click="filterVisible = !filterVisible"><i v-if="!filterVisible" class="el-icon-arrow-down"></i><i v-if="filterVisible" class="el-icon-arrow-up"></i>{{!filterVisible ? GetLang('MoreFilters') : GetLang('LessFilters')}}</span>                                      
                    <span v-if="filterVisible" style="float: right; cursor: pointer;" @click="clear"><i class="el-icon-delete"></i> {{GetLang('CleanFilters')}}</span>
                </div>
            </div>
            <div class="card">              
                <div class="body">
                    <div>
                        <el-table style="width: 100%" :header-cell-style="{ background: '#003B5C', color: '#fff'}" :data="filtering"  :empty-text="GetLang('NoData')">
                            <el-table-column
                                label="#"
                                width="50"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.index}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="roomName"                            
                                :label="GetLang('RoomName')"
                                width="250"
                                >
                                <template slot-scope="scope">
                                    {{TextLang(scope.row.roomEn, scope.row.roomEs)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="status"                            
                                :label="GetLang('Status')"
                                width="120"
                                align="center"
                                >
                                <template slot-scope="scope">
                                    <span v-if="scope.row.statusReservation=='finished'"  class="badge badge-success">{{GetLang('CarriedOut')}}</span>
                                    <span v-if="scope.row.statusReservation=='process'"  class="badge badge-warning">{{GetLang('InProgress')}}</span>
                                    <span v-if="scope.row.statusReservation=='cancelled'"  class="badge badge-danger">{{GetLang('Cancelled')}}</span>
                                    <span v-if="scope.row.statusReservation=='pendient'"  class="badge badge-default">{{GetLang('Scheduled')}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="hostUser"                            
                                :label="GetLang('hostUser')"
                                width="230"
                                >
                                <template slot-scope="scope">
                                    <span class="tag badge badge-user">{{scope.row.host}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="intDate"                            
                                :label="GetLang('InitDateMeeting')"
                                width="230"
                                align="center"
                                >
                                <template slot-scope="scope">
                                    {{formatDate(scope.row.dateTimeInit)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="endDate"                            
                                :label="GetLang('EndDateMeeting')"
                                width="230"
                                align="center"
                                >
                                <template slot-scope="scope">
                                    {{formatDate(scope.row.dateTimeEnd)}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="name"
                                :label="GetLang('NameMeeting')"
                                width="200"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.name}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="description"
                                :label="GetLang('Description')"
                                width="200"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.description}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="internalUsers"                            
                                :label="GetLang('GuestInternal')"
                                width="230"
                                >
                                <template slot-scope="scope">
                                    <span class="tag badge badge-user" v-for="user in scope.row.internalUsers" :key="user.email">{{user.email}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="externalUsers"                            
                                :label="GetLang('GuestExternal')"
                                width="230"
                                >
                                <template slot-scope="scope">
                                    <span class="tag badge badge-user" v-for="user in scope.row.externalUsers" :key="user.email">{{user.email}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="groups"                            
                                :label="GetLang('Groups')"
                                width="230"
                                >
                                <template slot-scope="scope">
                                    <span class="tag badge badge-user" v-for="group in scope.row.groups" :key="group.name">{{group.name}}</span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="personalCharge"                            
                                :label="GetLang('PersonalInChargeRoom')"
                                width="200"
                                >
                                <template slot-scope="scope">
                                    {{scope.row.personalCharge}}
                                </template>
                            </el-table-column>
                        </el-table>
                        <el-divider></el-divider>
                        <el-pagination
                            align="center"
                            background
                            layout="prev, pager, next"
                            @current-change="pag = $event"
                            :page-size="pageSize"
                            :total="filtered.length">
                        </el-pagination>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import Tools from '../../scripts/tools'
 
export default {
    name: 'ReportPage',
    components:{BreadCrumb},
    data: () => ({
        data: {
            name: '',
            roomEn: '',
            roomEs: '',
            host: '',
            status: '',
            dateTimeInit: '',
            dateTimeEnd: '',
            description: '',
            internalUsers: '',
            externalUsers: '',
            groups: '',
            personalCharge: ''

        },    
        intDate: null,
        endDate: null,
        search: '',
        searchRoom: '',
        searchHost: '',
        searchStatus: '',
        searchPersonal: '',
        filterVisible: false,
        total: 0,  
        pag: 1,
        pageSize: 7,
        tableData:[],
        filter: [],
    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        init(){
            const intDate = this.intDate == null ? '' :  Tools.getDate(this.intDate)
            const endDate = this.endDate == null ? '' :  Tools.getDate(this.endDate)
            this.data = []
            request.ADFetch({
                url: Urls.Api.Reports.List,
                data: { 
                    startDate: intDate,
                    finishDate: endDate
                },
                recive: dat => { 
                    this.data = dat
                }
            })
        },
        formatDate(date){
            var newDate =  date.split('T')[0]
            newDate = newDate.split('-')[2] + '-' + newDate.split('-')[1] + '-' + newDate.split('-')[0]
            return newDate
        },
        getString(array){
            let newArr = ''
            array?.map(item => {
                newArr = newArr + item.email + ' '
            });
            return newArr
        },
        download(){
            let csv = []
            csv= this.filtered.map(item => ({                
                'Indice': item.index,
                'Nombre de la sala': this.TextLang(item.roomEn, item.roomEs),
                'Estatus': item.status,
                'Usuario anfitrión': item.host,
                'Fecha de inicio de la reunión': this.formatDate(item.dateTimeInit),
                'Fecha de termino de la reunión': this.formatDate(item.dateTimeEnd),
                'Descripción': item.description.replace(/,/g, ''),
                'Invitados internos': this.getString(item.internalUsers),
                'Invitados externos': this.getString(item.externalUsers),
                'Grupos': this.getString(item.groups),
                'Personal a cargo de la sala': item.personalCharge,
            }))

            let csvContent = ""
            csvContent += [
                Object.keys(csv[0]),
                ...csv.map(item => Object.values(item))
            ]
                .join("\n")
                .replace(/(^\[)|(\]$)/gm, "")
            const link = document.createElement("a")
            link.setAttribute("href", "data:text/csv;charset=utf-8,%EF%BB%BF" + encodeURI(csvContent))
            link.setAttribute("download", 'Report_Mensajes_Estatus.csv')
            link.click()
        },
        removeAccents(string){
            const accents = {'á':'a','é':'e','í':'i','ó':'o','ú':'u','Á':'A','É':'E','Í':'I','Ó':'O','Ú':'U'};
            return string.split('').map( vowel => accents[vowel] || vowel).join('').toString();	
        },
        clear(){
            this.searchRoom = '',
            this.searchHost = '',
            this.searchStatus = '',
            this.searchPersonal = ''
        }
    },
    beforeMount() { // Antes de que se monte
        this.init()
        console.log(this.data)

    },
    computed: { // actualiza cuando cambia algo en el dom
        filtered(){
            let filtered = []
            let i = 1
            this.data.map(item => {
                const time = new Date().getTime()
                const init = new Date(item.dateTimeInit).getTime()
                const end = new Date(item.dateTimeEnd).getTime()
                var statusReservation = time < init? 'pendient'
                                : time >= init && time <= end? 'process'
                                : time > end || item.status == "Finished"? 'finished' : 'actived'
                if(item.status == "Cancelled"){
                    statusReservation = 'cancelled'
                }
                let status = statusReservation == 'pendient' ? toolsLang.getKey('Scheduled') : statusReservation == 'process' ? toolsLang.getKey('InProgress') : statusReservation == 'finished' ? toolsLang.getKey('CarriedOut') : toolsLang.getKey('InProgress')
                if(statusReservation == 'Cancelled'){
                    status = toolsLang.getKey('Cancelled')
                }
                item = {...item, statusReservation: statusReservation, statusLan: status, index: i++}
                filtered.push(item)
            })
            if (this.search != ''){
                filtered = filtered.filter(r => (r.roomEn + ' ' + this.removeAccents(r.roomEs) + ' ' + r.host +' ' + r.statusLan + ' ' + this.removeAccents(r.personalCharge)).toLowerCase().includes(this.search.toLowerCase()))
            }
            if (this.searchRoom != ''){
                filtered = filtered.filter(r => (this.removeAccents(r.roomEs).toLowerCase()).includes(this.searchRoom.toLowerCase()) || (r.roomEn.toLowerCase()).includes(this.searchRoom.toLowerCase()))
            }
            if (this.searchHost != ''){
                filtered = filtered.filter(r => (r.host).toLowerCase().includes(this.searchHost.toLowerCase()))
            }
            if (this.searchStatus != ''){
                filtered = filtered.filter(r => (r.statusLan).toLowerCase().includes(this.searchStatus.toLowerCase()))
            }
            if (this.searchPersonal != ''){
                filtered = filtered.filter(r => (this.removeAccents(r.personalCharge)).toLowerCase().includes(this.searchPersonal.toLowerCase()))
            }
            return filtered
        },
        filtering(){
            let filtered = []
            let i = 1
            this.data.map(item => {
                const time = new Date().getTime()
                const init = new Date(item.dateTimeInit).getTime()
                const end = new Date(item.dateTimeEnd).getTime()
                var statusReservation = time < init? 'pendient'
                                : time >= init && time <= end? 'process'
                                : time > end || item.status == "Finished"? 'finished' : 'actived'
                if(item.status == "Cancelled"){
                    statusReservation = 'cancelled'
                }
                let status = statusReservation == 'pendient' ? toolsLang.getKey('Scheduled') : statusReservation == 'process' ? toolsLang.getKey('InProgress') : statusReservation == 'finished' ? toolsLang.getKey('CarriedOut') : statusReservation == 'cancelled' ? toolsLang.getKey('Cancelled') : ''
                item = {...item, statusReservation: statusReservation, statusLan: status, index: i++}
                filtered.push(item)
            })
            if (this.search != ''){
                filtered = filtered.filter(r => (r.roomEn + ' ' + this.removeAccents(r.roomEs) + ' ' + r.host +' ' + r.statusLan + ' ' + this.removeAccents(r.personalCharge)).toLowerCase().includes(this.search.toLowerCase()))
            }
            if (this.searchRoom != ''){
                filtered = filtered.filter(r => (this.removeAccents(r.roomEs).toLowerCase()).includes(this.searchRoom.toLowerCase()) || (r.roomEn.toLowerCase()).includes(this.searchRoom.toLowerCase()))

            }
            if (this.searchHost != ''){
                filtered = filtered.filter(r => (r.host).toLowerCase().includes(this.searchHost.toLowerCase()))
            }
            if (this.searchStatus != ''){
                filtered = filtered.filter(r => (r.statusLan).toLowerCase().includes(this.searchStatus.toLowerCase()))
            }
            if (this.searchPersonal != ''){
                filtered = filtered.filter(r => (this.removeAccents(r.personalCharge)).toLowerCase().includes(this.searchPersonal.toLowerCase()))
            }
            return filtered.slice(this.pageSize * this.pag - this.pageSize, this.pageSize * this.pag);
        }
    },
    created() { // cuando se crea
        
    },
}
</script>
<style lang="scss">

</style>
