import Vue from 'vue'
import App from './App.vue'
import VueRouter from 'vue-router'
import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import Element from "element-ui"
import locale from "element-ui/lib/locale/lang/es"
import "./assets/scss/ele-table.scss"
import "flag-icon-css/css/flag-icon.css"

Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);


import VueViewer from 'v-viewer'
Vue.use(VueViewer)
import 'viewerjs/dist/viewer.css'

import "./vee-validate";
import "@/plugins/echarts"
import FullCalendar from 'vue-full-calendar'
import 'fullcalendar/dist/fullcalendar.css'
import 'v-tooltip/dist/v-tooltip.css'
import VTooltip from 'v-tooltip'
import VueToast from 'vue-toast-notification';
import 'vue-toast-notification/dist/theme-sugar.css'
import VueCarousel from 'vue-carousel'
import VueCircleControlSlider from 'vue-circle-control-slider'
import 'vue-circle-control-slider/dist/VueCircleControlSlider.css'
Vue.component('VueCircleControlSlider', VueCircleControlSlider);
Vue.use(VueCarousel)
Vue.use(VueToast)
Vue.use(VTooltip)
Vue.use(FullCalendar)
Vue.use(VueRouter)
window.Event = new Vue();

Vue.use(Element, { locale })


import Router from './router/index'
import store from "./store/index"

import mitt from 'mitt'
const emitter = mitt()

Vue.config.productionTip = false
new Vue({
  router: Router,
  store,
  render: h => h(App),
  provide: {
    emitter: emitter
  }
}).$mount('#app')