<template>
    <div id="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>            
            <div class="row clearfix">
                <div class="col-lg-12">
                    <div class="card profile-header" style="text-align:center">
                        <div class="body">
                        <div class="profile-image">
                            <img :src="model.routeImage" 
                    :onerror="imgError()"  class="rounded-circle m-b-10" width="180" height="180px">
                        </div>
                        <div class="m-b-20">
                            <h4 class="m-b-1">{{model.name + ' ' + model.app}}</h4>
                            {{model.email}}<br><br>
                            <h6>{{TextLang(model.departamentEn, model.departamentEs)}}</h6>
                            <h6>{{TextLang(model.hierarchyEn, model.hierarchyEs)}}</h6>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
        </div>           
    </div>
</template>

<script>
import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import constants from '../../scripts/constants'


export default {
    components:{BreadCrumb},
    name: 'ProfileView',
    data(){        
        return{
            route: require('@/assets/user.png'),//'https://cdn.pixabay.com/photo/2015/10/05/22/37/blank-profile-picture-973460_960_720.png',
            model:{
                name: '',
                app: '',
                email: '',
                routeImage: '',
                hierarchyEs: '',
                hierarchyEn: '',
                departamentEn: '',
                departamentEs: '',
                role: '',
                gender: {
                    nameEn: '',
                    nameEs: '',
                },
            },
            role: constants.KeyCatalog
        }
    },
    methods:{
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        imgError(){          
            var male = require('@/assets/users/img_user_male.png')
            var female = require('@/assets/users/img_user_female.png')
            var other = require('@/assets/users/img_user_other.png')
            var img = other
            if (this.model.gender.nameEn == "Male") {
                img = male
            } else if (this.model.gender.nameEn == "Female") {
                img = female
            }
            this.model.routeImage = img
        },
        init(){
            request.ADFetch({
                url: Urls.Api.Auth.Profile,
                recive: dat => {
                    const person = dat[0]
                    this.model = {
                        name: person.name,
                        app: person.lastname + ' ' + (person.secondlastname == null ? '' : person.secondlastname),
                        email: person.email,
                        gender: person.gender,
                        hierarchyEs: person.hierarchyEs,
                        hierarchyEn: person.hierarchyEn,
                        departamentEs: person.departamentEs,
                        departamentEn: person.departamentEn,
                        routeImage:  person.routeImage,
                        role: person.roleEs,
                    }
                }
            })
        },       
    },
    beforeMount(){
        toolsLang.initLanguage()
        this.init()
        
    },
    beforeUpdate(){
        toolsLang.initLanguage()
    },
}
</script>