<template>
<div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="content-container justify-content-center align-items-center">
                <div v-for="(item, row) in Math.ceil(arrSections.length / 2)" :key="item.id">
                    <div class="row justify-content-center align-items-center" style="text-align: center;  width: 100%; margin: auto;" :style="arrSections.length<3?'height: 70vh;': 'height:auto'" >
                        <div v-for="column in 2" :key="row * 2 + column - 2" class="col-lg-4 col-md-6 col-sm-12" >
                            <template v-if="row * 2 + column - 2 < arrSections.length">
                                <div v-if="arrSections.length > (row * 2 + column - 2) + 1" >
                                    <div class="col-lg-2 col-sm-12"></div>
                                    <div class="card div-img" style="align-content: center; border:2;">
                                        <a :href="'/reservation/listrooms?section=' + arrSections[(row * 2 + column - 2) + 1].id">
                                            <img :src="arrSections[(row * 2 + column - 2) + 1].routeImage" style="width: 90%; height: auto; margin: auto;">
                                        </a>
                                        <div  style="vertical-align: bottom; margin-top: 20px; margin-bottom: 0px;">
                                            <label>{{TextLang(arrSections[(row * 2 + column - 2) + 1].nameEn, arrSections[(row * 2 + column - 2) + 1].nameEs)}}</label>
                                        </div>
                                    </div>
                                    <div class="col-lg-2 col-sm-12"></div>
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
                
            </div>
        </div>           
    </div>
</template>


<script>
import BreadCrumb from '../components/BreadCrumb.vue'
import toolsLang from '../common/dictionary'
import {ListSectionRoomCatalog} from '../scripts/catalogs'



export default {
    name: 'Index',
    components:{BreadCrumb},
    data: () => ({
        arrSections:[]
    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            this.language = toolsLang.changeLanguage(lang)
            
        },     
    },
    beforeMount(){
        this.language = toolsLang.initLanguage()
        ListSectionRoomCatalog().then(dat => {
            this.arrSections = []
            for (let i = 0; i < dat.length; i++) {
                if(dat[i].active == 1) {
                    this.arrSections.push(dat[i])
                }
            }
        })
    },    
    computed: { // actualiza cuando cambia algo en el dom
        
    },
    created() { // cuando se crea
       
    },
}
</script>

<style lang="scss" scoped>
.div-img{
    max-width: 100%;
    height: 100%; 
}

.div-center{
    max-width: 100%;
    height: auto; 
    padding: 15px;    
}

.card {
    border:2;
}

.content-container {
    max-width: 100%;
    margin-top: 0px;
    background-color: transparent;
    padding: 0px;
    overflow-y: scroll;
    height: 80vh; 
    ::-webkit-scrollbar {
    width: 0px;
  }
  }

  .content-container::-webkit-scrollbar {
    width: 0; /* Ancho de las barras de desplazamiento */
  }

  .content-container::-webkit-scrollbar-thumb {
    background-color: transparent; /* Color de las barras de desplazamiento */
  }
</style>