<template>
    <div>
        <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#003B5C', color: '#fff'}" :data="filtering" style="width: 100%" :empty-text="GetLang('NoData')">
          <!--prop="Building"-->
            <el-table-column
                prop="no"
                :label=GetLang(titleTable)
                sortable>
                <template slot-scope="scope">
                    <div style="background: #d7e5ed; height: 40px; padding: 10px; margin: 0px;" ><h5 >{{TextLang(scope.row.nameEn, scope.row.nameEs)}}</h5></div>

                    <div class="nav nav-tabs-new-gray tag-container " > 
                        <li v-for="per in scope.row.options" :key="per.id" class="nav-item mr-1 " style="margin: 10px; :hover{}; :focus{}" >
                            <!---<a class="nav-link" href="#" v-on:click="setActive(per)" :class="{ active:per.isActive }" >{{TextLang(per.nameEn, per.nameEs)}}</a>-->
                            <a v-bind:class="!per.isActive? 'nav-link ': 'nav-link active '" v-on:click="setActive(per)" >{{TextLang(per.nameEn, per.nameEs)}}</a>
                        </li>
                        <!----<el-tag type="success" v-for="per in scope.row.options" :key="per.id" style="margin: 10px;">{{TextLang(per.nameEn, per.nameEs)}}</el-tag>--->
                    </div>
                       
                </template>
            </el-table-column>    
        </el-table>
        <el-divider></el-divider>

        <!-- <div style="text-align: center">
            <el-pagination
                background
                layout="prev, pager, next"
                @current-change="pag = $event"
                :page-size="pageSize"
                :total="total">
            </el-pagination>
        </div> -->
    </div>
</template>

<script>
import toolsLang from '../../../common/dictionary'

export default {
    name: 'TableCatalog',
    props: {
        tableData: {
            require: true,
            type: Array,
            default(){return []}
        },
        
    },
    watch:{
        search(){
            this.total = this.totalPags
        }
    },
    data: ()=>({
        titleTable:'Permissions',
        total: 0,
        pag: 1,
        pageSize: 2,
        arrPermission: null
    }),
    methods: {
        tableRowClassName({rowIndex}) {
            rowIndex == 1?'':''
            return '';
        },
        GetLang: key => toolsLang.getKey(key),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        actions(type, sData){
            const data = JSON.parse(sData)
            switch(type){
                case "Edit": this.$emit("editRow", data); break
                case "Delet":
                    this.$confirm(toolsLang.getKey("AreYouSureToRemoveIt"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
                    .then(()=>{
                        this.$emit("deleteRow", data)
                    }).catch(()=>{})    
                break
            }
        },
        
        setActive: function (item) {
            this.$emit('Update', item)
        },
      
    },
    computed:{
        filtering(){
            if(this.search == null) return this.tableData;
            const filtered = this.tableData.filter(r => (r.building + ' ' + r.address +' '+ r.municipalityId + ' ' + r.stateId).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.slice(this.pageSize * this.pag - this.pageSize, this.pageSize * this.pag);
        },
        totalPags(){
            const filtered = this.tableData.filter(r => (r.nameEn + ' ' + r.nameEs).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.length;            
        },
       
    },

    beforeMount(){
        //this.titleTable = this.GetLang("MSRole")
    }
}
</script>

<style lang="scss">
    .container {
        display: flex; /* or inline-flex */
        flex-direction: row;
        margin: 10px;
    }
    .el-table .ascending .sort-caret.ascending {
        border-bottom-color: #0e1d37;
    }
    .el-table .descending .sort-caret.descending{
        border-top-color: #0e1d37;
    }
    .el-table .sort-caret.ascending {
        border-bottom-color: #7e8daa;
    }
    .el-table .sort-caret.descending {
        border-top-color: #7e8daa;
    }
	.el-tag{	
        border: solid 1.8px !important;
	}    
    .remove {
                
    }

    .nav-tabs-new-gray {
        > li{
            > a {
                color: #777777;	
                border: 1px solid #eeeeee;
                border-radius: 30px;
                padding:7px 23px;
                background-color: transparent;
            
                &:hover,
                &:focus {
                    color: #777777;	
                    border: 1px solid #eeeeee;
                    border-radius: 30px;
                    padding:7px 23px;
                    background-color: transparent;
                }
                &.active{
                    border: 1px solid #49c5b6 !important;
                    background: #49c5b6;
                    color: #fff;
                }
                
            }
        }
    }
  
</style>