<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>            
            <RoleComponent 
                v-if="roleId != ''"
                :title="'EditRole'"
                :type="'edit'"
                :roleId="this.roleId"
                @Close="close">
            </RoleComponent>
        </div>           
    </div>
</template>

<script>
    import toolsLang from '../../common/dictionary'
    import BreadCrumb from '../../components/BreadCrumb.vue'
    import RoleComponent from '../../components/admin/config/RolePermissionComponent.vue'
    export default {
        name: 'ViewEditRole',
        components:{BreadCrumb, RoleComponent},
        data: () => ({
            roleId: ''
        }),
        methods: {
            GetLang: k => toolsLang.getKey(k),
            onCommandLang(lang) {
                toolsLang.changeLanguage(lang)
            },
                                
            close(){
                this.$router.back()
            }   
        },
        beforeMount() { // Antes de que se monte
            toolsLang.changeLanguage()        
            this.roleId = this.$route.query.roleId
        },
        computed: { // actualiza cuando cambia algo en el dom
    
        },
        created() { // cuando se crea
            
        },
    }
    </script>
    
    <style lang="scss" scoped>
    
    </style>