<template>
    <div class="modal fade show" style="padding-right: 15px; display: block;">
        <div class="modal-dialog" >
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="title">{{title}}</h4>
                </div>
                    <div class="modal-body"> 
                        <h6>{{GetLang('Name')}}</h6>
                        <div class="input-group mb-3">
                            <input :disabled="isView" type="text" class="form-control" v-model="data.name">
                        </div> 
                        <h6>{{GetLang("Participants")}}</h6>
                        <div class="input-group" style="display:inline-flex"> 
                            <input v-if="!isView" type="email" class="form-control mb-3" multiple name="emails" id="emails" list="memeberList" v-model="newMember" @change="addMember">
                            <datalist id="memeberList">
                                <option :value="user.email" v-for="user in users" :key="user.email">{{user.email}}</option>
                            </datalist>         
                            <div class="badge-container" v-if="members.length != 0">
                                <span class="tag badge badge-user" v-for="member in members" :key="member.guid" :value="member">{{member}}<i class="icon-close m-l-5" style="cursor: pointer" @click="removeMember(member)"></i></span>
                            </div>
                        </div>
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" v-if="!isView" @click="save">{{GetLang('Save')}}</button>
                    <button class="btn btn-danger" @click="close">{{GetLang('Close')}}</button>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
import toolsLang from '../../../common/dictionary'
import request from '../../../scripts/request'
import Urls from '../../../scripts/urls'

    export default {
        name: 'DialogComponent',
        data: () => ({    
            isView: false,   
            language: '',
            newMember: '',
            members: [],
            users: []
        }),
        props:{
            data:{
                require: true,
                type: Object,
                default: () => ({
                    id: '',
                    name: '',
                    members: []
                })
            },
            title:{
                require: true,
                type: String,
                default: 'Tittle'
            },
            type: {
                require: true,
                type: String,
                default: ''
            }        
        },
        methods:{
            GetLang: key => toolsLang.getKey(key),
            close(){
                this.$emit('Close')
            },
            save(){
                this.data.members = this.members
                if(this.data.name.trim() == "") {
                    this.$toast.open({
                        message: toolsLang.getKey("NameRequired"),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                    return false
                }
                if(this.members.length == 0) {
                    this.$toast.open({
                        message: toolsLang.getKey("AddMembers"),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                    return false
                }
                this.$emit("Correct", this.data)
            },
            addMember(){
                if(this.newMember != ''){
                    let uniqueMembers = []
                    this.members.push(this.newMember)
                    this.members.forEach(item => {
                        if(!uniqueMembers.includes(item)){
                            uniqueMembers.push(item)
                        }
                    });
                    this.members = uniqueMembers
                    this.newMember = ''
                }
            },
            removeMember(mb){
                if(!this.isView){
                    var index = this.members.indexOf(mb)
                    if(index != -1) this.members.splice(index, 1)                    
                }

            },
            constructor(){
                if(this.type == 'add'){
                    this.members = []         
                }
                else{
                    this.members = this.data.emails
                }
                if(this.type == 'view')this.isView = true;
            },
            listUsers(){
                request.ADFetch({
                    url: Urls.Api.Personal.List,
                    before: () => {this.users = []},
                    recive: dat => {this.users = dat}
                })
            },
        },

        beforeMount(){
            this.listUsers()
            this.constructor()
            //this.language = toolsLang.changeLanguage(lang)
        },
        beforeUpdate(){

        }
    }
    </script>

<style lang="scss">
    .select2-container {
        margin: 0;
        position: relative;
        display: inline-block;
        vertical-align: middle;
    }
    .modal{
        background-color: rgba(50,50,50,0.4);
    }
</style>
