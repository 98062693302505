<template>
    <div class="main-content">
        <div class="container-fluid" v-if="!visible">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="card">
                <div class="body">
                    <div class="input-group m-b-20">
                        <div class="input-group-prepend">
                            <span class="input-group-text">{{GetLang('Filter')}}</span>
                        </div>
                        <input type="text" class="form-control" :placeholder="GetLang('TypeToSearch')" v-model="search">
                    </div>
                    <div>                        
                        <el-table :row-class-name="tableRowClassName" :header-cell-style="{ background: '#003B5C', color: '#fff'}" 
                                    :data="filtering" :empty-text="GetLang('NoData')">
                            <el-table-column
                                prop="userId"
                                label="Id"
                                sortable>
                                <template slot-scope="scope">
                                    {{scope.row.userId}}
                                </template>
                            </el-table-column>            
                            <el-table-column
                                prop="name"
                                sortable
                                :label="GetLang('Name')">
                                <template slot-scope="scope">
                                    {{scope.row.name}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                prop="gender"
                                :label="GetLang('Gender')"
                                width="150">
                                <template slot-scope="scope">
                                {{TextLang(scope.row.genderEn, scope.row.genderEs)}}
                                </template>
                            </el-table-column>                            
                            <el-table-column
                                prop="email"
                                sortable
                                :label="GetLang('Email')">
                                <template slot-scope="scope">
                                {{scope.row.email}}
                                </template>
                            </el-table-column>
                            <el-table-column
                                align="center"
                                width="100"
                                v-if="isUEdit">
                                <template slot-scope="scope">
                                    <button class="btn btn-sm btn-pure btn-default on-default m-r-5" @click="edit(scope.row.userId)" data-toggle="tooltip" data-original-title="View">
                                        <i class="icon-pencil"></i>
                                    </button>  
                                </template>                               
                            </el-table-column>                            
                            <el-table-column
                                align="center"
                                width="100"
                                >
                                <template slot-scope="scope">
                                    <button class="btn btn-sm  btn-pure btn-default on-default m-r-5" @click="show(scope.row.userId)" data-toggle="tooltip" data-original-title="View">
                                        <i class="icon-eye"></i>
                                    </button>  
                                </template>                               
                            </el-table-column>
                        </el-table>
                        <el-divider></el-divider>
                        <div style="text-align: center">
                            <el-pagination
                                background
                                layout="prev, pager, next"
                                @current-change="pag = $event"
                                :page-size="pageSize"
                                :total="total">
                            </el-pagination>                            
                        </div>
                    </div>                
                </div>                                   
            </div>
        </div>    
    </div>
</template>


<script>
import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import constants from '../../scripts/constants'

export default {
    name: 'ListUser',
    components: {BreadCrumb},
    data: () => ({
        lenguage: '',
        id:'',             
        search: '',
        tableData:[],
        total: 0,  
        pag: 1,
        pageSize: 7,
        visible: false,    
        isUEdit: false
    }),
    methods: {       
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        getPermissions(){
            let arrPermission = this.$store.getters.permissions
            arrPermission.forEach(item =>{
                item.childs.forEach(c =>{
                    if(c.keyName == constants.Permissions.E_PERMISSIONS){
                        this.isUEdit = true
                    }
                })
                
            })
        },
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        listUsers(){
            request.ADFetch({
				url: Urls.Api.Personal.List,
				before: () => {
					this.tableData = []
				},
				recive: (dat) => {
					this.tableData = dat
				},
			})
        },
        show(id){
            this.$router.push({path: '/user/view', query: {tok: id}})
        },
        edit(id){
            this.$router.push({path: '/user/edit', query: {tok: id}})
        },
        close(){
            this.pag= 1
        }
    },    
    
    watch:{
        totalPags(){
            this.total = this.totalPags
        }
    },
    beforeMount() { // Antes de que se monte
        this.getPermissions()
        toolsLang.initLanguage()
        this.listUsers()
    },
    computed: { // actualiza cuando cambia algo en el dom
        filtering() {
            if (this.search === null) return this.tableData;
            const filtered = this.tableData.filter((r) =>
                (r.name + ' ' + r.lastname + ' '+ r.secondlastname + ' ' + r.email +' ' + r.id).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.slice(this.pageSize * this.pag - this.pageSize,this.pageSize * this.pag)
        },
        totalPags(){
            const filtered = this.tableData.filter((r) =>
            (r.name + ' ' + r.lastname + ' '+ r.secondlastname + ' ' + r.email +' ' + r.id).toLowerCase().includes(this.search.toLowerCase()));
            return filtered.length
        }
        
    },
    beforeUpdate(){
        toolsLang.initLanguage()        

    },    
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>

</style>