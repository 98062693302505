<template>
    <div v-if="data != null">
        <edit-reservation :edit="true" :data="data" :date="data.date" :type="'edit'" :isSending="isSending" @Cancel="cancel" @Save="edit"></edit-reservation>
    </div>
    <div v-else>
    
    </div>
</template>

<script>
    import EditReservation from '../../components/admin/reservation/reservationComponent.vue'
    import toolsLang from '../../common/dictionary'
    import request from '../../scripts/request'
    import Urls from '../../scripts/urls'

    export default{
        name: 'Edit',
        components: {EditReservation},
        data: () => ({
            reservation: [],
            data:null,
            isSending: false
        }),
        methods: {
            GetLang: k => toolsLang.getKey(k),
            TextLang: (en,es) => toolsLang.getTranslate(en,es),
            onCommandLang(lang) {
                toolsLang.changeLanguage(lang)
            },
            init(){
                const tok = this.$route.query.tok
                request.ADFetch({
                    url: Urls.Api.Reservation.Detail,
                    data: {value: tok},
                    // before: () => {this.reseravtion = []},
                    recive: dat => {
                        var sttTime = dat.dateTimeInit.split('T')[1]
                        sttTime = sttTime.split(':')[0]+':'+sttTime.split(':')[1]
                        var endTime = dat.dateTimeEnd.split('T')[1]
                        endTime = endTime.split(':')[0]+':'+endTime.split(':')[1]
                        const date = new Date(dat.dateTimeInit)
                        this.data = {
                            id: dat.id,
                            name: dat.name,
                            description: dat.description,
                            justify: '',
                            status: dat.status,
                            dateTimeInit: sttTime,
                            dateTimeEnd: endTime,
                            date: date,
                            userId: dat.userId,
                            room: {
                                id: dat.room.id,
                                name: dat.room.name,
                                description: dat.room.description,
                                numberPeople: dat.room.numberPeople,
                                flat: dat.room.flat,
                                area: dat.room.area,
                                status: dat.room.status,
                                typeRoom:{
                                    id: dat.room.typeRoom.id,
                                    nameEn:  dat.room.typeRoom.nameEn,
                                    nameEs:  dat.room.typeRoom.nameEs
                                },
                                manager: {
                                    nameComplete: dat.room.manager.nameComplete,
                                    email: dat.room.manager.email,
                                    phone: dat.room.manager.phone,
                                },                    
                                location: {
                                    address: dat.room.location.address,
                                    name: dat.room.location.name,
                                    stateId: dat.room.location.stateId,
                                    municipalityId:  dat.room.location.municipalityId,
                                    municipality: dat.room.location.municipality
                                },
                            },
                            internalUsers: dat.internalUsers,
                            externalUsers: dat.externalUsers, 
                            groups: dat.groups
                        }
                    }
                })
            },
            edit(model){
                if(model.date == null || model.timeInit == '' || model.timeEnd == ''){
                    this.$toast.open({
                        message: toolsLang.getKey('ScheduleRequired'),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                    return false;
                }
                if(model.internalPersonal.length == 0 && model.externalPersonal.length == 0 && model.groups.length == 0){
                    this.$toast.open({
                        message: toolsLang.getKey('UsersRequired'),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })
                    return false;
                }
                var groupsId = []
                model.groups.forEach((item) => {
                    groupsId.push(item.id)
                })
                request.ADFetch({
                   url: Urls.Api.Reservation.Update,
                   data: {
                        id: model.id,
                       roomId: model.roomId,
                       name: model.name,
                       description: model.description,
                       date: model.date,
                       startTime: model.timeInit,
                       endTime: model.timeEnd,
                       internalPersonal: model.internalPersonal,
                       externalPersonal: model.externalPersonal,
                       groups: groupsId
                   },
                   before:() => {this.isSending = true},
                   success: () => {
                       this.$toast.open({
                           message: toolsLang.getKey("MeetingUpdated"),
                           type: "success",
                           duration: 3000,
                           position: 'top'
                       })
                       this.$router.back()
                   },
                   complete: () => {this.isSending = false}
               })
            },
            cancel(){
                this.$router.back()
            }
        },
        watch:{

        },
        beforeMount() { // Antes de que se monte
            this.init()      
        },
        computed: { // actualiza cuando cambia algo en el dom
            
        },
        created() { // cuando se crea
            
        },        
        
    }
</script>