<template>
<div class="modal fade show" style="padding-right: 15px; display: block;">
    <div class="modal-dialog" >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="title">{{title}}</h4>
            </div>
                <div class="modal-body"> 
                    <h6>{{GetLang('NameEs')}}</h6>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="data.nameEs">
                    </div> 
                    <h6>{{GetLang('NameEn')}}</h6>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="data.nameEn">
                    </div>
                    <h6>{{GetLang('Status')}}</h6>
                    <div class="input-group mb-3">
                        <label class="fancy-radio"><input name="status" :value="1" v-model="data.active" type="radio"><span><i></i>{{GetLang('Active')}}</span></label>
                        <label class="fancy-radio"><input name="status" :value="0" v-model="data.active" type="radio"><span><i></i>{{GetLang('Inactive')}}</span></label>
                    </div>                                                               
                </div>
            <div class="modal-footer">
                <button class="btn btn-primary" @click="save">{{GetLang('Save')}}</button>
                <button class="btn btn-danger" @click="close">{{GetLang('Cancel')}}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import toolsLang from '../../../common/dictionary'
export default {
    name: 'DialogComponent',
    data: () => ({
       
    }),
    props:{
        data:{
            require: true,
            type: Object,
            default: ()=>({
                nameEs: '',
                nameEn: '',
                active: 0,
                keyName: ''
            })            
        },
        title:{
            require: true,
            type: String,
            default: 'Tittle'
        },
        type: {
            require: true,
            type: String,
            default: 'add'
        }        
    },
    methods:{
        GetLang: key => toolsLang.getKey(key),
        close(){
            this.$emit('Close')
        },
        save(){
            if(this.data.nameEs == '' || this.data.nameEn == ''){
                this.$toast.open({
                    message: toolsLang.getKey("FieldRequired"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })               
            }
            else{
                this.$emit("Correct", this.data)

            }
        }
    },
    watch:{

    },
    beforeMount(){
        toolsLang.initLanguage()
    }
}
</script>

<style lang="scss" scoped>
    .modal{
        background-color: rgba(50,50,50,0.4);
    }
</style>