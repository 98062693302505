<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <!-- <div class="col-sm-12" align="right">
                    <button type="button" class="btn btn-block btn-primary b-add" @click="clear();addVisible = true" align="right" style="margin-bottom: 20px;">{{GetLang("BAddPermission")}}</button>
                </div> -->
                <div class="card">
                    <div class="header">
                        <div class="comment-form">
                            <form class="row">
                                <div class="col-sm-12">
                                    <div class="input-group mb-3" >
                                        <div class="input-group-prepend" >
                                            <span class="input-group-text" id="inputGroup-sizing-default">{{GetLang("Filter")}}</span>
                                        </div>
                                        <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" :placeholder="GetLang('TypeToSearch')" v-model="search" />
                                        <button type="button" class="btn btn-primary b-add" @click="add" style="margin-left: 15px">{{GetLang("AddRole")}}</button>
                                    </div>
                                </div>
                                     
                            </form>
                        </div>
                    </div>
                    <div class="body">
                        <div style="width: 100%"> 
                            <table-catalog 
                                :tableData="tableData" 
                                :search="search"
                                @editRow="edit"
                                @deleteRow="deleteRow"                       
                            ></table-catalog>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <dialog-component
             v-if="addVisible"
             :title="title" 
             @Close="close"
             @Correct="save($event)"
             ></dialog-component>         
        </div>
        <!-- Edit -->
        <div>
            <dialog-component
             v-if="editVisible"
             :title="title" 
             :data="model"
             @Close="close"
             @Correct="save($event, 'edit')"
             ></dialog-component>         
        </div>                
    </div>
</template>

<script>

import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import tableCatalog from '../../components/admin/table/TableCatalogComponent.vue'
import dialogComponent from '../../components/admin/dialog/DialogRolePermissionComponent.vue'
import Urls from '../../scripts/urls'
import request from '@/scripts/request'
import constants from '../../scripts/constants'

export default {
    name: 'CPermissionComponent',
    components: {
        BreadCrumb,
        tableCatalog,
        dialogComponent
    },
    data: () => ({
        tableData: [],
        addVisible: false,
        model: {
            nameEs: '',
            nameEn: '',
            code: '',
            active: 0,
            keyName: constants.KeyCatalog.Role
        },
        sending: false,
        editVisible: false,
        title: toolsLang.getKey('AddRole'),
        search: '',
        total: 0    
    }),
    methods: {
        GetLang: key => toolsLang.getKey(key),
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        edit(data){
           this.$router.push({path: '/catalog/editrole', query:{roleId: data.id}})        
        },
        add(){
            this.$router.push({path: '/catalog/addrole'})
        },
        close(){
            //this.addVisible = false
            //this.editVisible = false
            this.visible = false
            this.pag= 1
            this.list()
        },
        deleteRow(data){
           request.ADFetch({
                url: Urls.Api.Config.RemoveRole,
                data:{
                    keyName: data.keyName,
                    id: data.id,
                    code: data.code
                },
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey('Deleted'),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                },
                complete: () => {this.list()}
            })
        },
        save(model, type = ""){ 
            let auxUrl = Urls.Api.Catalog.Role.Add
            let msg = toolsLang.getKey('AddingRole')
            if(type == "edit") {
                auxUrl = Urls.Api.Catalog.Role.Update
                msg = toolsLang.getKey('EditedRole')
            }
            request.ADFetch({
                    url: auxUrl,
                    data:{
                        keyName:model.keyName,
                        id: '',
                        code: model.code,
                        nameEs: model.nameEs,
                        nameEn: model.nameEn,
                        active: model.active
                    },
                    success: () => {
                        this.getPermissionUser()
                    this.$toast.open({
                        message: msg,
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                    },
                    complete: () => { 
                        this.sending = false
                        this.clear()
                        this.list()
                        this.addVisible = false
                        this.editVisible = false 
                    }                    
                })
        },
        getPermissionUser(){
            request.ADFetch({
                url: Urls.Api.Auth.Permissions,
                recive: dat => {
                    this.$store.dispatch("setPermissions", dat)
                    this.$store.commit('setPermission')
                }
            })
        },
        clear(){
            this.model = {
                nameEs: '',
                nameEn: '',
                code: '',
                active: 0,
                id: '',
                keyName: constants.KeyCatalog.Role
            }            
        },                
        list(){
            request.ADFetch({
                url: Urls.Api.Catalog.Role.List,
                before: () => { this.tableData = [] },
                recive: dat => { this.tableData = dat}
            })
            
        },
    },
    beforeMount() { // Antes de que se monte
        this.list()
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>

</style>