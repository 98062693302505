import { render, staticRenderFns } from "./editRoomPage.vue?vue&type=template&id=37f5d023&scoped=true&"
import script from "./editRoomPage.vue?vue&type=script&lang=js&"
export * from "./editRoomPage.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "37f5d023",
  null
  
)

export default component.exports