import request from "./request";
import Urls from "./urls";
import constants from "./constants";


export const Genders = () => ListCatalog(constants.KeyCatalog.Gender)
export const Skills = () => ListCatalog(constants.KeyCatalog.Skill)
export const Awards = () => ListCatalog(constants.KeyCatalog.Award)
export const Idioms = () => ListCatalog(constants.KeyCatalog.Idiom)
export const Location = () => ListCatalog(constants.KeyCatalog.Location)
export const Groups = () => ListCatalog(constants.KeyCatalog.Group)
export const Services = () => ListCatalog(constants.KeyCatalog.Service)
export const TypesRoom = () => ListCatalog(constants.KeyCatalog.TypesRoom)

const ListCatalog = keyName => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.List,
        data: { keyName: keyName },
        recive: success,
        error: error
    })
})

export const AddCatalog = (model, complete = ()=>{}) => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.Add,
        data: model,
        success: success,
        error: error,
        complete: complete
    })
})

export const UpdateCatalog = (model, complete = ()=>{}) => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.Update,
        data: model,
        success: success,
        error: error,
        complete: complete
    })
})

export const RemoveCatalog = (id, complete = ()=>{}) => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.Remove,
        data: { id: id },
        success: success,
        error: error,
        complete: complete
    })
})

export const ListSectionRoomCatalog = () => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.ListSectionRoom,
        data: { },
        recive: success,
        error: error
    })
})

export const AddSectionRoomCatalog = (model, complete = ()=>{}) => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.AddSectionRoom,
        data: model,
        success: success,
        error: error,
        complete: complete
    })
})

export const UpdateSectionRoomCatalog = (model, complete = ()=>{}) => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.UpdateSectionRoom,
        data: model,
        success: success,
        error: error,
        complete: complete
    })
})

export const RemoveSectionRoomCatalog = (id, complete = ()=>{}) => new Promise((success, error) => {
    request.ADFetch({
        url: Urls.Api.Catalog.RemoveSectionRoom,
        data: { id: id },
        success: success,
        error: error,
        complete: complete
    })
})