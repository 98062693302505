<template> 

  <div id="wrapper" style="height: 100vh;">
    <img class="logo-with-posco" v-if="!['login', 'register','lockscreen','forgotPassword','404','403','500','503','maintanance'].includes($route.name)"  src="@/assets/images/img_logo_with_posco.png" >
    <div class="page-loader-wrapper" v-if="isLoading">
      <div class="loader">
          <div class="m-t-30">
            <img src="./assets/logo-white-posco.svg" width="200" height="auto" alt="posco-salas">
          </div>
          <p>{{GetLang('Loading')}}</p>        
      </div>
    </div> 
    <HeaderComponent v-if="!['login', 'register','lockscreen','forgotPassword','404','403','500','503','maintanance'].includes($route.name)" />
    <SidebarComponent  v-if="!['login', 'register','lockscreen','forgotPassword','404','403','500','503','maintanance'].includes($route.name)"/>
    <!---<div > 
      <img  src="./assets/images/img_background_home.png" alt="posco-salas">
    </div>--->
    <router-view></router-view>
    
  </div>
</template>
<script>
import HeaderComponent from './components/admin/layouts/HeaderComponent.vue'
import SidebarComponent from './components/admin/layouts/SidebarComponent.vue'
import toolsLang from './common/dictionary'

export default {
  name: 'App',
  components: {
    HeaderComponent,
    SidebarComponent
  },watch: {
    $route: {
      immediate: true, // also trigger handler on initial value
      handler() {
         if (this.$route.name === 'maintanance') {
            document.body.className = '';
          }else{
            document.body.className = 'theme-blue';
          }
      }
    }   
  },methods:{
    GetLang: k => toolsLang.getKey(k),
  },data (){
      return{
        isLoading: true,
      }
  },created(){
         this.interval = setInterval(() => {
            this.isLoading = false
        }, 3000);
    },
}
</script>
<style lang="scss">
  @import '../src/assets/vendor/bootstrap/css/bootstrap.min.css';
  // @import '../src/assets/vendor/bootstrap/css/bootstrap.css';
  @import '../src/assets/vendor/font-awesome/css/font-awesome.min.css';
  @import '../src/assets/scss/main.scss';
  @import '../src/assets/scss/color_skins.scss';
  @import '../src/assets/scss/skins/_blue.scss';

  .el-table .color-row{
    background: #f2f2f2;
	}

  .b-add{
    background: #00A3E0;
    color: '#fff';
    width: 160px; 
    height: auto; 
    padding: 3px;
    border-color: #00A3E0;
	}

  .b-add:hover {
    color: #fff;
    background-color: #003b5b;
    border-color: #003b5b;
  }

  .b-add:active {
    color: #fff;
    background: #00A3E0;
    background-color: #003b5b;
    border-color: #003b5b;
  }

  .b-add:focus {
    color: #fff;
    background-color: #003b5b;
    border-color: #003b5b;
  }
  
  .logo-with-posco{
    width: 13%; 
    height: auto; 
    margin: auto;
    position: absolute;
    bottom: 10px;
    right: 2%;
}
</style>
