

import DummyView from '../../components/admin/dashboard/DummyView.vue'
// import BlankComponent from '../../components/admin/pages/BlankComponent.vue'

import Home from '../../views/home.vue'
export const menuHome = [{
    keyName: constants.Permissions.GENERIC,
    activeMenu: 'home',
    isHome: true,
    path: '/',
    name: 'Inicio',
    redirect: '/dashboard/index',
    component: DummyView,
    icon:'ic_home',
    isMain: true,
    meta: {
        title: 'MHome'
    },
    children: [
        {
            path: '/dashboard/index',
            component: Home,
            meta: {
                title: 'MHome',
            }
        }
    ]
}]

import AdminAddRoom from '../../views/adminRoom/addRoomPage.vue'
import AdminListRoom from '../../views/adminRoom/listRoomPage.vue'
import AdminEditRoom from '../../views/adminRoom/editRoomPage.vue'
import AdminViewRoom from '../../views/adminRoom/viewRoomPage.vue'
import AdminListRoomByCalendar from '../../views/adminRoom/reservationByCalendarPage.vue'

export const menuAdministratorRooms = [{
    keyName: constants.Permissions.ADMIN_ROOM,
    activeMenu: 'MenuAdminRoom',
    path: '/admin',
    name: 'MenuAdminRoom',
    redirect: '/admin/rooms',
    component: DummyView,
    icon:'ic_home',
    meta: {
        title: 'MAdminRooms',
        
    },
    children: [
        {
            keyName: constants.Permissions.AR_SHOW,
            path: '/admin/rooms',
            component: AdminListRoom,
            meta: {
                title: 'MSRoomList'
            }
        },
        {
            keyName: constants.Permissions.AR_EDIT,
            path: '/admin/addroom',
            component: AdminAddRoom,
            meta: {
                title: 'MSAddRoom'
            }
        },
        {
            keyName: constants.Permissions.AR_EDIT,
            path: '/admin/editroom',
            component: AdminEditRoom,
            isHidden: true,
            meta: {
                title: 'EditRoom'
            }
        },
        {
            keyName: constants.Permissions.AR_SHOW,
            path: '/admin/viewroom',
            component: AdminViewRoom,
            isHidden: true,
            meta: {
                title: 'ViewRoom'
            }
        },   
        {
            keyName: constants.Permissions.AR_SHOW,
            path: '/admin/calendar',
            component: AdminListRoomByCalendar,
            isHidden: true,
            meta: {
                title: 'MSRoomList'
            }
        },     
        
    ]
}]

import UserList from '../../views/user/listUserPage.vue'
import ViewUser from '../../views/user/viewUserPage.vue'
import EditUser from '../../views/user/editUserPage.vue'

export const menuUser = [{
    keyName: constants.Permissions.ADMIN_EMPLOYEES,
    activeMenu: 'MenuUsers',
    path: '/user',
    name: 'MenuUsers',
    redirect: '/user/list',
    component: DummyView,
    icon:'ic_user',
    isMain: true,
    meta: {
        title: 'MUsers',
    },

    children: [
        {
            keyName: constants.Permissions.E_SHOW,
            path: '/user/list',
            component: UserList,
            meta: {
                title: 'UserList'
            }
        },
        {
            keyName: constants.Permissions.E_PERMISSIONS,
            path: '/user/view',
            component: ViewUser,
            meta: {
                title: 'UserView',			
            }
        },
        {
            keyName: constants.Permissions.E_PERMISSIONS,
            path: '/user/edit',
            component: EditUser,
            meta: {
                title: 'UserView',			
            }
        },
    ]
}]


import CPermissionComponent from '../../views/catalog/permissionPage.vue'
import CLocationsComponent from '../../views/catalog/locationPage.vue'
import CGroupsComponent from '../../views/catalog/groupPage.vue'
import CServicesComponent from '../../views/catalog/servicePage.vue'
import CSectionTypeRoomComponent from '../../views/catalog/typesRoomMainPage.vue'
import CTypeRoomComponent from '../../views/catalog/typesRoomPage.vue'
import CAddRoleComponent from '../../views/catalog/addRolePage.vue'
import CEditRoleComponent from '../../views/catalog/editRolePage.vue'

export const menuCatalog = [{
    keyName: constants.Permissions.ADMIN_CATALOGS,
    activeMenu: 'MenuCatalogs',
    path: '/catalog',
    name: 'MenuCatalogs',
    redirect: '/catalog/types',
    component: DummyView,
    icon:'ic_catalogs',
    meta: {
        title: 'MCatalogs',
    },
    children: [
        {
            keyName: constants.Permissions.C_SECTION_ROOM_TYPES,
            path: '/catalog/sectionTypes',
            component: CSectionTypeRoomComponent,
            meta: {
                title: 'TypesRoomSection'
            }
        },        
        {
            keyName: constants.Permissions.C_ROOM_TYPES,
            path: '/catalog/types',
            component: CTypeRoomComponent,
            meta: {
                title: 'TypesRoom'
            }
        },        
        {
            keyName: constants.Permissions.C_SERVICES,
            path: '/catalog/services',
            component: CServicesComponent,
            meta: {
                title: 'Services'
            }
        },        
        {
            keyName: constants.Permissions.C_GROUPS,
            path: '/catalog/groups',
            component: CGroupsComponent,
            meta: {
                title: 'MSGroups'
            }
        },
        {
            keyName: constants.Permissions.C_LOCATIONS,
            path: '/catalog/locations',
            component: CLocationsComponent,
            meta: {
                title: 'MSLocations'
            }
        },
        {
            keyName: constants.Permissions.C_ROLES_PERMISSION,
            path: '/catalog/roles',
            component: CPermissionComponent,
            meta: {
                title: 'MSRole'
            }
        },
        {
            keyName: constants.Permissions.C_ROLES_PERMISSION,
            path: '/catalog/addrole',
            component: CAddRoleComponent,
            isHidden: true,
            meta: {
                title: 'MAddRole'
            }
        },
        {
            keyName: constants.Permissions.C_ROLES_PERMISSION,
            path: '/catalog/editrole',
            component: CEditRoleComponent,
            isHidden: true,
            meta: {
                title: 'MEditRole'
            }
        },
    ]
}]
import ListRooms from '../../views/adminRoom/reservationByCalendarPage.vue'
import ListByCard from '../../views/reservation/roomReservationPage.vue'
import Reservations from '../../views/reservation/myReservationPage.vue'
import AllReservations from '../../views/reservation/allReservationsPage.vue'
import AddReservation from '../../components/admin/reservation/addReservationComponent.vue'
import EditReservation from '../../views/reservation/editReservationPage.vue'
import ShowReservation from '../../views/reservation/showReservationPage.vue'

import constants from '../../scripts/constants'

export const menuReservation = [{
    keyName: constants.Permissions.ADMIN_RESERVATIONS,
    activeMenu: 'MenuReservations',
    path: '/reservation',
    name: 'MenuReservations',
    redirect: '/reservation/myreservations',
    component: DummyView,
    icon:'ic_reservations',
    meta: {
        title: 'MReservations',
        
    },
    children: [

        {
            keyName: constants.Permissions.R_SHOW_PERSONAL,
            path: '/reservation/myreservations',
            component: Reservations,
            meta: {
                title: 'MSMyReservations'
            }
        },
        {
            keyName: constants.Permissions.R_LIST_ROOM,
            path: '/reservation/listrooms',
            component: ListRooms,
            meta: {
                title: 'MSRoomList'
            }
        },
        {
            keyName: constants.Permissions.R_LIST_ROOM,
            path: '/reservation/list',
            component: ListByCard,
            isHidden: true,
            meta: {
                title: 'ListByCard'
            }
        },
        {
            keyName: constants.Permissions.R_ADD,
            path: '/reservation/add',
            component: AddReservation,
            isHidden: true,
            meta: {
                title: 'AddReservation'
            }
        },
        {
            keyName: constants.Permissions.R_SHOW_ALL,
            path: '/reservation/reservations',
            component: AllReservations,
            isHidden: true,
            meta: {
                title: 'AllReservations'
            }
        },
        {
            keyName: constants.Permissions.R_SHOW_ALL,
            path: '/reservation/edit',
            component: EditReservation,
            isHidden: true,
            meta: {
                title: 'EditReservation'
            }
        },   
        {
            keyName: constants.Permissions.R_SHOW_ALL,
            path: '/reservation/show',
            component: ShowReservation,
            isHidden: true,
            meta: {
                title: 'ShowReservation'
            }
        }
    ]
}]
import ViewReports from '../../views/reports/reportsPage.vue'
export const menuReport = [{
    keyName: constants.Permissions.ADMIN_REPORTS,
    activeMenu: 'MenuReports',
    path: '/reports',
    name: 'MenuReports',
    redirect: '/reports',
    component: DummyView,
    icon:'ic_reports',
    isMain: true,
    meta: {
        title: 'MReports',        
    },
    children: [
        {
            keyName: constants.Permissions.AR_FREQUENCY_REPORT,
            path: '/reports',
            component: ViewReports,
            meta: {
                title: 'MReports'
            }
        }
    ]
}]

export default [...menuHome, ...menuAdministratorRooms, ...menuCatalog, ...menuUser, ...menuReservation, ...menuReport]