/*
    TT = Top Title
    T = Title
    D = Description
    B = Button
*/
const dictionary = {  
    "Accept":"Aceptar",
    "AccessDenied":"Acceso denegado",
    "Accions":"Acciones",
    "Acknowledgments":"Reconocimientos",
    "Action":"Acción",
    "ActionRequired":"Acción obligatoria",
    "Active":"Activo",
    "actived":"Activada",
    "ActiveUser":"Usuario activo",
    "add":"Agregar",
    "AddAction":"Agregar acción",
    "AddCatalog":"Agregar catálogo",
    "Added":"Agregado",
    "AddExternalPersonal":"Agregar correo del personal externo",
    "AddFile":"Adjuntar archivo",
    "AddGroup":"Agregar grupo",
    "AddingGroup":"Grupo agregado",
    "AddingLocation":"Ubicación agregada",
    "AddingRole":"Rol agregado",
    "AddingService":"Servicio agregado",
    "AddingTypeRoom":"Tipo de sala agregada",
    "AddInternalPersonal":"Agregar correo del personal de posco",
    "AddLocation":"Agregar ubicación",
    "AddMembers":"Agrega integrantes al grupo",
    "AddNew":"Agregar",
    "AddNewUser":"Agregar nuevo usuario",
    "AddReservation":"Agregar reservación",
    "Address":"Dirección",
    "AddRole":"Agregar rol",
    "AddRoom":"Agregar sala",
    "AddService":"Agregar servicio",
    "AddTypeRoom":"Agregar tipo de sala",
    "admin":"Administración",
    "AdminCatalog":"Administración de catálogos",
    "AdminRolesPermssion":"Adminstración de roles y permisos",
    "AdressRequired":"La dirección es obligatoria",
    "Alert":"Alerta",
    "All":"Todas",
    "AllReservations":"Todas las reservaciones",
    "Area":"Área",
    "AreaRequired":"El área es obligatoria",
    "AreYouSureToCancel":"¿Estás seguro de cancelar la reservación?",
    "AreYouSureToRemoveIt":"¿Está seguro de eliminarlo?",
    "AreYouSureToRemoveRoom":"¿Estás seguro de eliminar la sala?",
    "Atention":"Atención",
    "Back":"Atrás",
    "BAddPermission":"Agregar un permiso",
    "Branch":"Sucursal",
    "BRestorePassword":"Restablecer contraseña",
    "Building":"Edificio",
    "BuildingRequired":"El edificio es obligatorio",
    "calendar":"calendario",
    "Cancel":"Cancelar",
    "Cancelled":"Cancelada",
    "CancelReservation":"Cancelar reservación y reunión",
    "CapacityPeople":" Capacidad de personas",
    "CapacityRequired":"El número de personas es obligatorio",
    "catalog":"catálogo",
    "Catalogs":"Catálogos",
    "charts":"gráficas",
    "Close":"Cerrar",
    "Code":"Código",
    "CodeRequired":"El código es obligatorio",
    "Company":"Compañia",
    /* "ConferenceRoom":"Sala de conferencia", */
    "Country":"País",
    "dashboard":"inicio",
    "DataCatalogNotExist":"El catálogo no existe",
    "DataCatalogRegistered":"Los datos para el catálogo ya se encuentran registrados",
    "DataLocationNotExist":"La ubicación no existe",
    "DataLocationRegistered":"Los datos para la ubicación ya se encuentran registrados",
    "DataRoomNotDelete":"No se puede eliminar la sala por que ya cuenta con reservaciones",
    "DataRoomNotExist":"La sala no existe",
    "DataRoomRegistered":"Los datos para la sala ya se encuentran registrados",
    "DataRoomRequired":"La sala es obligatoria",
    "Date":"Fecha",
    "DateBirth":"Fecha de nacimiento",
    "DateRequired":"La fecha es requerida",
    "Delete":"Eliminar",
    "Deleted":"Eliminado",
    "Description":"Descripción",
    "DescriptionRequired":"La descripcion es obligatoria",
    "Details":"Detalles",
    "DForgotPasswordPassword":"Por favor ingresa tu correo electrónico, te enviaremos instrucciones para reestablecer tu contraseña",
    "Edit":"Editar",
    "Edited":"Editado",
    "EditedGroup":"Grupo editado",
    "EditedLocation":"Ubicacion editada",
    "EditedRole":"Rol editado",
    "EditedService":"Servicio editado",
    "EditedTypeRoom":"Tipo de sala editada",
    "EditGroup":"Editar grupo",
    "EditLocation":"Editar ubicación",
    "EditReservation":"Editar reservación",
    "EditRole":"Editar rol",
    "EditRoom":"Editar sala",
    "EditService":"Editar servicio",
    "EditTypeRoom":"Editar tipo de sala",
    "Email":"Correo electrónico",
    "End":"Fin",
    "EndTimeMessage":"La hora de finalización no puede ser antes de la hora de inicio",
    "EndTimeRequired":"La hora de término es obligatoria",
    "ErrorDateFormat":"Formato de fecha incorrecto",
    "ErrorDateInitEndMeeting":"Por favor verifica que la fecha, hora de inicio y hora final sean correctas",
    "ErrorDeleteLocationUsed":"La ubicación no se puede eliminar porque está siendo utilizada por una sala",
    "ExistingRoleNameEN":"El nombre en inglés ya existe para otro rol",
    "ExistingRoleNameES":"El nombre en español ya existe para otro rol",
    "ExpiredToken":"El token ha caducado o no estás autorizado",
    "ExtensionAllowed":"Solo es posible subir jpg o png",
    "ExtensionPDFAllowed":"Solo es posible subir pdf",
    "ExtensionRequired":"La extensión es obligatoria",
    "ExternalPersonal":"Correo del personal externo",
    "FailSave":"Error al guardar",
    "FieldRequired":"Completa los campos requeridos",
    "File":"Archivo",
    "FileNotSave":"Archivo no guardado",
    "Filter":"Filtro",
    "FilterByName":"Filtrar por nombre",
    "FilterHere":"Filtrar aquí",
    "FilterStaff":"Escribe aquí para filtrar empleado",
    "Finished":"Terminada",
    "finished":"Finalizada",
    "Flat":"Piso",
    "FlatRequired":"El piso es obligatorio",
    "ForgotMyPassword":"Olvidé mi contraseña",
    "ForgotPassword":"Ha solicitado restablecer su contraseña, haga clic en el botón de abajo para continuar con el proceso.",
    "ForgotPwd":"¿Olvidaste la contraseña?",
    "FormatEncryptionIncorrect":"La contraseña no tiene el formato de encriptación correcto",
    "From":"De",
    "Gender":"Género",
    "Group":"Grupo",
    "Groups":"Grupos",
    "Hour":"Hora",
    "ImageNotSaved":"La imagen no pudo ser guardada",
    "ImageRequired":"La imagen es obligatoria",
    "Inactive":"Inactivo",
    "InactiveUser":"Usuario inactivo",
    "IncorrectCredentials":"Las credenciales son incorrectas",
    "index":"inicio",
    "IneRequired":"La Identificación es obligatoría",
    "Information":"Información",
    "InformationCorrect":"¿La información es correcta?",
    "InNumber":"Número interior",
    "InProcess":"En proceso",
    "InstructionResetPassword":"Te hemos enviado un correo electrónico con las instrucciones para restablecer tu contraseña a",
    "InternalPersonal":"Correo del personal de posco",
    "InvalidEmail":"Correo electrónico invalido",
    "IsRequired":"Requerido",
    "Justification":"Justificación",
    "KnowYourPwd":"¿Sabes tu contraseña?",
    "LangEnglish":"Inglés",
    "LangSpanish":"Español",
    "LanguageRequire":"Se debe capturar al menos un idioma",
    "Languages":"Idiomas",
    "list":"lista",
    "ListByCard":"Lista por sala",
    "listroom":"lista de salas",
    "listrooms":"Listas de salas",
    "Loading":"Cargando...",
    "Location":"Ubicación",
    "LocationRequired":"La ubicación es obligatoria",
    "locations":"ubicaciones",
    "LoggingIn":"Iniciando sesión ...",
    "LogginOn":"Entrando",
    "Login":"Inicia sesión",
    "LoginCredentials":"Ingresa tus credenciales de acceso",
    "LogOn":"Entrar",
    "Logout":"Cerrar sesión",
    "MAdminRooms":"Administrador de salas",
    "MailSent":"Correo enviado correctamente",
    "MCatalogs":"Catálogos",
    "MeetingNameRequired":"El nombre de la reunión es obligatorio",
    "MeetingUpdated":"Reunión actualizada",
    "Menu":"Menú",
    "MHome":"Inicio",
    "MReports":"Reportes",
    "MReservations":"Reservaciones",
    "MSAddRoom":"Agregar sala",
    "MSGroups":"Grupos",
    "MSLocations":"Ubicaciones",
    "MSMyReservations":"Mis reservaciones",
    "MSRole":"Roles y permisos",
    "MSRoomList":"Lista de salas",
    "MSRooms":"Salas",
    "Municipality":"Municipio",
    "MUsers":"Empleados",
    "MyProfile":"Mi perfil",
    "myreservations":"mis reservaciones",
    "Name":"Nombre",
    "NameEn":"Nombre (EN)",
    "NameEnglish":"Nombre en inglés",
    "NameEs":"Nombre (ES)",
    "NameMeeting":"Nombre de la junta",
    "NameRequired":"El nombre es obligatorio",
    "NameSpanish":"Nombre en español",
    "NewLocation":"Nueva ubicación",
    "NewMeeting":"Nueva reunión",
    "NewPassword":"Nueva contraseña",
    "Next":"Siguiente",
    "NoConnection":"No tienes conexión",
    "NoData":"Sin datos",
    "NoErrors":"No hay errores que mostrar",
    "NotRemovedRoom":"No se puede eliminar esta sala, tiene reservaciones",
    "NumberPeopleRequired":"El número de personas debe ser mayor a 0",
    "Office":"Oficina",
    "OutNumber":"Número exterior",
    "Participants":"Participantes",
    "Password":"Contraseña",
    "PasswordStrong":"Ingresa tu nueva contraseña y para reforzar la seguridad de la misma no olvides colocar letras y números.",
    "pendient":"Pendiente",
    "Pendients":"Pendientes",
    "PermissionDenied":"Permiso denegado",
    "PermissionRequired":"Necesitas agregar al menos un permiso",
    "Permissions":"Permisos",
    "Personal":"Empleados",
    "PersonalIformation":"Información personal",
    "PersonalInChargeRoom":"Personal a cargo de la sala",
    "PersonalList":"Lista de empleados",
    "PersonalRequired":"El encargado es obligatorio",
    "PersonCharge":"Personal encargado",
    "Phone":"Teléfono",
    "Previous":"Anterior",
    "process":"En proceso",
    "Profile":"Perfil",
    "ProfileImageRequired":"La imagen de perfil de usuario es obligatoria",
    "Protected":"Protegido",
    "Records":"Archivos",
    "Recovering":"Recuperando",
    "Recovery":"Recuperación",
    "RecoveryPassword":"Recuperación de contraseña",
    "RegisteredEmail":"El email ya existe para otro usuario",
    "RegisteredRole":"El rol ya se encuentra registrado",
    "RegisterUser":"Registrar usuario",
    "ReminderMeeting":"Recordatorio",
    "reports":"reportes",
    "RequiredEmail":"El correo electrónico es obligatorio",
    "RequiredPassword":"La contraseña es obligatoria",
    "RequiredPermissions":"Los permisos son obligatorios",
    "RequiredProfilePic":"La imagen del perfil de usuario es obligatorio",
    "RequiredPwd":"La contraseña es obligatoria",
    "RequiredRole":"El rol es obligatorio",
    "RequiredUser":"El usuario es obligatorio",
    "RequiredUsername":"El nombre de usuario es obligatorio",
    "reservation":"Reservación",
    "ReservationAdded":"Reservación agregada",
    "ReservationCanceled":"Reservación cancelada",
    "ReservationCancelled":"Reservación cancelada",
    "ReservationConfirmed":"Reservación confirmada",
    "ReservationExist":"Ya existe una reservación igual",
    "ReservationExistDate":"Ya existe otra reservación con esa fecha y hora",
    "ReservationNotExist":"La reservación no existe",
    "reservations":"reservaciones",
    "Reserve":"Agendar",
    "ReserveRoom":"Reservar una una sala",
    "ResetPwd":"Restablecer contraseña",
    "RestorePassword":"¿Sabes tu contraseña?",
    "Results":"Resultados",
    "Role":"Rol",
    "RoleAdded":"Rol agregado correctamente",
    "RoleAddError":"No se ha podido agregar el nuevo rol",
    "RoleNotFound":"El rol no fue encontrado",
    "roles":"Roles",
    "RolesPermissions":"Roles y permisos",
    "RoleUpdated":"El rol se actualizó con éxito",
    "RoleUpdateError":"Error al actualizar el rol",
    "Room":"Sala",
    "ShowReservation":"Mostrar reservación",
    "To":"a",
    "Update":"Actualizar",
    "UpdatePassword":"Actualizar contraseña",
    "Seats":"Asientos",
    "Welcome":"Bienvenido(a)",
    "Search":"Buscar",
    "Street":"Calle",
    "TextButtonRecoveryEmail":"Cambiar contraseña",
    "Suburb":"Colonia",
    "UpdatedPwd":"Contraseña actualizada",
    "UsersRequired":"Debe de haber al menos un participante en la reunión",
    "ScheduleRequired":"El horario es obligatorio",
    "TypeRoomRequired":"El tipo de sala es obligatorio",
    "SectionRoomRequired":"La sección de la sala es obligatoria",
    "TokenExpired":"El token ha caducado",
    "SendingEmail":"Enviando correo",
    "SendReminder":"Enviar recordatorio",
    "Team":"Equipo",
    "TypeToSearch":"Escribe para buscar",
    "State":"Estado",
    "Status":"Estado",
    "Saving":"Guardando",
    "Save":"Guardar",
    "Skills":"Habilidades",
    "TextReset":"Ingresa tu nueva contraseña y para reforzar la seguridad de la misma no olvides colocar letras y números.",
    "Start":"Inicio",
    "StartTimeRequired":"La hora de inicio es obligatoria",
    "UserList":"Lista de empleados",
    "RoomName":"Nombre de la sala",
    "UserName":"Nombre de usuario",
    "TTForgotPassword":"Olvide mi contraseña",
    "TextRecovery":"Por favor ingresa tu correo electrónico, te enviaremos instrucciones para restablecer tu contraseña.",
    "TextRecoveryEmail":"Recibimos una solicitud para restablecer tu contraseña. Si no realizaste esta solicitud, ignora este correo electrónico.",
    "SentReminder":"Recordatorio enviado",
    "TRecoverPassword":"Recuperar contraseña",
    "TitleRecoveryEmail":"Restaurar contraseña",
    "UpdateMeeting":"Reunión actualizada",
    "RoomAdded":"Sala agregada",
    "VideoconferenceRoom":"Sala de videoconferencia",
    "RoomEdited":"Sala editada",
    "RoomRemoved":"Sala eliminada",
    "RoomSortOk":"Sala ordenada",
    "rooms":"salas",
    "SectionRoom":"Sección de sala",
    "SelectCharge":"Selecciona al personal encargado",
    "SelectTypeRoom":"Selecciona un tipo de sala",
    "SelectDate":"Selecciona una fecha",
    "SelectRoom":"Selecciona una sala",
    "SelectSectionRoom":"Selecciona una sección de sala",
    "SelectLocation":"Selecciona una ubicación",
    "Services":"Servicios",
    "SubtitleRecoveryEmail":"Si deseas restablecer tu contraseña, haz clic en el siguiente enlace",
    "UploadRoomImage":"Sube una imagen de la sala",
    "UploadImage":"Subir imagen",
    "TextMailSent":"Te hemos enviado un correo electrónico con las instrucciones para restablecer tu contraseña a ",
    "Type":"Tipo",
    "TypeRoom":"Tipo de Sala",
    "types":"Tipos",
    "TypesRoomSection":"Tipos de secciones",
    "TypesRoom":"Tipos de sala",
    "user":"usuario",
    "show":"ver",
    "view":"ver",
    "ViewCalendar":"Ver calendario",
    "ViewDetails":"Ver detalles",
    "ViewList":"Ver lista",
    "ViewRoom":"Ver sala",
    "ShowAllReservations":"Ver todas las reservaciones",
    "UserView":"Vista de empleado",
    "Editing":"Editando",
    "InitDate":"Fecha de inicio",
    "EndDate":"Fecha de fin",

    "hostUser":"Usuario anfitrión",
    "InitDateMeeting":"Fecha inicial de la reunión",
    "EndDateMeeting":"Fecha final de la reunión",
    "GuestInternal":"Invitado interno",
    "GuestExternal":"Invitado externo",
    "CarriedOut":"Realizada",
    "InProgress":"En proceso",
    "Scheduled":"Programada",

    "MoreFilters":" Más filtros",
    "LessFilters":" Menos filtros",
    "GeneralFilter":"Filtro general",
    "RoomFilter":"Filtro por sala",
    "StatusFilter":"Filtro por estatus",
    "HostFilter":"Filtro por anfitrion",
    "PersonalChargeFilter":"Filtro por personal a cargo",
    "CleanFilters":"Limpiar filtros",
    

    "AddSectionRoom": "Agregar sección",
    "SectionEnd": "Al final",
    "Position": "Posición",
    "UploadSectionRoomImage": "Sube una imagen de la sección",
    "before": "Antes de ",
    "RequiredImage": "Imagen requerida",
    "FilterRoomTypeSection":"Tipo de sección",

    "Schedule": "Horario",
    "Monday": "Lunes",
    "Tuesday": "Martes",
    "Wednesday": "Miércoles",
    "Thursday": "Jueves",
    "Friday": "Viernes",
    "Saturday": "Sábado",
    "Sunday": "Domingo",
    "Available24": "Disponible 24 hrs",
    "MainRoom": "Sala principal",
    "SelectMainRoom": "Selecciona sala principal",
    "ErrorEmptySchedule": "Por favor, selecciona un horario disponible o establece el modo de 24 horas",
    "ErrorHoursSchedule": "Por favor, verifica que la hora de inicio sea anterior a la hora final",
    "ErrorHoursSegmentsSchedule": "Por favor, verifica que los horarios no estén superpuestos",
    "ReserveBlockTime": "Reservar y bloquear segmentos de tiempo",
 }
export default dictionary