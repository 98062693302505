<template>
    <div class="modal fade show" style="padding-right: 15px; display: block;">
        <div class="modal-dialog" >
            <div class="modal-content">
                <div class="modal-header">
                    <h4 class="title">{{title}}</h4>
                </div>
                    <div class="modal-body"> 
                        <div class="media">
                                <div class="media-left m-r-15">
                                    <viewer>
                                        <img :src="image ? image : data.routeImage != '' && data.routeImage != null? route + data.routeImage : !image ? require('@/assets/fondo-sala.jpg') : image " class="user-photo media-object" width="150" height="150">
                                    </viewer>
                                </div>
                                <div class="media-body" v-if="!isView">
                                    <h6>{{GetLang('UploadSectionRoomImage')}}</h6><br>
                                    <button  class="btn btn-upload-image" id="btn-upload-photo" @click="$refs.filePhoto.click()">{{GetLang('UploadImage')}}</button>
                                        <input type="file" ref="filePhoto" class="sr-only" @input="onSelectFile(); uploadImage();" accept="image/jpeg, image/png, image/jpg"><br>
                                </div>
                            </div>
                        <h6>{{GetLang('NameEs')}}</h6>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="data.nameEs">
                        </div> 
                        <h6>{{GetLang('NameEn')}}</h6>
                        <div class="input-group mb-3">
                            <input type="text" class="form-control" v-model="data.nameEn">
                        </div>
                        <h6 v-if="arrSections.length != 0">{{GetLang('Position')}}</h6>
                        <div class="input-group mb-3" v-if="arrSections.length != 0">
                            <el-select  @change="changePosition" v-model="data.position" style="width:100%">
                                <el-option v-for="(item, i) in arrSections" :key="'elOption'+i" :value="i" :label="getName(item)"></el-option>
                                <el-option :value="arrSections.length" :label="GetLang('SectionEnd')"></el-option>
                            </el-select>
                        </div>
                        <h6>{{GetLang('Status')}}</h6>
                        <div class="input-group mb-3">
                            <label class="fancy-radio"><input name="status" :value="1" v-model="data.active" type="radio"><span><i></i>{{GetLang('Active')}}</span></label>
                            <label class="fancy-radio"><input name="status" :value="0" v-model="data.active" type="radio"><span><i></i>{{GetLang('Inactive')}}</span></label>
                        </div>                                                               
                    </div>
                <div class="modal-footer">
                    <button class="btn btn-primary" @click="save">{{GetLang('Save')}}</button>
                    <button class="btn btn-danger" @click="close">{{GetLang('Cancel')}}</button>
                </div>
            </div>
        </div>
    </div>
    </template>
    
    <script>
    import toolsLang from '../../../common/dictionary'
    export default {
        name: 'DialogSectionRoomComponent',
        data: () => ({
            filename: '',
            route: '',
            image: null, 
            isView: false ,
            position: 0,
            mfile: {
                name: '',
                file: '',
                extention: ''
            }
        }),
        props:{
            data:{
                require: true,
                type: Object,
                default: ()=>({
                    routeImage: '',
                    nameEs: '',
                    nameEn: '',
                    position: 0,
                    active: 0,
                })            
            },
            title:{
                require: true,
                type: String,
                default: 'Tittle'
            },
            type: {
                require: true,
                type: String,
                default: 'add'
            },
            arrSections: {
                required: true,
                type: Array,
                default(){return []}
            },     
        },
        methods:{
            GetLang: key => toolsLang.getKey(key),
            TextLang: (en,es) => toolsLang.getTranslate(en,es),
            getName(item){
                return this.GetLang("before") + this.TextLang(item.nameEn, item.nameEs)
            },
            close(){
                this.$emit('Close')
            },
            changePosition(){

            },
            onSelectFile () {
                const input = this.$refs.filePhoto
                const files = input.files
                if (files && files[0]) {
                    const reader = new FileReader
                    reader.onload = e => {
                        this.image = e.target.result
                        console.log(this.image)
                        this.mfile.routeImage = e.target.result
                    }
                    reader.readAsDataURL(files[0])
                    this.$emit('input', files[0])
                }
            }, 
            uploadImage() {
                if(this.mfile == null){
                    this.mfile = {
                        name: '',
                        file: '',
                        extention: ''
                    }
                }
                const file = document.querySelector('input[type=file]').files[0]
                const reader = new FileReader()
                reader.onload = () => {
                    this.mfile.file = reader.result.split(',')[1]
                }
                reader.readAsDataURL(file)
                this.mfile.name = file.name.split('.')[0]
                this.mfile.extention = file.name.split('.').pop()
                this.filename = file.name
                
            },
            save(){
                if(this.type == 'add' && this.mfile.name == ''){
                    this.$toast.open({
                        message: toolsLang.getKey("RequiredImage"),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })    
                    return
                }
                let aux = {...this.data, file:  this.mfile }
                if(this.data.nameEs == '' || this.data.nameEn == ''){
                    this.$toast.open({
                        message: toolsLang.getKey("FieldRequired"),
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    })               
                }
                else{
                    this.$emit("Correct", aux)
    
                }
            }
        },
        watch:{
    
        },
        beforeMount(){
            toolsLang.initLanguage()
            console.log('dialog', this.arrSections.length)
        }
    }
    </script>
    
    <style lang="scss" scoped>
        .modal{
            background-color: rgba(50,50,50,0.4);
        }
    </style>