import Vue from 'vue'
import Router from 'vue-router'
import store from '../store'

import externalMenu from './menu/external.js'
import internalMenu from './menu/internal'
import toolsLang from '../common/dictionary'
import Profile from '../views/authentication/profileViewPage'
//import ViewUser from '../views/user/viewUser'

const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)


const routes= [
    ...internalMenu,
    ...externalMenu,
    {
        path: '*',
        redirect: '/login'
    },
	{
		path: '/profile',
		component: Profile,
		meta: {
			title: 'MyProfile',			
		}
	},
	// {
	// 	path: '/view/user',
	// 	component: ViewUser,
	// 	meta: {
	// 		title: 'ViewUser',			
	// 	}
	// }	
]

const auth = {
	loggedIn() {
		return store.getters.isLogged
	},
	logout() {
		store.commit('setLogout')
	}
}

const router = new Router({
  mode: 'history',
  routes,
  base: '/'
})

router.afterEach((to) => {
    Vue.nextTick( () => {
        document.title = 'POSCO México '+ toolsLang.getKey(to.meta.title);
    });
})

router.beforeEach((to, from, next) => {
	let external = false
	if(to && to.meta && to.meta.external)
        external = true

	if(!external) {
		if(auth.loggedIn()) {
			next()
		} else {
			if(to.name !== 'login'){
                router.replace("/login")
				return false
			}
			next()
		}
	} else {
		next()
	}
    
})

export default router