<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>            
            <AddRoom :model="model" @Close="close" @Save="saveRoom()" :isSending="isSending" :type="'edit'"></AddRoom>
        </div>           
    </div>
</template>


<script>
import toolsLang from '../../common/dictionary'
import BreadCrumb from '../../components/BreadCrumb.vue'
import AddRoom from '../../components/admin/room/AddRoomComponent.vue'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'

export default {
    name: 'EditRoom',
    components:{BreadCrumb, AddRoom},
    data: () => ({
        type: 'edit',
        room: [],
        services: [],
        model:{
            id: '',
            nameEs: '',
            nameEn: '',
            description: '',
            personChargeId: '',
            numberPeople:  0,
            selectServices: [],
            sectionRoomType: {
                id: '',
                nameEn:'',
                nameEs:''
            },
            typeRoomCatalog: {
                id: '',
                nameEn:'',
                nameEs:''
            },
            location: {
                address: '',
                building: '',
                id: '',
                municipality: {
                    id:'',
                    name: '',
                    municipalityId: 0
                },
                state: {
                    id: 0,
                    name: ''
                }
            },
            personCharge: {
                email: '',
                nameComplete: '',
                phone: '',
                userId:''
            },
            flat: '',
            area: '',
            roomservices: [{
                nameEn:'',
                nameEs:'',
                roomServiceId:'',
                serviceCatalog:''
            }],
            section: '',
            routeImage: '',
            file: {
                name: '',
                file: '',
                extention: ''
            },
            isOpen24Hours: false,
            isReserveBlockTime: false,
            schedule: {
                monday: [], // Monday
                tuesday:[], // Tuesday
                wednesday:[], // Wednesday
                thursday:[], // Thursday
                friday:[], // Friday
                saturday:[], // Saturday
                sunday:[], // Sunday
            },             
        },        
        isSending: false,
        mainRoomId: ''
    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        }, 
        details(){
            const tok = this.$route.query.tok
            request.ADFetch({
                url: Urls.Api.Room.Detail,
                data: {
                    roomId: tok
                },
                recive: data => {
                    this.model = data
                },
                
            })
        },
        saveRoom(){
            console.log('main', this.model.mainRoomId)
            request.ADFetch({
                url: Urls.Api.Room.Update,
                data: {
                    id: this.model.id,
                    nameEs: this.model.nameEs,
                    nameEn: this.model.nameEn,
                    description: this.model.description,
                    numberPeople: this.model.numberPeople,
                    personChargeId: this.model.personCharge.userId,
                    typeRoomId: this.model.typeRoomCatalog.id,
                    locationId: this.model.location.id,
                    flat: this.model.flat,
                    area: this.model.area,  
                    section: this.model.section,
                    sectionId: this.model.sectionRoomType.id,
                    services: this.model.selectServices,      
                    file: {
                        name: this.model.file == null ? '' : this.model.file.name,
                        file: this.model.file == null ? '' : this.model.file.file,
                        extention: this.model.file == null ? '' : this.model.file.extention
                    },
                    schedule: this.model.schedule,
                    isOpen24Hours: this.model.isOpen24Hours,
                    isReserveBlockTime: this.model.isReserveBlockTime,
                    mainRoomId: this.model.mainRoomId,
                },
                before: () => {this.isSending = true},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("RoomEdited"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })                      
                    this.clear()
                    this.close()
                },
                complete: () => {this.isSending = false},
                error: (dat) => {
                    this.$toast.open({
                        message: dat,
                        type: "error",
                        duration: 3000,
                        position: 'top'
                    }) 
                }
            })
        }, 
        clear(){
            this.model={
                id: '',
                nameEs: '',
                nameEn: '',
                description: '',
                personChargeId: '',
                numberPeople:  0,
                sectionRoomType: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                typeRoomCatalog: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                location: {
                    address: '',
                    building: '',
                    id: '',
                    municipality: {
                        id:'',
                        name: '',
                        municipalityId: 0
                    },
                    state: {
                        id: 0,
                        name: ''
                    }
                },
                personCharge: {
                    email: '',
                    nameComplete: '',
                    phone: '',
                    userId:''
                },
                flat: '',
                area: '',
                roomservices: [{
                    nameEn:'',
                    nameEs:'',
                    roomServiceId:'',
                    serviceCatalog:''
                }],
                section: '',
                routeImage: '',
                file: {
                    name: '',
                    file: '',
                    extention: ''
                },
                isOpen24Hours: false,
                isReserveBlockTime: false,
                schedule: {
                    monday: [], // Monday
                    tuesday:[], // Tuesday
                    wednesday:[], // Wednesday
                    thursday:[], // Thursday
                    friday:[], // Friday
                    saturday:[], // Saturday
                    sunday:[], // Sunday
                },    
                mainRoomId: ''
            }
        },
        close(){
            this.$router.back()
        }
    },
    beforeMount() { // Antes de que se monte
        toolsLang.initLanguage()
        this.details()
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>

</style>
