<template>
    <div>
        <show-reservation :data="data" :date="data.date" :type="'show'" 
            @Cancel="$router.back()"
            @close="$router.back()"
            @Reminder="reminder" @Back="$router.back()"></show-reservation>
    </div>
</template>

<script>
    import ShowReservation from '../../components/admin/reservation/reservationComponent.vue'
    import toolsLang from '../../common/dictionary'
    import request from '../../scripts/request'
    import Urls from '../../scripts/urls'

    export default{
        name: 'Show',
        components: {ShowReservation},
        data: () => ({
            reservation: [],
            data: {
                id: '',
                name: '',
                description: '',
                justify: '',
                status: '',
                dateTimeInit: '',
                dateTimeEnd: '',
                date: '',
                userId: '',
                room: {
                    name: '',
                    description: '',
                    numberPeople: 0,
                    flat: '',
                    area: '',
                    status: '',
                    typeRoom:{
                        id: '',
                        nameEn: '',
                        nameEs: ''
                    },
                    manager: {
                        nameComplete: '',
                        email: '',
                        phone: '',
                    },                    
                    location: {
                        address: '',
                        name: '',
                        stateId: 0,
                        municipalityId: 0,
                        municipality: ''
                    },
                },
                internalUsers: [],
                externalUsers: []
                
            }
        }),
        methods: {
            GetLang: k => toolsLang.getKey(k),
            TextLang: (en,es) => toolsLang.getTranslate(en,es),
            onCommandLang(lang) {
                toolsLang.changeLanguage(lang)
            },
            init(){
                const tok = this.$route.query.tok
                request.ADFetch({
                    url: Urls.Api.Reservation.Detail,
                    data: {value: tok},
                    recive: dat => {
                        var sttTime = dat.dateTimeInit.split('T')[1]
                        sttTime = sttTime.split(':')[0]+':'+sttTime.split(':')[1]
                        var endTime = dat.dateTimeEnd.split('T')[1]
                        endTime = endTime.split(':')[0]+':'+endTime.split(':')[1]
                        const date = new Date(dat.dateTimeInit)
                        this.data = {
                            id: dat.id,
                            name: dat.name,
                            description: dat.description,
                            // justify: dat.justify,
                            status: dat.status,
                            dateTimeInit: sttTime,
                            dateTimeEnd: endTime,
                            date: date,
                            userId: dat.userId,
                            room: {
                                id: dat.room.id,
                                name: dat.room.name,
                                description: dat.room.description,
                                numberPeople: dat.room.numberPeople,
                                flat: dat.room.flat,
                                area: dat.room.area,
                                status: dat.room.status,
                                typeRoom:{
                                    id: dat.room.typeRoom.id,
                                    nameEn:  dat.room.typeRoom.nameEn,
                                    nameEs:  dat.room.typeRoom.nameEs
                                },
                                manager: {
                                    nameComplete: dat.room.manager.nameComplete,
                                    email: dat.room.manager.email,
                                    phone: dat.room.manager.phone,
                                },                    
                                location: {
                                    address: dat.room.location.address,
                                    name: dat.room.location.name,
                                    stateId: dat.room.location.stateId,
                                    municipalityId:  dat.room.location.municipalityId,
                                    municipality: dat.room.location.municipality
                                },
                            },
                            internalUsers: dat.internalUsers,
                            externalUsers: dat.externalUsers, 
                            groups: dat.groups
                        }
                    }
                })
            },
            reminder(model){
                request.ADFetch({
                   url: Urls.Api.Reservation.Reminder,
                   data: {
                        value: model.id
                   },
                   success: () => {
                       this.$toast.open({
                           message: toolsLang.getKey("SentReminder"),
                           type: "success",
                           duration: 3000,
                           position: 'top'
                       })
                   },
               })
            }
        },
        beforeMount() { // Antes de que se monte
            this.init()
        }      
        
    }
</script>