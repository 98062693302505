const Sites = {
    //UrlAPI: 'https://api-poscosalas.sharktech.com.mx'
    //UrlAPI: 'http://207.249.129.244:91'
    UrlAPI: 'https://api.poscomeetingsystem.com.mx'
    //UrlAPI: 'https://localhost:44307'
    //UrlAPI: 'http://192.168.1.72:8080'
  }
  
  const Paths = {
    ApiV1: Sites.UrlAPI + '/api/v1'
  }
  
  const Urls = {
    Api: {
      Auth: {
        Login: Paths.ApiV1 + '/Auth/Login',
        ForgotPassword: Paths.ApiV1 + '/Auth/ForgotPassword',
        ResetPassword: Paths.ApiV1 + '/Auth/ResetPassword',
        Profile: Paths.ApiV1 + '/Auth/Profile',
        SendRecovery: Paths.ApiV1 + '/Auth/Recovery',
        Permissions: Paths.ApiV1 + '/Auth/PermissionsUser',
      },
      Catalog: {
        Group: {
          Add: Paths.ApiV1 + '/catalog/AddGroup',
          List: Paths.ApiV1 + '/catalog/Groups',
          Update: Paths.ApiV1 + '/catalog/UpdateGroup',
          Delete: Paths.ApiV1 + '/catalog/DeleteGroup',
          ByGroup: Paths.ApiV1 + '/catalog/UsersByGroup'
        },
        Role: {
          List: Paths.ApiV1 + '/catalog/Role',
          Add: Paths.ApiV1 + '/catalog/AddRole',
          Update: Paths.ApiV1 + '/catalog/UpdateRole',
          Remove: Paths.ApiV1 + '/catalog/RemoveRole',
        },
        Location: {
          Add: Paths.ApiV1 + '/catalog/AddLocation',
          List: Paths.ApiV1 + '/catalog/Locations',
          Update: Paths.ApiV1 + '/catalog/UpdateLocation',
          Delete: Paths.ApiV1 + '/catalog/DeleteLocation'
        },
        State: Paths.ApiV1 + '/catalog/State',
        Municipality: Paths.ApiV1 + '/catalog/Municipality',
        List: Paths.ApiV1 + '/catalog/List',
        Add: Paths.ApiV1 + '/catalog/Add',
        Update: Paths.ApiV1 + '/catalog/Update',
        Remove: Paths.ApiV1 + '/catalog/Remove',
        ListSectionRoom: Paths.ApiV1 + '/catalog/ListSectionRoom',
        AddSectionRoom: Paths.ApiV1 + '/catalog/AddSectionRoom',
        UpdateSectionRoom: Paths.ApiV1 + '/catalog/UpdateSectionRoom',
        RemoveSectionRoom: Paths.ApiV1 + '/catalog/RemoveSectionRoom'
      },
      Admin:{
        List: Paths.ApiV1 + '/Admin/ListUser',
      },
      Config:{
        ListPermission: Paths.ApiV1 + '/config/ListPermission',
        ListPermissionByRole: Paths.ApiV1 + '/config/ListPermissionByRole',
        AddPermissionToRole: Paths.ApiV1 + '/config/AddPermissionToRole',
        AddRole: Paths.ApiV1 + '/config/AddRole',
        EditRole: Paths.ApiV1 + '/config/EditRole',
        DetailsRole: Paths.ApiV1 + '/config/DetailsRole',
        RemoveRole: Paths.ApiV1 + '/config/RemoveRole',
      },
      Personal:{
        Detail: Paths.ApiV1 + '/personal/Detail',
        List: Paths.ApiV1 + '/personal/List',
        Update: Paths.ApiV1 + '/personal/Update'
      },
      Room:{
        Add: Paths.ApiV1 + '/room/Add',
        List: Paths.ApiV1 + '/room/List',
        ListMain: Paths.ApiV1 + '/room/ListMain',
        Update: Paths.ApiV1 + '/room/Update',
        UpdatePosition: Paths.ApiV1 + '/room/UpdatePosition',
        Detail: Paths.ApiV1 + '/room/Detail',
        Delete: Paths.ApiV1 + '/room/Delete',
        ListCalendar: Paths.ApiV1 + '/room/ListCalendar'
      },
      Reservation:{
        Add: Paths.ApiV1 + '/reservation/Add',
        List: Paths.ApiV1 + '/reservation/List',
        Update: Paths.ApiV1 + '/reservation/Update',
        Delete: Paths.ApiV1 + '/reservation/Delete',
        Cancel: Paths.ApiV1 + '/reservation/Cancel',
        ListByRoom: Paths.ApiV1 + '/reservation/AllReservationsByRoom',
        Detail: Paths.ApiV1 + '/reservation/Details',
        Reminder: Paths.ApiV1 + '/reservation/Reminder'
      },
      Reports:{
        List: Paths.ApiV1 + '/report/TotalAllReservation'
      }
    },
    Sites,
    Paths
  }
  
  export default Urls
  