<template>
    <img class="img-icon" v-if="imageCurrent" :src="imageCurrent" />
    <img class="img-icon" width="20" v-else src="../assets/user.png" alt="default">
</template>


<script>
import ic_home from '../assets/images/menu/ic_home.png'
import ic_catalogs from '../assets/images/menu/ic_catalogs.png'
import ic_reports from '../assets/images/menu/ic_reports.png'
import ic_reservations from '../assets/images/menu/ic_reservations.png'
import ic_user from '../assets/images/menu/ic_user.png'

export default {
    name: 'ImageAssetIcon',
    props: {
        icon: {
            type: String,
            require: true,
            default: ''
        }
    },
    data: ()=>({
        imageCurrent: undefined
    }),
    beforeMount() {
        if(this.icon != ''){
            switch(this.icon){
                case 'ic_home': this.imageCurrent = ic_home; break
                case 'ic_catalogs': this.imageCurrent = ic_catalogs; break
                case 'ic_reports': this.imageCurrent = ic_reports; break
                case 'ic_reservations': this.imageCurrent = ic_reservations; break
                case 'ic_user': this.imageCurrent = ic_user; break
            }
        }
    }
}
</script>


<style>
    .img-icon{
        width: 24px;
        margin-right: 5px;
    }
</style>