<template>
    <div class="vertical-align-wrap">
        <div class="image-top">
            <img class="image-logo-top" src="@/assets/images/img_logo_posco_mexico_blue.png" alt="posco-salas"/>
        </div>  
        <div class="auth-main"></div>
        <div class="background-gradient"></div>
        <div class="image-bot" align="right">
            <img class="bottom-logo-login" src="@/assets/images/img_logo_posco_blue.png" alt="posco-salas" />
        </div>
        <div class="vertical-align-middle">
            
            <div class="auth-box" style="border: 3; border-color: #dfdfdf; border-width: 10px;">
                <div class="text-logo-top" style="margin-bottom: 10px;">     
                   <h2 style="color: #003b5b; position: relative;">POSCO Meeting System</h2>   
                </div>
                
                <div class="card card-login" >
                    <div class="header" style="padding: 16px;" >                        
                        <el-dropdown class="el-static-lang" trigger="click" @command="onCommandLang">
                            <span class="el-dropdown-link">
                                <i class="flag-icon" :class="{ ['flag-icon-' + language]: true }"></i>
                            </span>
                            <el-dropdown-menu slot="dropdown" style="list-style-type: none; cursor: pointer;">
                                <el-dropdown-item command="us">
                                    <i class="flag-icon flag-icon-us mr-1"></i> {{GetLang('LangEnglish')}}
                                </el-dropdown-item>
                                <el-dropdown-item command="mx">
                                    <i class="flag-icon flag-icon-mx mr-1"></i> {{GetLang('LangSpanish')}}
                                </el-dropdown-item>
                            </el-dropdown-menu>
                        </el-dropdown>
                        <label style="font-size: 20px;"   v-if="type=='login' || type=='forgot'" class="lead">{{type=='login' ? GetLang('Login') : GetLang('RecoveryPassword')}}</label>
                        <label v-if="type=='reset'" class="lead">{{GetLang('NewPassword')}}</label>        
                    </div>
                    <div class="body" style="margin: 0px; padding: 16px;">
                        <!-- LOGIN -->
                        <div class="form-auth-small" v-if="type=='login'">
                            <label class="control-label">{{GetLang('UserName')}}</label>
                            <div class="form-group">
                                <input type="email" class="form-control" v-model="form.username" :placeholder="GetLang('UserName')">
                            </div>
                            <label class="control-label">{{GetLang('Password')}}</label><br>
                            <div class="form-group input-group">
                                <input :type="show ? 'text' : 'password'" class="form-control" v-model="form.password" :placeholder="GetLang('Password')">
                                <div class="input-group-append">
                                    <span @click="show = !show" class="input-group-text"><span class="input-group-addon"><i class="icon-eye" style="cursor: pointer"></i> </span></span>
                                </div>
                            </div>
                            <button type="button" :disabled="sending" @click="login" class="btn btn-primary btn-lg btn-block">{{!sending ? GetLang('Login') : GetLang('LoggingIn')}}</button>
                            <div class="bottom">
                                <i class="fa fa-lock"></i><span class="link m-b-10" @click="type = 'forgot', emailSending=false"> {{GetLang('ForgotPwd')}}</span>
                            </div>
                        </div>
                        <!-- END LOGIN -->
                        <!-- FORGOT -->
                        <div class="form-auth-samll" v-if="type=='forgot'">
                            <p>{{!emailSending ? GetLang("TextRecovery") : GetLang("TextMailSent")}}<strong v-if="emailSending">{{form.username}}</strong> </p>
                            <form class="form-auth-small" v-if="!emailSending">
                                <div class="form-group">
                                    <label class="control-label">{{GetLang("Email")}}</label>
                                    <input type="email" class="form-control" v-model="form.username" :placeholder="GetLang('Email')">
                                </div>
                                <button type ="button" class="btn btn-primary btn-lg btn-block" :disabled="sending" @click="ForgotPassword">{{GetLang("ResetPwd")}}</button>
                                <div class="bottom">
                                    <span class="helper-text">{{GetLang("KnowYourPwd")}}<span @click="type = 'login'" class="link m-l-20">{{GetLang("Login")}}</span></span>
                                </div>
                            </form>
                            <button type="button" v-if="emailSending" @click="type = 'login'" class="btn btn-primary btn-lg btn-block">{{GetLang('Login')}}</button>
                        </div>
                        <!-- END FORGOT -->
                        <!-- RESET -->
                        <div class="form-auth-samll" v-if="type == 'reset'">
                            <p>{{GetLang("TextReset")}} </p>
                            <form class="form-auth-small" >
                                <div class="form-group">
                                    <label class="control-label">{{GetLang("Password")}}</label>
                                    <input type="text" class="form-control" v-model="form.password" :placeholder="GetLang('Password')">
                                </div>
                                <button type="button" class="btn btn-primary btn-lg btn-block" @click="ResetPassword" :disabled="sending">{{!sending ? GetLang("Save") : GetLang("Saving")}}</button>
                            </form>
                        </div>
                        <!-- END RESET -->
                    </div>
                </div>
                
            </div>
        </div>
        
        
    </div>
	<!-- END WRAPPER class="bottom-logo-login" style="position: relative;"  -->
</template>
<script>
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import tools from '../../scripts/tools'
import toolsLang from '../../common/dictionary'


import {
    str_TOKEN,
    str_ROLE,
    str_NAME,
    str_IMAGE,
    str_MENU
} from '../../store/index'

export default {
    name:'LoginComponent',
    data: ()=>({
        form: {
            username: '',
            password: '',
        },
        language: "",
        type: 'login',
        emailSending: false,
        sending: false,
        show: false
    }),
    methods: {
        validEmail(email){
            var ev=/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            return ev.test(email);
        },
        login(){
            if(this.form.username.trim() == ''){
                this.$toast.open({
                    message: toolsLang.getKey("RequiredUsername"),
                    type: "error",
                    position: 'top',

                })
                return;
            }
            if(this.form.password.trim() == ''){
                this.$toast.open({
                    message: toolsLang.getKey("RequiredPwd"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
				return;
			}
            this.sending = true
            const password = tools.EncryptPass(this.form.password)
            request.ADFetch({
                url: Urls.Api.Auth.Login,
                data: { Username: this.form.username, Password: password, platform: 'Room' },
                recive: dat => {
                    this.$store.dispatch("setSession", dat)
                    this.$store.commit('setLogin')
                    window.location = '/dashboard/index'
                },
                complete: () => {this.sending = false}
            })
        },
        
        ForgotPassword(){
            if(!this.validEmail(this.form.username)){
                this.$toast.open({
                    message: toolsLang.getKey("InvalidEmail"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return;
            }
            if(this.form.username.trim() == ""){
                this.$toast.open({
                    message: toolsLang.getKey("RequiredEmail"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return;
            }
            this.sending = true
            request.ADFetch({
                url: Urls.Api.Auth.ForgotPassword,
                data: {username: this.form.username},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("MailSent"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
					this.emailSending = true
				},
				complete: () => { this.sending = false }
            })
        },
        ResetPassword(){
            if(this.form.password.trim() == ''){
                this.$toast.open({
                    message: toolsLang.getKey("RequiredPwd"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
				return;
			}
            request.ADFetch({
                url: Urls.Api.Auth.ResetPassword,
                token: this.$route.query.tok,
                data: this.form,
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("UpdatedPwd"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    }) 
                    this.type = 'login'
                },
                error: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("TokenExpired"),
                        type: "error",
                        duration: 3000,
                        position: 'top',
                    })                    
                },    
			})
        },
        GetLang: k => toolsLang.getKey(k),
        onCommandLang(lang) {
            this.language = toolsLang.changeLanguage(lang)
        },
    },
    beforeMount(){
        localStorage.removeItem(str_TOKEN)
        localStorage.removeItem(str_ROLE)
        localStorage.removeItem(str_NAME)
        localStorage.removeItem(str_IMAGE)
        localStorage.removeItem(str_MENU)
        this.language = toolsLang.initLanguage()
		if(this.$route.query?.tok) {
			this.type = 'reset'
		}
    }  
}
</script>
<style lang="scss" scoped>
.el-dropdown{
    cursor: pointer;
    float: right;
}

.btn-lg{
    background: #003b5b;
    color: '#fff';
    border-color: #003b5b;
	}

  .btn-lg:hover {
    color: #fff;
    background-color: #003b5b;
    border-color: #003b5b;
  }

  .btn-lg:active {
    color: #fff;
    background: #003b5b;
    background-color: #003b5b;
    border-color: #003b5b;
  }

  .btn-lg:focus {
    color: #fff;
    background-color: #003b5b;
    border-color: #003b5b;
  }

  
</style>