<template>
   <div class="main-content myreservationPage">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="col-sm-12" align="right" style="margin: 0px;">
                <button type="button" class="btn btn-block btn-primary b-add" @click="showCalendar"  style="margin-bottom: 20px; margin-right: -10px;">{{GetLang('ViewCalendar')}}</button>
            </div>  
            <div class="card">
                <div class="card-header">
                    {{GetLang('Filter')}}s
                    <label class="fancy-checkbox" style="float:right">
                    </label>
                </div>
                <div class="body">
                    <h4>{{name}}</h4>
                    <el-form>
                        <el-row>
                            <el-col :xs="24" :md="18">
                                <el-form-item>
                                    <el-input 
                                        type="text" 
                                        :placeholder="GetLang('TypeToSearch')"
                                        v-model="search">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :md="6">
                                <el-form-item>
                                    <el-date-picker @change="init()" :placeholder="GetLang('Date')" v-model="date" style="width:100%"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>                    
                    <!-- <div class="row">
                        <div class="col-lg-8">
                            <div class="input-group m-b-10">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{GetLang('Filter')}}</span>
                                </div>
                                <input type="text" class="form-control" :placeholder="GetLang('TypeToSearch')" v-model="search">
                            </div>
                        </div>
                        <div class="col-lg-4">
                            <div class="input-group">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">{{GetLang('Date')}}</span>
                                    <el-date-picker v-model="date"></el-date-picker>
                                </div>
                            </div>
                        </div>
                    </div> -->
                </div>
            </div>
            <div class="row">
                <div class="col-lg-12">
                    <div v-if="filter.length == 0" style="text-align: center">
                        <h4>{{GetLang('NoData')}}</h4>
                    </div>
                </div>
                <div class="col-lg-4 col-md-6 col-sm-12" v-for="reservation in dataFilter" :key="reservation.id">                  
                    <div class="card myreservationPage-item" @click="show(reservation.id)" style="cursor: pointer">
                        <div class="body" style="padding: 20px">
                                <h6>{{reservation.name}}</h6>
                                <p>{{reservation.description}}</p>
                                <ul class="list-unstyled team-info m-t-20">
                                    <span>{{GetLang('Team')}} </span>
                                    <li v-for="user in reservation.users" :key="user.id"><img :src="require('@/assets/users/img_user_other.png')" :title="user.name" :alt="user.email"></li>
                                </ul>
                                <span style="float: right">{{reservation.dateTimeInit.split('T')[0]}}</span> 
                                <h6>{{GetLang('From')  }}{{reservation.dateTimeInit.split('T')[1]}} hrs {{GetLang('To')}}  {{reservation.dateTimeEnd.split('T')[1]}} hrs</h6>
                                <div 
                                    class="progress progress-xs progress-transparent m-b-10 m-t-25" 
                                    :class="'progress-bar-custom-' + reservation.statusReservation">
                                    <div class="progress-bar " role="progressbar" :style="{width: reservation.percentage + '%'}"></div>        
                                </div>
                                <p :class="'status-text-' + reservation.statusReservation" :style="{margin: '0px'}">{{GetLang(reservation.statusReservation)}}</p>  
                                <div  style="text-align: right">
                                <span v-if="reservation.statusReservation == 'pendient' && (isREdit || reservation.userId == userId)" class="badge badge-success" style="cursor: pointer" @click="edit(reservation.id)">{{GetLang('Edit')}}</span>
                                <span v-if="reservation.statusReservation == 'pendient' && (isREdit || reservation.userId == userId)" class="badge badge-danger" style="cursor: pointer" @click="cancel(reservation.id)">{{GetLang('Cancel')}}</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>            
        </div>
    </div>
</template>


<script>
import BreadCrumb from '../../components/BreadCrumb.vue'
import toolsLang from '../../common/dictionary'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
// import constants from '../../scripts/constants'


export default {
    name: 'ALlReservations',
    components: {BreadCrumb},
    data: () => ({    
        visible: true,
        userId: '',
        search: '',
        date: null,
        isREdit: false,
        reservations: [],
        room: [],
        filter: [],
        name: 'Nombre de la sala'

    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        init(){
            const role = this.$store.getters.role
            if(role == 'Super'){
                this.isREdit = true
            }
            else{this.isREdit = false}
            this.userId = this.$store.getters.userId
            const tok = this.$route.query.tok
            request.ADFetch({
                url: Urls.Api.Reservation.ListByRoom,
                data: {value: tok},
                before: () => {this.reservations = []},
                recive: dat => {
                    
                    var arrTemp = []
                    dat.forEach(item =>{
                        var i = item
                        i.statusName = ''
                        arrTemp.push(i)
                    })
                    this.filter = this.reservations = arrTemp
                    this.getProggress()
                }
            })
            request.ADFetch({
                url: Urls.Api.Room.Detail,
                data: {roomId: tok},
                before: () => {this.name = ''},
                recive: dat => {this.name = dat.name}
            })
        },
        getProggress(){
            var arrReservation = []
            this.reservations.forEach((item) => {
                item = {...item, percentage:0 }
                arrReservation.push(item)
            })
            this.reservations = arrReservation;
        },
        getRoute(){
            this.route = Urls.Sites.UrlAPI
        },
        formatDate(){
            if(this.date != null){ 
                var newDate = this.date.toJSON().slice(0,10)
                this.searchDate = newDate.toString() 
            }
        },
        updateClock(){
            setInterval(() =>{
                this.getProggress()
            }, 60000)
        },   
          
        filterByDate(){
            if(this.searchDate == '' || this.date == null) {this.filter = this.reservations}
            else{this.filter = this.reservations.filter((r) => r.dateTimeInit.split('T')[0] == this.searchDate)}
        },
        edit(data){
            this.$router.push({path: '/reservation/edit', query: {tok: data}})
        },
        show(id){
            // console.log("aqui se ven")
            this.$router.push({path: '/reservation/show', query: {tok: id}})
        },
        showCalendar(){
            this.$router.push('/admin/calendar')
        },
        cancel(id){
           this.$confirm(toolsLang.getKey("AreYouSureToCancel"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
                .then(()=>{
                    this.editVisible = false
                    request.ADFetch({
                        url: Urls.Api.Reservation.Cancel,
                        data: {value: id},
                        success: () => {
                            this.$toast.open({
                                message: 'Reservación eliminada',//toolsLang.getKey("RoomEdited"),
                                type: "success",
                                duration: 3000,
                                position: 'top'
                            }) 
                            this.init()
                        }
                    })
                }).catch(()=>{})
        }
    },
    watch:{/*
        search(){
            this.dataFilter()
        },*/
        date(){
            this.formatDate()
            this.filterByDate()
        },
        checked(){
            this.showAll()
        }
    },
    beforeMount() { // Antes de que se monte
        toolsLang.initLanguage()
        this.init()
        this.updateClock()
        this.getRoute() 
    },
    computed: { // actualiza cuando cambia algo en el dom
        dataFilter(){
            let dataTemp = []
            this.reservations.map(item => {
                const time = new Date().getTime()
                const init = new Date(item.dateTimeInit).getTime()
                const end = new Date(item.dateTimeEnd).getTime()

                var durationMin = (( end - init)/1000)/60
                var timeLeft = ((end - time)/1000)/60
                var proggress = ((durationMin - timeLeft)*100)/durationMin

                item = {...item, percentage: proggress}

                var statusReservation = time < init? 'pendient'
                                : time >= init && time <= end? 'process'
                                : time > end || item.status == "Finished"? 'finished' : 'actived'
                
                if(item.status == "Cancelled"){
                    statusReservation = 'cancelled'
                }
                item = {...item, statusReservation: statusReservation}
                dataTemp.push(item)
            })
            
            if(this.search && this.search != null && this.search != ''){
                dataTemp = dataTemp.filter((r) => (r.name).toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
            }

            const newDate = this.date?.toJSON().slice(0,10)
            const sDate = newDate?.toString() 

            if(sDate && sDate != null && sDate != '' ){
                dataTemp = dataTemp.filter((r) => r.dateTimeInit.split('T')[0] == sDate)
            }

            return dataTemp
        }
    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>
    .vdp-datepicker input {
        border: none;
        background: transparent;
        background-color: transparent !important;
        height: 1% !important;
    }
    .vdp-datepicker__calendar {
        top: 41px;
        left: 0px !important;
    }
    .myreservationPage {
        .el-form-item__content {
            padding:10px
        }
        .el-form-item__label{
            padding: 0px;
            margin: 0px;
        }

        .card {
            .body{
                padding: 15px;
            }
        }
        .el-form-item {
            margin-bottom: 0px;
        }

        &-item{
            min-height: 290px;
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
            padding: 15px;

            &:hover {
                background-color: #f8f8f8;
            }
        }
    }
</style>