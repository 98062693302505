import dateUtil from 'element-ui/lib/utils/date'
export default {
    EncryptPass(pass, num = 0) {
        if (num === 0) {
          num = Math.round(Math.random() * (9 - 2) + 2)
        }
        let c = 0
        let sPass = pass
        do {
          sPass = btoa(sPass)
          c++
        } while (c < num)
        switch (num) {
          case 1:
            sPass += String.fromCharCode(117)
            break
          case 2:
            sPass += String.fromCharCode(114)
            break
          case 3:
            sPass += String.fromCharCode(99)
            break
          case 4:
            sPass += String.fromCharCode(105)
            break
          case 5:
            sPass += String.fromCharCode(101)
            break
          case 6:
            sPass += String.fromCharCode(108)
            break
          case 7:
            sPass += String.fromCharCode(97)
            break
          case 8:
            sPass += String.fromCharCode(103)
            break
          case 9:
            sPass += String.fromCharCode(111)
            break
        }
        return sPass
    },
    setCookie(cname, cvalue, exdays) {
      const d = new Date()
      d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000))
      let expires = "expires="+d.toUTCString()
      document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
    },
    getCookie(cname) {
      let name = cname + "=";
      let ca = document.cookie.split(';');
      for(let i = 0; i < ca.length; i++) {
        let c = ca[i]
        while (c.charAt(0) == ' ') {
          c = c.substring(1)
        }
        if (c.indexOf(name) == 0) {
          return c.substring(name.length, c.length)
        }
      }
      return ""
    },
    DecryptPass(pass) {
      if(pass == null) return ''
      const letter = pass[pass.length-1]
      pass = pass.substring(0, pass.length - 1)
      let n = 0
      switch (letter) {
        case String.fromCharCode(117):
          n = 1 
          break
        case String.fromCharCode(114):
          n = 2
          break
        case String.fromCharCode(99):
          n = 3 
          break
        case String.fromCharCode(105):
          n = 4 
          break
        case String.fromCharCode(101):
          n = 5 
          break
        case String.fromCharCode(108):
          n = 6 
          break
        case String.fromCharCode(97):
          n = 7 
          break
        case String.fromCharCode(103):
          n = 8 
          break
        case String.fromCharCode(111):
          n = 9 
          break
      }
      let c = 0
      do {
        try {pass = atob(pass)} catch (e) { console.info(e) }
        c++
      } while (c < n)
      return pass
    },
    getDate(date = null, format = 'yyyy-MM-dd'){
      date ??= new Date()
      return dateUtil.format(date, format)
    }
}