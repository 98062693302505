<template>
    <div class="main-content myreservationPage">
        <div class="container-fluid" v-if="visible">
            <div class="block-header">
                <bread-crumb></bread-crumb>        
            </div>
            <div class="col-sm-12" align="right" style="margin: 0px;">
                <button type="button" class="btn btn-block btn-primary b-add" @click="reserve(); addVisible = true;" style="margin-bottom: 20px; margin-right: -10px;">{{GetLang("ReserveRoom")}}</button>
            </div>         
            <div class="card">
                <div class="card-header">
                    {{GetLang('Filter')}}s
                    <label class="fancy-checkbox" style="float:right">
                        <input type="checkbox" @change="showAll" v-model="checked">
                        <span>{{GetLang('ShowAllReservations')}}</span>
                    </label>
                </div>
                <div class="body">
                    <el-form>
                        <el-row>
                            <el-col :xs="24" :sm="8" :md="8">
                                <el-form-item :label="GetLang('Filter')">
                                    <el-input 
                                        type="text" 
                                        @change="checked = false"
                                        :placeholder="GetLang('TypeToSearch')"
                                        v-model="search">
                                    </el-input>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8">
                                <el-form-item :label="GetLang('Status')">
                                    <el-select @change="changeStatus" v-model="statusSelected" style="width:100%">
                                        <el-option value="" :label="GetLang('All')"></el-option>
                                        <el-option v-for="(item, i) in statuss" :key="'elOption'+i" :value="item" :label="GetLang(item)"></el-option>
                                    </el-select>
                                </el-form-item>
                            </el-col>
                            <el-col :xs="24" :sm="8" :md="8">
                                <el-form-item :label="GetLang('Date')">
                                    <el-date-picker @change="init()" :placeholder="GetLang('Date')" v-model="date" style="width:100%"></el-date-picker>
                                </el-form-item>
                            </el-col>
                        </el-row>
                    </el-form>
                </div>
            </div> 
            <div class="row">
                <div class="col-lg-4 col-md-6 col-sm-12"  v-for="reservation in dataFilter" :key="reservation.id" >
                    <div class="card myreservationPage-item" @click="show(reservation.id)" style="cursor: pointer">
                        <!-- <div @click="show(reservation.id)" style="cursor: pointer"> -->
                            <h5 style="color: #20A091;">{{reservation.room.name}}</h5>
                            <h6>{{reservation.name}}</h6>
                            <p>{{reservation.description}}</p>
                            <ul class="list-unstyled team-info m-t-20">
                                <span>{{GetLang('Team')}} </span>
                                <li v-for="user in reservation.users" :key="user.id"><img :src="require('@/assets/users/img_user_other.png')" :title="user.name" :alt="user.email"></li>
                            </ul>
                            <span style="float: right">{{reservation.dateTimeInit.split('T')[0]}}</span> 
                            <h6>{{GetLang('From')}}  {{reservation.dateTimeInit.split('T')[1]}} hrs {{GetLang('To')}}  {{reservation.dateTimeEnd.split('T')[1]}} hrs</h6>
                            <div 
                                class="progress progress-xs progress-transparent m-b-10 m-t-25" 
                                :class="'progress-bar-custom-' + reservation.statusReservation">
                                <div class="progress-bar " role="progressbar" :style="{width: reservation.percentage + '%'}"></div>        
                            </div>
                            <p :class="'status-text-' + reservation.statusReservation" :style="{margin: '0px'}">{{GetLang(reservation.statusReservation)}}</p> 
                        <!-- </div>                        -->
                            <div  style="text-align: right">
                                <span v-if="reservation.statusReservation == 'pendient'" class="badge badge-success" style="cursor: pointer" @click="edit(reservation.id)">{{GetLang('Edit')}}</span>
                                <span v-if="reservation.statusReservation == 'pendient'" class="badge badge-danger" style="cursor: pointer" @click="cancel(reservation.id)">{{GetLang('Cancel')}}</span>
                            </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import toolsLang from '../../common/dictionary'
import BreadCrumb from '../../components/BreadCrumb.vue'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'
import Tools from '../../scripts/tools'

export default {
    name: 'MyReservation',
    components: {BreadCrumb},
    data(){
        return { 
            type: '',   
            statusSelected: '',
            statuss : ['pendient', 'process', 'finished', 'actived'],
            search: '',
            date: null,
            route: '',
            reservations:[],
            filter: [],
            visible: true,
            checked: true,
            editVisible: false,
            model:{
                last: false,
                id: '',
                name: '',
                description: '',
                personChargeId: '',
                numberPeople:  0,
                typeRoomCatalog: {
                    id: '',
                    nameEn:'',
                    nameEs:''
                },
                location: {
                    address: '',
                    building: '',
                    id: '',
                    municipality: {
                        id:'',
                        name: '',
                        municipalityId: 0
                    },
                    state: {
                        id: 0,
                        name: ''
                    }
                },
                personCharge: {
                    email: '',
                    nameComplete: '',
                    phone: '',
                    userId:''
                },
                flat: '',
                area: '',
                roomservices: [{
                    nameEn:'',
                    nameEs:'',
                    roomServiceId:'',
                    serviceCatalog:''
                }],
                routeImage: '',
                file: {
                    name: '',
                    file: '',
                    extention: ''
                },             
            }
        }
    },
    methods: {
        GetLang: k => toolsLang.getKey(k),
        TextLang: (en,es) => toolsLang.getTranslate(en,es),
        onCommandLang(lang) {
            toolsLang.changeLanguage(lang)
        },
        changeStatus(){
            if(this.statusSelected != '' && this.checked == true){
                this.checked = false
            }
        },
        init(){
            const date = this.date == null ? '' :  Tools.getDate(this.date)
            if(date != '' && this.checked == true){
                this.checked = false
            }
            this.reservations = []
            request.ADFetch({
                url: Urls.Api.Reservation.List,
                data: { 
                    userId: this.$store.getters.userId, 
                    date: date
                },
                recive: dat => {
                    this.reservations = dat.map(item =>{
                        item.statusName = ''
                        return item
                    })
                    this.getProggress()
                }
            })
            this.route = Urls.Sites.UrlAPI
        },
        reserve(){
            this.$router.push('/reservation/listrooms')
        },
        showAll(){
            if(this.checked) {
                this.date = null
                this.search = ''
                this.statusSelected = ''
            } else {
                if(this.date == null){
                    this.date = Date.parse(Tools.getDate() + 'T00:00:00')
                }
            }
            this.init()
        },
        getProggress(){
            var arrReservation = []
            this.reservations.forEach((item) => {
                item = {...item, percentage:0 }
                arrReservation.push(item)
            })
            this.reservations = arrReservation;
        },
        getRoute(){
            this.route = Urls.Sites.UrlAPI
        },
        updateClock(){
            setInterval(() =>{
                this.getProggress()
            }, 60000)
        },   
        show(id){
            this.$router.push({path: '/reservation/show', query: {tok: id}})
        },
        edit(id){
            this.$router.push({path: '/reservation/edit', query: {tok: id}})
        },
        cancel(id){
            this.$confirm(toolsLang.getKey("AreYouSureToCancel"), toolsLang.getKey("ConfirmDeletion"), {confirmButtonText: toolsLang.getKey('Accept'), cancelButtonText: toolsLang.getKey('Cancel'), type: "warning" })
            .then(()=>{
                this.editVisible = false
                request.ADFetch({
                    url: Urls.Api.Reservation.Cancel,
                    data: {value: id},
                    success: () => {
                        this.$toast.open({
                            message: toolsLang.getKey("ReservationCancelled"),
                            type: "success",
                            duration: 3000,
                            position: 'top'
                        }) 
                        this.$router.back()
                        this.init() 
                    }
                })
            }).catch(()=>{this.$router.back()})
        }
    },
    beforeMount() { // Antes de que se monte
        this.init()        
        this.updateClock()
        this.getRoute()        
    },
    computed: { // actualiza cuando cambia algo en el dom
        dataFilter(){
            let dataTemp = this.reservations.map(item => {
                const time = new Date().getTime()
                const init = new Date(item.dateTimeInit).getTime()
                const end = new Date(item.dateTimeEnd).getTime()

                const durationMin = (( end - init)/1000)/60
                const timeLeft = ((end - time)/1000)/60
                const proggress = ((durationMin - timeLeft)*100)/durationMin

                item = {...item, percentage: proggress}

                let statusReservation = time < init ? 'pendient'
                                : (time >= init && time <= end ? 'process'
                                : (time > end || item.status == "Finished" ? 'finished' : 'actived'))
                
                if(item.status == "Cancelled") {
                    statusReservation = 'cancelled'
                    item.percentage = 100
                }
               
                item = {...item, statusReservation: statusReservation}
                return item
            })
            
            if(this.search && this.search != null && this.search != ''){
                dataTemp = dataTemp.filter(r => r.name.toLocaleLowerCase().includes(this.search.toLocaleLowerCase()))
            }

            if(this.statusSelected != ''){
                dataTemp = dataTemp.filter(r => r.statusReservation == this.statusSelected)
            }
            
            return dataTemp
        }
    }
}
</script>

<style lang="scss">
    .vdp-datepicker input {
        border: none;
        background: transparent;
        background-color: transparent !important;
    }
    .vdp-datepicker__calendar {
        top: 41px;
        left: 0px !important;
    }

    .fancy-checkbox {    
        input[type="checkbox"] {

            &:checked + span:before {
                background: #003b5b;
                border-color: #003b5b;
            }
        }
    }

    .myreservationPage {
        .el-form-item__content {
            padding:10px
        }
        .el-form-item__label{
            padding: 0px;
            margin: 0px;
        }

        .card {
            .body{
                padding: 15px;
            }
        }
        .el-form-item {
            margin-bottom: 0px;
        }

        &-item{
            min-height: 320px;
            display: flex !important;
            flex-direction: column;
            justify-content: space-between;
            align-content: space-between;
            padding: 15px;

            &:hover {
                background-color: #f8f8f8;
            }
        }
    }
</style>