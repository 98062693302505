<template>
<div class="modal fade show" style="padding-right: 15px; display: block;">
    <div class="modal-dialog" >
        <div class="modal-content">
            <div class="modal-header">
                <h4 class="title">{{title}}</h4>
            </div>
                <div class="modal-body"> 
                    <h6>{{GetLang('Address')}}</h6>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="data.address" >
                    </div> 
                    <h6>{{GetLang('Building')}}</h6>
                    <div class="input-group mb-3">
                        <input type="text" class="form-control" v-model="data.building">
                    </div>
                    <h6>{{GetLang('State')}}</h6>
                    <div class="input-group mb-3">
                        <select  class="form-control" v-model="stateId">
                            <option v-for="state in states" :key="state.id" :value="state.id" :label="state.name"></option>
                        </select> 
                    </div>
                    <h6>{{GetLang('Municipality')}}</h6>
                    <div class="input-group mb-3">
                        <select  class="form-control" v-model="data.municipalityId">
                            <option v-for="municipio in municipalities" :key="municipio.id" :value="municipio.id" :label="municipio.name"></option>
                        </select>
                    </div>                                                                             
                    <!-- <h6>{{GetLang('Status')}}</h6>
                    <div class="input-group mb-3">
                        <label class="fancy-radio"><input name="status" :value="1" v-model="data.active" type="radio"><span><i></i>{{GetLang('Active')}}</span></label>
                        <label class="fancy-radio"><input name="status" :value="0" v-model="data.active" type="radio"><span><i></i>{{GetLang('Inactive')}}</span></label>
                    </div>                                                                -->
                </div>
            <div class="modal-footer">
                <button class="btn btn-primary" @click="save">{{GetLang('Save')}}</button>
                <button class="btn btn-danger" @click="close">{{GetLang('Cancel')}}</button>
            </div>
        </div>
    </div>
</div>
</template>

<script>
import toolsLang from '../../../common/dictionary'
import request from '../../../scripts/request'
import Urls from '../../../scripts/urls'
export default {
    name: 'DialogComponent',
    data: () => ({
        states: [],
        municipalities: [],
        stateId: 0
    }),
    props:{
        data:{
            require: true,
            type: Object,
            default: ()=>({
                id: '',
                building: '',
                address: '',
                stateId: 0,
                municipalityId: 0
            })            
        },
        title:{
            require: true,
            type: String,
            default: 'Tittle'
        },
        type: {
            require: true,
            type: String,
            default: 'add'
        }        
    },
    methods:{
        GetLang: key => toolsLang.getKey(key),
        close(){
            this.$emit('Close')
        },
        save(){
            if(this.data.address.trim() == "" || this.data.building.trim() == "" || this.stateId == "" || this.data.municipalityId == "") {
                this.$toast.open({
                    message: toolsLang.getKey("FieldRequired"),
                    type: "error",
                    duration: 3000,
                    position: 'top'
                })
                return false
            }           
            this.data.stateId = this.stateId
            this.$emit("Correct", this.data)
        },
        getMunicipalities(){
			this.municipalities = []
			request.Catalog(Urls.Api.Catalog.Municipality, { id: this.stateId }).then(dat => {this.municipalities = dat})
        },
        constructor() {
           if(this.type == 'edit'){
                this.stateId = this.data.stateId
            }
        }      
    },
    computed:{
        sortStates(){
            let states = this.states
            states = states.sort((a,b) => {
                let sa = a.name.toLowerCase(), sb = b.name.toLowerCase();
                if(sa < sb){
                    return -1
                }
                if(sa > sb){
                    return 1
                }
                return 0
            })
            return states
        },
        sortMunicipalities(){
            let states = this.municipalities
            states = states.sort((a,b) => {
                let sa = a.name.toLowerCase(), sb = b.name.toLowerCase();
                if(sa < sb){
                    return -1
                }
                if(sa > sb){
                    return 1
                }
                return 0
            })
            return states
        }           
    },
    watch:{
        stateId(){
            this.getMunicipalities()
        }
    },
    beforeMount(){
        //this.language = toolsLang.changeLanguage(lang)
        this.constructor()
        request.Catalog(Urls.Api.Catalog.State).then(dat => {
            this.states = dat
            if(this.data.stateId == '') {
                this.data.stateId = dat[0].id
            }
        })
        this.states =  this.sortStates   
    },
    beforeUpdate(){
        this.municipalities = this.sortMunicipalities
    }
}
</script>

<style lang="scss" scoped>
    .modal{
        background-color: rgba(50,50,50,0.4);
    }
</style>