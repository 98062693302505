<template>
    <div id="left-sidebar" class="sidebar">
       <div class="">
         <div class="user-account">
             <a href="/profile">
                <!-- onerror="this.onerror=null;this.src=require('/img/img_user_other.png')"  -->
                <img 
                    :src="userImage" 
                    :onerror="imgError()" 
                    class="rounded-circle user-photo" 
                    style="height: 50px; width: 50px"
                    alt="User Profile Picture">
            </a>
             <div class="dropdown" v-bind:class="{ 'show': dropActive}"  @click="dropActive = !dropActive" v-click-outside="outside">
                 <span>{{GetLang("Welcome")}},</span>
                 <a href="javascript:void(0);" class="dropdown-toggle user-name" data-toggle="dropdown" :aria-expanded="dropActive ? 'true' : 'false'"><strong>{{userName}}</strong></a>
                 <ul class="dropdown-menu dropdown-menu-right account" v-bind:class="{ 'show': dropActive }" :style="dropActive ? 'top: 100%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;' : 'top: 80%; position: absolute; transform: translate3d(-52px, 42px, 0px); left: 0px; will-change: transform;'">
                     <!-- <li><router-link to="/pages/page-profile2"><i class="icon-user"></i>My Profile</router-link></li>
                     <li><router-link to="/app/app-inbox"><i class="icon-envelope-open"></i>Messages</router-link></li>
                     <li><a href="javascript:void(0);"><i class="icon-settings"></i>Settings</a></li> -->
                     <li><router-link to="/profile"><i class="icon-user"></i>{{GetLang('MyProfile')}}</router-link></li>
                     <li class="divider"></li>
                     <li><router-link to="/authentication/page-login"><i class="icon-power"></i>{{GetLang("Logout")}}</router-link></li>
                 </ul>
             </div>
         </div> 
           <!-- Nav tabs -->
         <ul class="nav nav-tabs">
             <li class="nav-item"><a class="nav-link"  v-on:click="setActiveNavtab('Menu')" :class="{ 'active show': openNavtab('Menu')}" data-toggle="tab">{{GetLang("Menu")}}</a></li>              
         </ul>  
           <!-- Tab panes -->
         <div class="tab-content p-l-0 p-r-0">
             <div class="tab-pane" :class="{ 'active show': openNavtab('Menu')}" id="menu">
                 <nav class="sidebar-nav">
                     <ul class="metismenu" v-for="(item,i) in menuInternal" :key="i">
                         <li :class="{ 'active': currentActiveMenu == item.activeMenu }" >
                             <!---<a :href="item.children?.length > 1 || !item.isMain ? null : item.path" :class="{'has-arrow': (item.children?.length > 0 && !item.isMain), 'metismenu-link': true}" @click="setActive(item.activeMenu)">-->
                                <a :href="item.isMain ? item.path : null" :class="{'has-arrow': (item.children?.length > 0 && !item.isMain), 'metismenu-link': true}" @click="setActive(item.activeMenu)">
                                 <IconImage :icon='item.icon'/>
                                 <span>{{ GetLang(item.meta.title) }}</span>
                             </a>
                             <ul v-if="currentActiveMenu === item.activeMenu && item.children?.length > 0 && !item.isMain" >
                             
                                 <li  v-for="(iChill,j) in item.children" :key="j" v-bind:class="{ 'active':currentRoutePath == '{{iChill.path}}'}">
                                     <router-link v-if="!iChill.isHidden" :to="iChill.path" >{{GetLang(iChill.meta.title)}}</router-link>
                                 </li>                                
                             </ul>
                         </li>
                     </ul>
                 </nav>
             </div>             
         </div>
       </div>
     </div>    
 </template>
 <script>
 
 import menuAdministratorRooms from '../../../router/menu/internal'
 import IconImage from '../../IconImageComponent.vue'
 import toolsLang from '../../../common/dictionary.js'
 import urls from "../../../scripts/urls"
 import request from "../../../scripts/request"
 import constants from '../../../scripts/constants'
 
 
 export default {
     name: 'SidebarComponent',
     components: { IconImage },
     methods:{
         GetLang: k => toolsLang.getKey(k),
         changeBodycolor(color){
             this.activeColor = color
             document.body.className = 'theme-'+color+' '+ this.data_theme;
         },
         outside() {
             this.dropActive = false
         },
         setActive(menuItem) {
             if (this.currentActiveMenu === menuItem) {
                 this.currentActiveMenu = null;
             } else {
                 this.currentActiveMenu = menuItem;
             }
         },
         imgError(){
             var male = require('@/assets/users/img_user_male.png')
             var female = require('@/assets/users/img_user_female.png')
             var other = require('@/assets/users/img_user_other.png')
             var img = other
             if (this.gender.nameEn == "Male") {
                 img = male
             } else if (this.gender.nameEn == "Female") {
                 img = female
             }
             this.userImage = img
         },
         openNavtab(tab) {
             return this.currentMenuTab === tab
         },
         setActiveNavtab(tab) {
             this.currentMenuTab = tab 
         },
         setactiveTheme(theme) {
             this.data_theme = theme
             document.body.className = 'theme-'+this.activeColor +' '+ theme;
             Event.$emit('theme',theme) 
         },
         GetPermission(){
             request.ADFetch({
                 url: urls.Api.Auth.Permissions,
                 recive: dat => {
                    var aux = [...dat]
                    
                    const objMain = aux.find(item => item.keyName === constants.Permissions.ADMIN_CATALOGS)
                    if(objMain != null && objMain != undefined){
                        let indexR = aux.findIndex(item => item.keyName === constants.Permissions.ADMIN_CATALOGS)
                        let objChild = objMain.childs.find(item => item.keyName === constants.Permissions.C_SECTION_ROOM_TYPES)
                        if(objChild != null && objChild != undefined){
                            const newArray =  objMain.childs.filter(item => item.keyName !==  constants.Permissions.C_SECTION_ROOM_TYPES)
                            objMain.childs = [objChild, ...newArray]
                            aux[indexR] = objMain
                        }
                    }
                    

                     this.$store.dispatch("setPermissions", aux)
                     this.$store.commit('setPermission')
                     var arrMenu = []
                     arrMenu.push(menuAdministratorRooms[0])
                     menuAdministratorRooms.forEach((menu) => {
                        aux.forEach((per) => {
                             var isAdd = false
                             var newMenu = menu
                             if(per.keyName == menu.keyName) {
                                 isAdd = true
                                 var arrMenuChild = []
                                 
                                 per.childs.forEach((perChild) => {
                                     menu.children.forEach((menuChild) => {
                                         
                                         if(menuChild.keyName == perChild.keyName || 
                                         menuChild.keyName == constants.Permissions.R_LIST_ROOM ||
                                         menuChild.keyName == constants.Permissions.R_ADD) {
                                             var isAdd = true
                                             arrMenuChild.forEach((tempChild) => {
                                                 if(menuChild.keyName == tempChild.keyName) {
                                                     isAdd = false
                                                 }
                                             })
                                             
                                             if(isAdd){
                                                 arrMenuChild.push(menuChild)
                                             }
                                         }
                                         
                                     })
                                 })
                                 newMenu.children = arrMenuChild
                             }
 
                             if(per.keyName == constants.Permissions.ADMIN_ROOM && isAdd){
                                 var isRoomList = false
                                 per.childs.forEach((perChild) => {
                                     if(perChild.keyName == constants.Permissions.AR_SHOW){
                                         isRoomList = true
                                     }
                                 })
                                 isAdd = isRoomList
                                 if(per.childs.length == 0){
                                     isAdd = false
                                 }
                             }
                            if(isAdd){
                                arrMenu.push(newMenu)
                            }
                         })
                     })
                      this.menuInternal = arrMenu
                 },
                 complete: () => {}
             })
         },
         detail() {
             request.ADFetch({
                 url: urls.Api.Auth.Profile,
                 recive: dat => {
                     const person = dat[0]
                     this.type = person.type
                     this.userName = person.name + " " + person.lastname
                     this.gender = person.gender
                     this.userImage = urls.Sites.UrlAPI + person.routeImage
                     /*
                     if(person.type == "Personal")
                     {
                         this.userName = person.name + " " + person.lastname
                     } else {
                         this.userName = person.name + " " + person.lastname,
 
                         this.userImage = person.routeImage
                         this.form = {
                         this.userImage = urls.Sites.UrlAPI + person.routeImage
                         /*this.form = {
                             routeImage: person.routeImage,
                             name: person.name,
                             lastname: person.lastname,
                             secondlastname: person.secondlastname ?? '',
                             roleEs: person.roleEs,
                             roleEn: person.roleEn
                         }
                     }*/
                 }
             })
         },
     },
     data() {
         return {
             currentActiveMenu: window.location.pathname.split("/")[2],
             currentMenuTab: 'Menu',
             activeColor: 'cyan',
             dropActive: '',
             data_theme:'',
             menuInternal: [],
             userName:'',
             userImage:'',
             id: '',
             gender: {
                 nameEn: '',
                 nameEs: '',
             },
         }
     },
     beforeMount() {
         this.GetPermission()
     },
     directives: {
         'click-outside': {
             bind: function(el, binding) {
                 // Define Handler and cache it on the element
                 const bubble = binding.modifiers.bubble
                 const handler = (e) => {
                 if (bubble || (!el.contains(e.target) && el !== e.target)) {
                     binding.value(e)
                 }
                 }
                 el.__vueClickOutside__ = handler
 
                 // add Event Listeners
                 document.addEventListener('click', handler)
                     },
             }
     },
     mounted() {
         this.detail()
     },
     computed: {
         currentRoutePath() {
             return this.$route.path;
         },
     },
     watch:{
         $route (){
             this.currentActiveMenu = window.location.pathname.split("/")[2];
             if (window.location.pathname.split("/")[2] ==  this.currentActiveMenu) {
                 document.body.className = 'theme-'+this.activeColor +' '+ this.data_theme; 
             }
         }
     }
 }
 </script>
 <style lang="scss" scoped>
     .metismenu-link {
         span {
             position: relative;
             top: 3px;
         }
     }
 
 </style>
 