<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>            
            <AddRoom :model="model" @Close="close" :type="type"></AddRoom>
        </div>           
    </div>
</template>


<script>
import toolsLang from '../../common/dictionary'
import BreadCrumb from '../../components/BreadCrumb.vue'
import AddRoom from '../../components/admin/room/AddRoomComponent.vue'
import request from '../../scripts/request'
import Urls from '../../scripts/urls'

export default {
    name: 'EditRoom',
    components:{BreadCrumb, AddRoom},
    data: () => ({
        type: 'view',
        rooms: [],
        model:{
            id: '',
            nameEn: '',
            nameEs: '',
            description: '',
            personChargeId: '',
            numberPeople:  0,
            sectionRoomType: {
                id: '',
                nameEn:'',
                nameEs:''
            },
            typeRoomCatalog: {
                id: '',
                nameEn:'',
                nameEs:''
            },
            location: {
                address: '',
                building: '',
                id: '',
                municipality: {
                    id:'',
                    name: '',
                    municipalityId: 0
                },
                state: {
                    id: 0,
                    name: ''
                }
            },
            personCharge: {
                email: '',
                nameComplete: '',
                phone: '',
                userId:''
            },
            flat: '',
            area: '',
            roomservices: [{
                nameEn:'',
                nameEs:'',
                roomServiceId:'',
                serviceCatalog:''
            }],
            routeImage: '',
            file: {
                name: '',
                file: '',
                extention: ''
            },           
            is24Hours: false,
            isOpen24Hours: false,
            isReserveBlockTime: false,
            schedule: {
                monday: [], // Monday
                tuesday:[], // Tuesday
                wednesday:[], // Wednesday
                thursday:[], // Thursday
                friday:[], // Friday
                saturday:[], // Saturday
                sunday:[], // Sunday
            },  
        }               
    }),
    methods: {
        GetLang: k => toolsLang.getKey(k),
        onCommandLang(lang) {
            this.language = toolsLang.changeLanguage(lang)
        },   
        close(){
            this.$router.push({path:'/admin/rooms'})
        },
        details(){
            console.log('viewroom')
            const tok = this.$route.query.tok
            request.ADFetch({
                url: Urls.Api.Room.Detail,
                data: {
                    roomId: tok
                },
                recive: data => {
                    this.model = data
                },
                
            })
        }
    },
    beforeMount() { // Antes de que se monte
        toolsLang.initLanguage()
        this.details()
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>