<template>
    <div class="main-content">
        <div class="container-fluid">
            <div class="block-header">
                <bread-crumb></bread-crumb>
            </div>
            <div class="row clearfix">
                <div class="card">
                    <div class="header">
                        <div class="comment-form">
                            <form class="row">
                                <div class="col-sm-12">
                                    <div class="input-group mb-3">
                                        <div class="input-group-prepend">
                                            <span class="input-group-text" id="inputGroup-sizing-default">{{GetLang("Filter")}}</span>
                                        </div>
                                        <input type="text" class="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" :placeholder="GetLang('TypeToSearch')" v-model="search" />
                                        <button type="button" class="btn btn-primary b-add" @click="clear();addVisible = true;" style="margin-left: 15px">{{GetLang("AddGroup")}}</button>
                                    </div>
                                </div>
                                     
                            </form>
                        </div>
                    </div>
                    <div class="body">
                        <div style="width: 100%"> 
                            <table-catalog 
                                :tableData="tableData" 
                                :search="search"
                                @editRow="edit"
                                @deleteRow="deleteRow"
                                @view="view"
                            ></table-catalog>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div>
            <dialog-component
             v-if="viewVisible"
             :type="'view'"
             :title="title"
             :data="model"
             @Close="close"
             @Correct="save($event)"
             ></dialog-component>         
        </div>                 
        <!-- Add -->
        <div>
            <dialog-component
             v-if="addVisible"
             :type="'add'"
             :title="title"
             :data="model"
             @Close="close"
             @Correct="save($event)"
             ></dialog-component>         
        </div>
        <!-- Edit -->
        <div>
            <dialog-component
             v-if="editVisible"
             :title="title" 
             :type="'edit'"
             :data="model"
             @Close="close"             
             @Correct="save($event, 'edit')"
             ></dialog-component>         
        </div>                
    </div>
</template>

<script>

import BreadCrumb from '@/components/BreadCrumb'
import toolsLang from '../../common/dictionary'
import tableCatalog from '../../components/admin/table/TableGroupsComponent.vue'
import dialogComponent from '../../components/admin/dialog/DialogGroupComponent.vue'
import Urls from '../../scripts/urls'
import request from '../../scripts/request'
//import {Groups, AddCatalog, UpdateCatalog, RemoveCatalog} from '../../scripts/catalogs'
//import constants from '../../scripts/constants'

export default {
    name: 'CGroupsComponent',
    components: {
        BreadCrumb,
        tableCatalog,
        dialogComponent
    },
    data: () => ({
        tableData: [],
        addVisible: false,
        model: {
            id: '',
            name: '',
            members: []
        },
        type: '',
        sending: false,
        editVisible: false,
        viewVisible: false,
        title: toolsLang.getKey('AddGroup'),
        search: '',
        total: 0    
    }),
    methods: {
        GetLang: key => toolsLang.getKey(key),
        tableRowClassName({rowIndex}) {
            if ((rowIndex%2)!= 0) {
            return 'color-row';
            } 
            return '';
        },
        edit(data){
            this.editVisible = true
            this.title = toolsLang.getKey('EditGroup')
            this.model = data
        },
        view(data){            
            this.viewVisible = true
            this.title = toolsLang.getKey('Group')
            this.model = data
        },
        close(){
            this.addVisible = this.editVisible = this.viewVisible = false
        },
        deleteRow(data){
            request.ADFetch({
                url: Urls.Api.Catalog.Group.Delete,
                data:{value: data.id},
                success: () => {
                    this.$toast.open({
                        message: toolsLang.getKey("Deleted"),
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                },
                complete: () => {this.list()}
            })
        },        
        save(model, type = ''){ 
            let auxUrl = Urls.Api.Catalog.Group.Add
            let msg = toolsLang.getKey('AddingGroup')
            let id = ''
            if(type == "edit") {
                auxUrl = Urls.Api.Catalog.Group.Update
                msg = toolsLang.getKey('EditedGroup')
                id = model.id
            }
            request.ADFetch({
                url: auxUrl,
                data: {
                    id: id,
                    name: model.name,
                    emails: model.members
                },
                complete: () =>{ this.list(), this.close() },
                success: () => {
                    this.$toast.open({
                        message: msg,
                        type: "success",
                        duration: 3000,
                        position: 'top'
                    })
                }                
            })            
        },
        clear(){
            this.model = {
                name: '',
                members: []
            }
        },
        list(){
            this.tableData = []
            request.ADFetch({
                url: Urls.Api.Catalog.Group.List,
                before: () => {this.table = []},
                recive: dat => {this.tableData = dat}
            })            
        },
    },
    beforeMount() { // Antes de que se monte
        this.list()
    },
    computed: { // actualiza cuando cambia algo en el dom

    },
    created() { // cuando se crea
        
    },
}
</script>

<style lang="scss" scoped>

</style>